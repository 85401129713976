import React from 'react';
import styled from 'styled-components'
import TextToPrint from '../TextToPrint'
import InvertColor from '../InvertColor';

import {
    whereBorn,
    whereLiving,
    ancestors,
    whanau,
    name,
    mountain,
    hill,
    sea,
    river,
    lake,
    tribe,
    extra1,
    extra2,
    extra3,
    extra4,
    extra5
} from './IsNotMaoriPepehaSentences';
import { myJob } from './IsMaoriPepehaSentences';

const Container = styled.div`
    margin-top: -50px;
    text-align: center;
    padding: 0 10px 50px 10px;
`;

const IsNotMaoriPepehaToPrintUi = ({
    inputValueAncestors,
    inputValueHill,
    inputValueMountain,
    inputValueRiver,
    inputValueLake,
    inputValueSea,
    inputValueExtra1,
    inputValueExtra2,
    inputValueExtra3,
    inputValueExtra4,
    inputValueExtra5,
    inputValueTribe,
    inputValueName,
    inputValueWhereBorn,
    inputValueWhereLiving,
    inputValueWhanau,
    toPrintTheme,
    setToPrintTheme,
    inputValueJob,
    isBasic,
}) => {

    return (
        <Container>
            {
                isBasic == true &&
                <div>
                    {inputValueAncestors !== "" &&
                        <TextToPrint
                            textMaori={ancestors(inputValueAncestors)[0]}
                            textEng={ancestors(inputValueAncestors)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueWhanau !== "" &&
                        <TextToPrint
                            textMaori={whanau(inputValueWhanau)[0]}
                            textEng={whanau(inputValueWhanau)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueWhereBorn !== "" &&
                        <TextToPrint
                            textMaori={whereBorn(inputValueWhereBorn)[0]}
                            textEng={whereBorn(inputValueWhereBorn)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueWhereLiving !== "" &&
                        <TextToPrint
                            textMaori={whereLiving(inputValueWhereLiving)[0]}
                            textEng={whereLiving(inputValueWhereLiving)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueName !== "" &&
                        <TextToPrint
                            textMaori={name(inputValueName)[0]}
                            textEng={name(inputValueName)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                </div>
            }
            {
                isBasic == false &&
                <div>
                    {inputValueAncestors !== "" &&
                        <TextToPrint
                            textMaori={ancestors(inputValueAncestors)[0]}
                            textEng={ancestors(inputValueAncestors)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueWhanau !== "" &&
                        <TextToPrint
                            textMaori={whanau(inputValueWhanau)[0]}
                            textEng={whanau(inputValueWhanau)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueWhereBorn !== "" &&
                        <TextToPrint
                            textMaori={whereBorn(inputValueWhereBorn)[0]}
                            textEng={whereBorn(inputValueWhereBorn)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueMountain !== "" &&
                        <TextToPrint
                            textMaori={mountain(inputValueMountain)[0]}
                            textEng={mountain(inputValueMountain)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueHill !== "" &&
                        <TextToPrint
                            textMaori={hill(inputValueHill)[0]}
                            textEng={hill(inputValueHill)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueLake !== "" &&
                        <TextToPrint
                            textMaori={lake(inputValueLake)[0]}
                            textEng={lake(inputValueLake)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueRiver !== "" &&
                        <TextToPrint
                            textMaori={river(inputValueRiver)[0]}
                            textEng={river(inputValueRiver)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueSea !== "" &&
                        <TextToPrint
                            textMaori={sea(inputValueSea)[0]}
                            textEng={sea(inputValueSea)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }





                    {inputValueExtra1 !== "" &&
                        <TextToPrint
                            textMaori={extra1(inputValueExtra1)[0]}
                            textEng={extra1(inputValueExtra1)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueExtra2 !== "" &&
                        <TextToPrint
                            textMaori={extra2(inputValueExtra2)[0]}
                            textEng={extra2(inputValueExtra2)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueExtra3 !== "" &&
                        <TextToPrint
                            textMaori={extra3(inputValueExtra3)[0]}
                            textEng={extra3(inputValueExtra3)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueExtra4 !== "" &&
                        <TextToPrint
                            textMaori={extra4(inputValueExtra4)[0]}
                            textEng={extra4(inputValueExtra4)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueExtra5 !== "" &&
                        <TextToPrint
                            textMaori={extra5(inputValueExtra5)[0]}
                            textEng={extra5(inputValueExtra5)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }






                    {inputValueWhereLiving !== "" &&
                        <TextToPrint
                            textMaori={whereLiving(inputValueWhereLiving)[0]}
                            textEng={whereLiving(inputValueWhereLiving)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueTribe !== "" &&
                        <TextToPrint
                            textMaori={tribe(inputValueTribe)[0]}
                            textEng={tribe(inputValueTribe)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {inputValueName !== "" &&
                        <TextToPrint
                            textMaori={name(inputValueName)[0]}
                            textEng={name(inputValueName)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                    {
                        inputValueJob !== "" &&
                        <TextToPrint
                            textMaori={myJob(inputValueJob)[0]}
                            textEng={myJob(inputValueJob)[1]}
                            toPrintTheme={toPrintTheme}
                        />
                    }
                </div>
            }
            <InvertColor setToPrintTheme={setToPrintTheme} toPrintTheme={toPrintTheme} />
        </Container>
    )
}

export default IsNotMaoriPepehaToPrintUi;