import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const Button = styled.div`
    text-align: center;
    
`;

const Icon = styled.img`
    height: 50px;
    width: 50px;
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    // box-shadow: inset 0px 0px 20px var(--unnamed-color-ffffff), 0px 0px 20px var(--unnamed-color-ffffff);
    // box-shadow: inset 0px 0px 20px #FFFFFF, 0px 0px 20px #FFFFFF;
    border-radius: 65px;

    @media only screen and (max-width: 1280px) {
        height: 45px;
        width: 45px;
    }

    @media only screen and (max-width: 768px) {
        height: 40px;
        width: 40px;
    }
`;

const ButtonText = styled.p`
    color: #000000;
    font-weight: 900;
    font-size: 13px;
`;

const ButtonTextSpan = styled.p`       
    color: #444;
    font-weight: 900;
    font-size: 12px;
    margin-top: -10px;

`;


const ButtonMenu = ({ link, image, maoriText, engText }) => {
    return (

        <Button>
            <Link to={link}>
                <Icon src={image} />
                <ButtonText>{maoriText}</ButtonText>
                <ButtonTextSpan>{engText}</ButtonTextSpan>
            </Link>
        </Button>

    )
}

export default ButtonMenu;