import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from "styled-components"
import ButtonMenu from './ButtonMenu';

const MenuContainer = styled.div`
    margin: 15px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    // top: 18px;
    width: 40px;
    height: 40px;
    // left: 1782px;
    /* UI Properties */
    box-shadow: inset 0px 0px 20px var(--unnamed-color-ffffff), 0px 0px 20px var(--unnamed-color-ffffff);
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 20px #FFFFFF, 0px 0px 20px #FFFFFF;
    border-radius: 50%;
    opacity: 1;
    -webkit-backdrop-filter: blur(30px);
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    justify-content: center;
    flex-direction: row;  
`;

const MenuOpenContainer = styled.div`
    // background-color: #2D373C;
    padding-top: 75px;
    position: absolute;
    top: 0px;
    right: 0; 
    width: 88%;
    z-index: -100;
    height: calc(100vh - 75px);

    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 65px 0px 0px 65px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);

    @supports (-webkit-backdrop-filter: blur(30px)) {
        width: calc(100vw - 12%);
    }
    @media only screen and (min-width: 768px) {
        width: 345px;
        margin-left: -280px;
    }
`;

const LinksContainer = styled.div`
    margin-bottom: 15px;
    width: 50%;
    @media only screen and (max-height: 900px) {
        width: 110px; 
    }
`;

const MenuImages = styled.img`
    cursor: pointer;
    // margin-top: 6px;
    // margin-left: 6px;
    // margin-right: 5px;
    width: 80%;
`;


const Menu = () => {
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    let location = useLocation()
    let path = location.pathname

    return (
            <MenuContainer>
                {
                    !menuIsOpen ?
                        <>
                            {path === "/" ?

                                <MenuImages onClick={() => setMenuIsOpen(!menuIsOpen)} src='/svg/menu-white.svg' alt="" />
                                :
                                <MenuImages onClick={() => setMenuIsOpen(!menuIsOpen)} src='/svg/menu.svg' alt="" />
                            }
                        </>
                        :
                        <MenuImages onClick={() => setMenuIsOpen(!menuIsOpen)} src='/svg/menu-close.svg' alt="" />

                }

                {
                    menuIsOpen &&

                    <MenuOpenContainer>
                        <Flex>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/"
                                    image="/imagesss/All SVG/Marae.svg"
                                    maoriText="Kāinga"
                                    engText="Home"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/pepeha/0/0"
                                    image="/imagesss/All SVG/Pepeha (Provenance).svg"
                                    maoriText="Pepeha"
                                    engText="Provenance"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/mihimihi/0/0"
                                    image="/imagesss/All SVG/Mihi (Greetings).svg"
                                    maoriText="Mihi"
                                    engText="Greeting"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/whakapapa/0/0"
                                    image="/imagesss/All SVG/Whakapapa (Genealogy).svg"
                                    maoriText="Whakapapa"
                                    engText="Genealogy"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/akona/0"
                                    image="/imagesss/All SVG/Whakahua tika (Pronunciation).svg"
                                    maoriText="Whakahua tika"
                                    engText="Pronunciation"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/karakia/0"
                                    image="/imagesss/All SVG/Karakia (Blessings).svg"
                                    maoriText="Karakia"
                                    engText="Blessings"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/waiata/0"
                                    image="/imagesss/All SVG/Waiata (Songs).svg"
                                    maoriText="Waiata"
                                    engText="Songs"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/glossary"
                                    image="/imagesss/All SVG/Kupu taka (Glossary).svg"
                                    maoriText="Kupu taka"
                                    engText="Glossary"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/framework"
                                    image="/imagesss/All SVG/Te Kākano (AUT Values).svg"
                                    maoriText="Te Kākano"
                                    engText="AUT Values"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/about"
                                    image="/imagesss/All SVG/Mō tēnei rauemi (About this resource).svg"
                                    maoriText="Mō tēnei rauemi"
                                    engText="About" // this resource
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/welcome"
                                    image="/imagesss/All SVG/Kete Korero (Tool Kit).svg"
                                    maoriText="Kete Kōrero"
                                    engText="Tool Kit"
                                />
                            </LinksContainer>
                            <LinksContainer onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                <ButtonMenu
                                    link="/practice/0/0"
                                    image="/imagesss/All SVG/practice.svg"
                                    maoriText="Whakaharatau"
                                    engText="Practice"
                                />
                            </LinksContainer>

                        </Flex>
                    </MenuOpenContainer>
                }
            </MenuContainer>
    )
}

export default Menu;