import React from 'react';
import WaiataTextGroup from './WaiataTextGroup'

const LyricEight = () => {

    return (
        <div>

            <WaiataTextGroup
                textMaori="Tērā te haeata"
                textEng="Towards the new dawn"
            />
            <WaiataTextGroup
                textMaori="Kia manawanui"
                textEng="Be courageous"
            />
            <WaiataTextGroup
                textMaori="Kia manawaroa"
                textEng="Be resilient"
            />
            <WaiataTextGroup
                textMaori="Ki te hoe nei"
                textEng="Take hold the paddle"
            />
            <WaiataTextGroup
                textMaori="Whakamānu"
                textEng="Launch"
            />
            <WaiataTextGroup
                textMaori="Eke Tangaroa"
                textEng="Master the journey"
            />
            <WaiataTextGroup
                textMaori="Tōkihi tōtika"
                textEng="Travel straight and with purpose"
            />
            <WaiataTextGroup
                textMaori="Ki te pae o Te Rawenga"
                textEng="And venture beyond the horizon"
            />
            <WaiataTextGroup
                textMaori="Auē e rongo nei 	i"
                textEng="I feel the wairua"
            />
            <WaiataTextGroup
                textMaori="Aku tīpuna"
                textEng="of those gone before us"
            />
            <WaiataTextGroup
                textMaori="Mana tuku iho"
                textEng="Who continue to inspire us"
            />
            <WaiataTextGroup
                textMaori="Mana Motuhake"
                textEng="To live meaningful lives"
            />
            <WaiataTextGroup
                textMaori="Tūā taku ara e"
                textEng="Clear my path"
            />
            <WaiataTextGroup
                textMaori="Tūātūā"
                textEng="Clear my path"
            />
            <WaiataTextGroup
                textMaori="Toi Te Rawenga"
                textEng="Hold fast to your greatness"
            />

        </div>

    )
}

export default LyricEight;