import React from 'react'
import { useHistory } from 'react-router-dom'

const PracticeItem = ({ engTitle, maoriTitle, icon, path }) => {
    const history = useHistory()
    const clickHandler = () => {
        history.push(`/practice/${path}`)
    }
    return (
        <div>
            <div className="practice__main-col practice__main-box" onClick={clickHandler}>
                <div className='practice__main-col-content'>
                    <div className='main-box__icon'>
                        <img src={icon} alt="karakia icon" />
                    </div>
                    <span className="main-box__maori-title">{maoriTitle}</span>
                    <span className="main-box__eng-title">{engTitle}</span>
                </div>
            </div>
        </div>
    )
}

export default PracticeItem