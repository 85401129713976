import React from 'react'
import styled from 'styled-components'

const Container = styled.p`
width: 58%;
margin: -30px auto 0 auto;
font-size: 17px;
padding-bottom: 2rem;
`

const SongInfo = ({title}) => {
  return (
    <Container>
        {title}
    </Container>
  )
}

export default SongInfo