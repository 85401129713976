
const QuestionAlert = ({ engTextAlert, maoriTextAlert, colorBtn, alertRef, alertBtnClickHandler, score }) => {
    return (
        <div className="alert">
            <div className="alert__content">
                <span className='alert__content-text'>
                    {maoriTextAlert}
                </span>
                {score && <span className="alert__content-score">Score: {score}</span> }
                <span className={`alert__content-button ${colorBtn}`} ref={alertRef} onClick={alertBtnClickHandler}>
                    {engTextAlert}
                </span>
            </div>
        </div>
    )
}

export default QuestionAlert