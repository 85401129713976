import React from 'react';
import styled from 'styled-components'

const TextMaori = styled.p`
    color: #AE0F0B;
    font-family: MediumFont;
    font-size: 15px;
    width: 70%;
    margin: 0 auto;
    @media only screen and (min-width: 768px) {
        font-size: 20px;;
    }
`;

const TextEng = styled.p`
    color: #2F373B;
    font-size: 12px;
    width: 70%;
    margin: -5px auto 20px auto;
    @media only screen and (min-width: 768px) {
        font-size: 15px;
    }
`;

const TextTextGroup = ({ textMaori, textEng }) => {

    return (
        <>
            <TextMaori>{textMaori}</TextMaori>
            <TextEng>{textEng}</TextEng>
        </>
    )
}

export default TextTextGroup;