export const data = [
  {
    Capital: "Āwhina mai",
    Currency: "Please help",
  },
  {
    Capital: "Kei hea te wharepaku",
    Currency: "Where are the toilets",
  },
  {
    Capital: "Kei hea te wharekai",
    Currency: "Where is the kitchen/ dining room",
  },
  {
    Capital: "Āe",
    Currency: "Yes",
  },
  {
    Capital: "Kāo",
    Currency: "No",
  },
  // {
  //   Capital: "Kōrero mai anō", 
  //   Currency: "Say that again",
  // },
  {
    Capital: "Kōrero mai anō",
    Currency: "Say that again",
  },
  {
    Capital: "He aha te kupu Māori mō ….?",
    Currency: "What is the Māori word for …?",
  },
  {
    Capital: "He aha te kupu Pākehā mō…?",
    Currency: "What is the English word for …?",
  },
  {
    Capital: "Homai te ….",
    Currency: "Pass me the ….",
  },
  {
    Capital: "He pātai tāku",
    Currency: "I have a question",
  },
  {
    Capital: "Kei te pai ahau",
    Currency: "I’m okay",
  },
  {
    Capital: "Kāore au I te pai",
    Currency: "I’m not okay",
  },
  {
    Capital: "Kua hiakai ahau",
    Currency: "I’m hungry",
  },
  {
    Capital: "Kua hiainu ahau",
    Currency: "I’m thirsty",
  }
];
