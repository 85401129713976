import React from 'react';
import styled from 'styled-components'
import WaiataButtons from './WaiataButtons';
import Title from '../Title';
import SongInfo from './SongInfo';

const Container = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-direction: row;
    justify-content: center;
`;

const SubTitle = styled.p`
    margin: -30px 0 50px 0;
`;

const Audio = styled.audio`
    width: 80%;
`;

const LyricContainer = styled.div`
    // background-color: #F8F8F8;
    margin: auto;
    text-align: center;
    padding 50px 0;

    @media only screen and (min-width: 1200px) {
        width: 530px;
    }
`;
const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
    height: 100%;
    width: 560px;
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    padding-bottom: 2rem;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin: auto auto 30px;
    @media only screen and (max-width: 650px) {
        width: 80%;
    }
    @media only screen and (min-width: 850px) {
        width: 50%;
    }

`;



const WaiataView = ({
    title,
    subTitle,
    song,
    lyric,
    songInfoText,
}) => {
    return (

        <Container>
            <IntroContainer>
                <div style={{ marginTop: "5rem" }}><Title title={title} /></div>
                <SubTitle>{subTitle}</SubTitle>

                <Audio src={song} controls />
                <LyricContainer>
                    {lyric}
                </LyricContainer>
                <SongInfo title={songInfoText} />
                <WaiataButtons />
            </IntroContainer>
        </Container>

    )
}

export default WaiataView;