export const data = [
  {
    Capital: "Kia ora",
    Currency: "Hello/Thanks",
  },
  {
    Capital: "Tēnā koe",
    Currency: "Greetings (1 person)",
  },
  {
    Capital: "Tēnā kōrua",
    Currency: "Greetings (2 people)",
  },
  {
    Capital: "Tēnā koutou",
    Currency: "Greetings (3 + people)",
  },
  {
    Capital: "Ata mārie",
    Currency: "Good morning",
  },
  {
    Capital: "Mōrena",
    Currency: "Morning",
  },
  {
    Capital: "Ngā mihi o te ahiahi",
    Currency: "Good afternoon",
  }
];