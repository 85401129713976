import React from 'react'

const Question = ({ words, spanRef , handleClick }) => {
  return (
    <div className="question__box-row-child">
      {words.map((word, index) => (
        <div key={index} className="question__box-col">
          <span ref={element => element && spanRef.current.push(element)} className='white__option' onClick={handleClick}>{word}</span>
        </div>
      ))}
    </div>
  )
}

export default Question