import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from '../Title'
import Button from '../Button';
import ButtonOption from '../ButtonOption';
import ErrorMessage from '../ErrorMessage';

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90vh;
`;

const Container = styled.div`
    text-align: center;
    width: 380px;
    padding: 35px 50px;

    border-radius: 10px;  
    opacity: 1;
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    @media only screen and (min-width: 768px) {
        padding: 50px 135px;
    }
`;

const AreYouMaori = ({ setIsMaori, isMaori }) => {
    const [showError, setShowError] = useState(false)
    return (
        <ContainerOut>
            <Container>
                <img src="/svg/pepeha-red.svg" alt="Are you Māori?" width={150} style={{ marginBottom: "20px" }} />
                {/* <Title title="Are you Māori?" /> */}

                <ButtonOption
                    text="Pepeha for Māori"
                    setBtState={setIsMaori}
                    btState={true}
                    active={isMaori === true}
                />
                <ButtonOption
                    text="Pepeha for all"
                    setBtState={setIsMaori}
                    btState={false}
                    active={isMaori === false}
                />
                {showError && <ErrorMessage text="Please select an option above" />}

                {
                    isMaori === "error" ?
                        <div onClick={() => setShowError(true)}>
                            <Button text="Next" />
                        </div>
                        :
                        <Link to={isMaori ? "/pepeha/maori/0.5" : "/pepeha/non-maori/0.5"}>
                            <Button text="Next" />
                        </Link>
                }
            </Container>
        </ContainerOut>
    )
}

export default AreYouMaori;