import "./PracticeQuestionPage.css"
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import QuestionPage from './QuestionPage'

const PracticeQuestionPage = ({ data, title }) => {
  const { flow, practiceView } = useParams()

  return (
    <>
      {
        data && flow > 0 &&
        <QuestionPage
          data={data}
          flow={flow}
          practiceView={practiceView}
          title={title}
        />
      }
    </>
  )
}

export default PracticeQuestionPage