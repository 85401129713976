const maoriVoices = {
    // line By line 

    // Mihimihi Basic
    'Tene koe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_10_tena_koe.mp3',
    'Tene Korua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_11_tena_korua.mp3',
    'Tene koutou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_12_tena_koutou.mp3',
    'Tene koutou katoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_13_tena_koutou_katoa.mp3',
    'Tene tātou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_14_tena_tatou.mp3',
    'Tene tātou katoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_15_tena_tatou_katoa.mp3',
    'e hoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_18_e_hoa.mp3',
    'e hoa mā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_19_e_hoa_mamp3.mp3',
    'e te whānau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_20_e_te_whanau.mp3',
    'e te manuhiri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_21_e_te_manuhiri.mp3',
    'e aku rangatira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_22_e_aku_rangatira.mp3',
    'e te hau kāinga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_23_e_te_hau_kainga.mp3',
    'kua tae mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_20_kua_tae_mai_nei.mp3',
    'kua huihui mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_4/Mihimihi_mihi_advance_1_4_21_kua_huihui_mai_nei.mp3',
    'kua haere mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_22_kua_haere_mai_nei.mp3',
    'kua eke mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_23_kua_eke_mai_nei.mp3',
    'kua pōwhiri mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_24_powhiti_mai_nei.mp3',
    'kua karanga mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_25_karanga_mai_nei.mp3',
    'ki tēnei marae': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_14_ki_tenei_marae.mp3',
    'ki tēnei whare': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_15_ki_tenei_whare.mp3',
    'ki tēnei wāhi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_16_ki_tenei_wahi.mp3',
    'ki tēnei hui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_17_ki_tenei_hui.mp3',
    'ki tēnei whenua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_18_ki_tenei_whenua.mp3',
    'ki Aotearoa nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_19_aotearoa_nei.mp3',
    'ki te whakarite': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_10_ki_te_whakarite.mp3',
    'ki te kawe mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_11_ki_te_kawe_mai.mp3',
    'ki te kōrero': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_12_ki_te_korero.mp3',
    'ki te wānanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_6/Mihimihi_mihi_advance_1_6_13_ki_te_wananga.mp3',
    'ki te whāriki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_14_ki_te_whariki.mp3',
    'ki te rongo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_15_ki_te_rongo..mp3',
    'i te aroha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_22_i_te_aroha.mp3',
    'i ngā whakaaro': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_23_i_nga_whakaaro.mp3',
    'i ngā take o te wā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_24_i_nga_take_o_te_wa.mp3',
    'i te kaupapa o te rā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_25_i_te_kaupapa_o_te_ra.mp3',
    'i ngā uaua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_8/Mihimihi_mihi_advance_1_8_26_i_nga_uaua.mp3',
    'i ngā pātai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_27_i_nga_patai.mp3',
    'i te kōrero': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_28_i_te_korero.mp3',
    'nō reira e hoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_9/mihimihi_mihi_basic_1_9_20_no_reira_e_hoa.mp3',
    'nō reira e hoa mā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_9/mihimihi_mihi_basic_1_9_21_no_reira_e_hoa_ma.mp3',
    'nō reira e te whānau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_9/mihimihi_mihi_basic_1_9_22_no_reira_e_te_whanau.mp3',
    'nō reira e te manuhiri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_9/mihimihi_mihi_basic_1_9_23_no_reira_e_te_manuhiri.mp3',
    'nō reira e aku rangatira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_9/mihimihi_mihi_basic_1_9_24_no_reira_e_aku_rangatira.mp3',
    'nō reira e ngā mana': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_9/mihimihi_mihi_basic_1_9_25_no_reira_e_nga_mana.mp3',

    // Mihimihi Advance
    'Tēnā koe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_1/Mihimihi_mihi_advance_1_1_10_tena_koe.mp3',
    'Tēnā kōrua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_1/Mihimihi_mihi_advance_1_1_11_tena_korua.mp3',
    'Tēnā koutou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_1/Mihimihi_mihi_advance_1_1_13_tena_koutou_katoa.mp3',
    'Tēnā koutou katoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_1/Mihimihi_mihi_advance_1_1_13_tena_koutou_katoa.mp3',
    'Tēnā tātou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_1/Mihimihi_mihi_advance_1_1_14_tena_tatou.mp3',
    'Tēnā tātou katoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_1/Mihimihi_mihi_advance_1_1_15_tena_tatou_katoa.mp3',
    'E kiia nei te kōrero Tūwhitia te hopo':'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_42_E_kiia_nei_te_korero_4.mp3',
    'E kiia nei te kōrero He aha te mea nui o tēnei ao, he tangata':'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_39_E_kiia_nei_te_korero_1.mp3',
    'E kiia nei te kōrero Mā pango mā whero ka oti te mahi':'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_40_E_kiia_nei_te_korero_2.mp3',
    'E kiia nei te kōrero He wake eke noa':'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_41_E_kiia_nei_te_korero_3.mp3',
    'E kiia nei te kōrero Manuka takato, hikitia ake':'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_43_E_kiia_nei_te_korero_5.mp3',
    // 'e hoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_2/Mihimihi_mihi_advance_1_2_19_e_hoa.mp3',
    // 'e hoa mā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_2/Mihimihi_mihi_advance_1_2_20_e_hoa_ma.mp3',
    // 'e te whānau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_2/Mihimihi_mihi_advance_1_2_21_e_te_whanau.mp3',
    // 'e te manuhiri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_2/Mihimihi_mihi_advance_1_2_22_e_te_manuhiri.mp3',
    // 'e aku rangatira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_2/Mihimihi_mihi_advance_1_2_23_e_aku_rangatira.mp3',
    // 'e te hau kāinga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_2/Mihimihi_mihi_advance_1_2_24_e_te_hau_kainga.mp3',
    // 'kua tae mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_4/Mihimihi_mihi_advance_1_4_20_kua_tae_mai_nei.mp3',
    // 'kua huihui mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_4/Mihimihi_mihi_advance_1_4_21_kua_huihui_mai_nei.mp3',
    // 'kua haere mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_4/Mihimihi_mihi_advance_1_4_22_kua_haere_mai_nei.mp3',
    // 'kua eke mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_4/Mihimihi_mihi_advance_1_4_23_kua_eke_mai_nei.mp3',
    // 'kua pōwhiri mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_4/Mihimihi_mihi_advance_1_4_24_kua_powhiti_mai_nei.mp3',
    // 'kua karanga mai nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_4/Mihimihi_mihi_advance_1_4_25_kua_karanga_mai_nei.mp3',
    // 'ki tēnei marae': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_5/Mihimihi_mihi_advance_1_5_14_ki_tenei_marae.mp3',
    // 'ki tēnei whare': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_5/Mihimihi_mihi_advance_1_5_15_ki_tenei_whare.mp3',
    // 'ki tēnei wāhi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_5/Mihimihi_mihi_advance_1_5_16_ki_tenei_wahi.mp3',
    // 'ki tēnei hui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_5/Mihimihi_mihi_advance_1_5_17_ki_tenei_hui.mp3',
    // 'ki tēnei whenua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_5/Mihimihi_mihi_advance_1_5_18_ki_tenei_whenua.mp3',
    // 'ki Aotearoa nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_5/Mihimihi_mihi_advance_1_5_19_ki_aotearoa_nei.mp3',
    // 'ki te whakarite': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_6/Mihimihi_mihi_advance_1_6_10_ki_te_whakarite.mp3',
    // 'ki te kawe mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_6/Mihimihi_mihi_advance_1_6_11_ki_te_kawe_mai.mp3',
    // 'ki te kōrero': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_6/Mihimihi_mihi_advance_1_6_12_ki_te_korero.mp3',
    // 'ki te wānanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_6/Mihimihi_mihi_advance_1_6_13_ki_te_wananga.mp3',
    // 'ki te whāriki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_6/Mihimihi_mihi_advance_1_6_14_ki_te_whariki.mp3',
    // 'ki te rongo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_6/Mihimihi_mihi_advance_1_6_15_ki_te_rongo.mp3',
    // 'i te aroha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_8/Mihimihi_mihi_advance_1_8_22_i_te_aroha.mp3',
    // 'i ngā whakaaro': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_8/Mihimihi_mihi_advance_1_8_23_i_nga_whakaaro.mp3',
    // 'i ngā take o te wā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_8/Mihimihi_mihi_advance_1_8_24_i_take_o_te_wa.mp3',
    // 'i te kaupapa o te rā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_8/Mihimihi_mihi_advance_1_8_25_i_te_kaupapa_o_te_ra.mp3',
    // 'i ngā uaua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_8/Mihimihi_mihi_advance_1_8_26_i_nga_uaua.mp3',
    // 'i ngā pātai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_8/Mihimihi_mihi_advance_1_8_27_i_nga_patai.mp3',
    // 'i te kōrero': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_8/Mihimihi_mihi_advance_1_8_28_i_te_korero.mp3',

    'E kiia nei te kōrero' : 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_39_E_kiia_nei_te_korero_0_New.mp3',
    'He aha te mea nui o tēnei ao, he tangata': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_39_E_kiia_nei_te_korero_1_New.mp3',
    'Mā pango mā whero ka oti te mahi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_40_E_kiia_nei_te_korero_2_New.mp3',
    'He wake eke noa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_40_E_kiia_nei_te_korero_3_New.mp3',
    'Tūwhitia te hopo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_40_E_kiia_nei_te_korero_4_New.mp3',
    'Manuka takato, hikitia ake': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_10/Mihimihi_mihi_advance_1_10_40_E_kiia_nei_te_korero_5_New.mp3',

    'Nā konā, kei a koe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_11/Mihimihi_mihi_advance_1_11_10_na_kona_kei_a_koe.mp3',
    'Nā konā, kei a kōrua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_11/Mihimihi_mihi_advance_1_11_11_na_kona_kei_a_korua.mp3',
    'Nā konā, kei a koutou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_11/Mihimihi_mihi_advance_1_11_12_na_kona_kei_a_koutou.mp3',
    'Nā konā, kei a ahau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_11/Mihimihi_mihi_advance_1_11_13_na_kona_kei_a_ahau.mp3',
    'Nā konā, kei a mātou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_11/Mihimihi_mihi_advance_1_11_14_na_kona_kei_a_matou.mp3',
    'Nā konā, kei a tātou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_11/Mihimihi_mihi_advance_1_11_15_na_kona_kei_a_tatou.mp3',
    'mō te kaha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_12/Mihimihi_mihi_advance_1_12_11_mo_te_kaha.mp3',
    'mō te māia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_12/Mihimihi_mihi_advance_1_12_12_mo_te_maia.mp3',
    'mō te piri pono': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_12/Mihimihi_mihi_advance_1_12_13_mo_te_piri_pono.mp3',
    'mō te manawanui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_12/Mihimihi_mihi_advance_1_12_14_mo_te_manawanui.mp3',
    'mō te kai ngākau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_12/Mihimihi_mihi_advance_1_12_15_mo_te_kai_ngakau.mp3',
    'mō te ngana': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_12/Mihimihi_mihi_advance_1_12_16_mo_te_ngana.mp3',
    'Ki te āwhina': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_13/Mihimihi_mihi_advance_1_13_9_ki_te_awhina.mp3',
    'Ki te hāpai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_13/Mihimihi_mihi_advance_1_13_10_ki_te_hapai.mp3',
    'Ki te tautoko': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_13/Mihimihi_mihi_advance_1_13_11_ki_te_tautoko.mp3',
    'Ki te whakamārama': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_13/Mihimihi_mihi_advance_1_13_12_ki_te_whakamarama.mp3',
    'Ki te whakatikatika': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_13/Mihimihi_mihi_advance_1_13_13_ki_te_whakatikatika.mp3',
    'Ki te kōrerorero': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_13/Mihimihi_mihi_advance_1_13_14_ki_te_korerorero.mp3',
    'i ngā kōrero': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_14/Mihimihi_mihi_advance_1_14_14_i_nga_korero.mp3',
    'i ngā tikanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_14/Mihimihi_mihi_advance_1_14_15_i_nga_tikanga.mp3',
    'i ngā āhuatanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_14/Mihimihi_mihi_advance_1_14_16_i_nga_ahuatanga.mp3',
    'i ngā kaupapa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_14/Mihimihi_mihi_advance_1_14_17_i_nga_kaupapa.mp3',
    'i ngā take': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_14/Mihimihi_mihi_advance_1_14_18_i_nga_take.mp3',
    // 'i ngā uaua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_14/Mihimihi_mihi_advance_1_14_19_i_nga_uaua.mp3',
    'e pā ana ki tēnei marae': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_15/Mihimihi_mihi_advance_1_15_21_e_pa_ana_ki_tenei_marae.mp3',
    'e pā ana ki tēnei whare': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_15/Mihimihi_mihi_advance_1_15_22_e_pa_ana_ki_tenei_whare.mp3',
    'e pā ana ki tēnei wāhi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_15/Mihimihi_mihi_advance_1_15_23_e_pa_ana_ki_tenei_wahi.mp3',
    'e pā ana ki tēnei hui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_15/Mihimihi_mihi_advance_1_15_24_e_pa_ana_ki_tenei_hui.mp3',
    'e pā ana ki tēnei whakaminenga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_15/Mihimihi_mihi_advance_1_15_25_e_pa_ana_ki_tenei_whakaminenga.mp3',
    'e pā ana ki Aotearoa nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_15/Mihimihi_mihi_advance_1_15_26_e_pa_ana_ki_aotearoa_nei.mp3',
    'e pā ana ki tēnei āhuatanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_15/Mihimihi_mihi_advance_1_15_27_e_pa_ana_ki_tenei_ahatanga.mp3',
    'e pā ana ki tēnei kaupapa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_15/Mihimihi_mihi_advance_1_15_28_e_pa_ana_ki_tenei_kaupapa.mp3',
    // 'nō reira e hoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_16/Mihimihi_mihi_advance_1_16_20_no_reira_e_hoa_.mp3',
    // 'nō reira e hoa mā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_16/Mihimihi_mihi_advance_1_16_21_no_reira_e_hoa_ma.mp3',
    // 'nō reira e te whānau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_16/Mihimihi_mihi_advance_1_16_22_no_reira_e_hoa_whanau.mp3',
    // 'nō reira e te manuhiri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_16/Mihimihi_mihi_advance_1_16_23_no_reira_e_hoa_manuhiri.mp3',
    // 'nō reira e aku rangatira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_16/Mihimihi_mihi_advance_1_16_24_no_reira_e_hoa_rangatira.mp3',
    // 'nō reira e ngā mana': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Continuously/1_16/Mihimihi_mihi_advance_1_16_25_no_reira_e_hoa_mana.mp3',

    // Kete Korero
    'Mihi Tīmatanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_timatanga/Kete_korero_mihi_timatanga_18.mp3',
    'Kia ora': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_timatanga/Kete_korero_mihi_timatanga_19_kia_ora.mp3',
    'tena koe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_timatanga/Kete_korero_mihi_timatanga_20_tena_koe.mp3',
    'tena korua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_timatanga/Kete_korero_mihi_timatanga_21_tena_korua.mp3',
    'tena koutou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_timatanga/Kete_korero_mihi_timatanga_22_tena_koutou.mp3',
    'Ata mārie': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_timatanga/Kete_korero_mihi_timatanga_23_ata_marie.mp3',
    'Mōrena': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_timatanga/Kete_korero_mihi_timatanga_24_morena.mp3',
    'Ngā mihi o te ahiahi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_timatanga/Kete_korero_mihi_timatanga_25_nga_mihi.mp3',
    'Mihi Mutunga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_mutunga/Kete_korero_mihi_mutunga_21.mp3',
    'Ka kite': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_mutunga/Kete_korero_mihi_mutunga_22_ka_kite.mp3',
    'Haere rā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_mutunga/Kete_korero_mihi_mutunga_23_haere_ra.mp3',
    'E noho rā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_mutunga/Kete_korero_mihi_mutunga_24_e_noho_ra.mp3',
    'Hei konei rā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_mutunga/Kete_korero_mihi_mutunga_25_hei_konei_ra.mp3',
    'Pō mārie': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_mutunga/Kete_korero_mihi_mutunga_26_po_marie.mp3',
    'Kia pai te rā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_mutunga/Kete_korero_mihi_mutunga_27_kia_pai_te_ra.mp3',
    'Hei te wā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_mutunga/Kete_korero_mihi_mutunga_28_hei_te_wa.mp3',
    'Ā tōna wā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/mihi_mutunga/Kete_korero_mihi_mutunga_29_a_tona_wa.mp3',
    'Kupu Whakatepe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kupu_whakatepe/Kete_korero_kupu_whakatepe_14.mp3',
    'Nā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kupu_whakatepe/Kete_korero_mihi_mutunga_15_na.mp3',
    'Nāku, nā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kupu_whakatepe/Kete_korero_mihi_mutunga_16_naku_na.mp3',
    'Nāku iti noa, nā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kupu_whakatepe/Kete_korero_mihi_mutunga_17_naku_iti_noa_na.mp3',
    'Ngā mihi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kupu_whakatepe/Kete_korero_mihi_mutunga_18_mga_mihi.mp3',
    'Ngā mihi nui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kupu_whakatepe/Kete_korero_mihi_mutunga_19_nga_mihi_nui.mp3',
    'Hei konā mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kupu_whakatepe/Kete_korero_mihi_mutunga_20_hei_kona_mai.mp3',
    'Kīwaha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_kiwaha_40.mp3',
    'Ka mau te wehi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_41_ka_mau_te_wehi.mp3',
    'Koia kei a koe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_42_koia_kei_a_koe.mp3',
    'Hei aha tāu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_43_hei.mp3',
    'Aua hoki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_44_aua.mp3',
    'Kei wīwī kei wāwā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_45_kei.mp3',
    'Nē?': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_46_ne.mp3',
    'Nē': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_47_ne.mp3',
    'Nā whai anō': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_48_na.mp3',
    'Tōnā tikanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_49_tona.mp3',
    'E taea ana te aha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_50_E.mp3',
    'Hoatu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_51_hoatu.mp3',
    'Hoake': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_52_hoake.mp3',
    'Nā wai rā, nā wai rā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_53_na_wai.mp3',
    'Ka mutu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_54_ka.mp3',
    'Kei tūpono': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/kiwaha/Kete_korero_mihi_kiwaha_55_kei.mp3',
    'Tohutohu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_17_.mp3',
    'Kuhu mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_18_kuhu_mai.mp3',
    'E kai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_19_E_kai.mp3',
    'Haere mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_20_haere_mai.mp3',
    'Haere atu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_21_haere_atu.mp3',
    'E oma': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_22_e_oma.mp3',
    'Āta haere': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_23_ata.mp3',
    'Whakarongo mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_24_whakarongo.mp3',
    'Titiro mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_25_titiro.mp3',
    'Turn to me': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_26_huri.mp3',
    'Taihoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_27_taihoa.mp3',
    'Kōrero mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_28_korero.mp3',
    'Hoihoi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/tohutohu/Kete_korero_mihi_tohutohu_29_hoihoi.mp3',
    'Reo Ōpaki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_31.mp3',
    'Āwhina mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_32_awhina.mp3',
    'Kei hea te wharepaku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_33_kei.mp3',
    'Kei hea te wharekai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_34_kei.mp3',
    'Āe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_35_ae.mp3',
    'Kāo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_36_kao.mp3',
    'Kōrero mai anō': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_37_korero.mp3',
    'He aha te kupu Māori mō ….?': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_38_he.mp3',
    'He aha te kupu Pākehā mō…?': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_39_he.mp3',
    'Homai te ….': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_40_homai.mp3',
    'He pātai tāku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_41_he.mp3',
    'Kei te pai ahau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_42_kei.mp3',
    'Kāore au I te pai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_43_kaore.mp3',
    'Kua hiakai ahau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_44_kua.mp3',
    'Kua hiainu ahau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Continuously/reo_opaki/Kete_korero_mihi_roe_opaki_50_kua.mp3',














    // Word By Word 

    // Mihimihi Basic
    'tēnā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_1_tena.mp3',
    'koe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_2_koe.mp3',
    'kōrua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_3_Korua.mp3',
    'koutou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_4_koutou.mp3',
    'katoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_6_katoa.mp3',
    'tātou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_1/mihimihi_mihi_basic_1_1_7_tatou.mp3',
    'e': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_1_e.mp3',
    'hoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_2_hoa.mp3',
    'mā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_4_ma.mp3',
    'te': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_15_te.mp3',
    'whānau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_7_whanau.mp3',
    'manuhiri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_10_manuhiri.mp3',
    'aku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_12_aku.mp3',
    'rangatira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_13_rangatira.mp3',
    'hau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_16_hau.mp3',
    'kāinga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_2/mihimihi_mihi_basic_1_2_17_kainga.mp3',
    'tae': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_3_tae.mp3',
    'mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_12_mai.mp3',
    'kiia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_2_kiia.mp3',
    'nei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_13_nei.mp3',
    'huihui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_5_huihui.mp3',
    'haere': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_8_haere.mp3',
    'eke': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_11_eke.mp3',
    'pōwhiri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_14_powhiri.mp3',
    'karanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_4/mihimihi_mihi_basic_1_4_17_karanga.mp3',
    'tēnei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_10_tenei.mp3',
    'marae': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_3_marae.mp3',
    'whare': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_5_whare.mp3',
    'wāhi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_7_wahi.mp3',
    'hui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_9_hui.mp3',
    'whenua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_11_whenua.mp3',
    'aotearoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_5/mihimihi_mihi_basic_1_5_12_aotearoa.mp3',
    'whakarite': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_3_whakarite.mp3',
    'kawe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_4_kawe.mp3',
    'kōrero': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_6_korero.mp3',
    'wānanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_7_wananga.mp3',
    'whāriki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_8_whariki.mp3',
    'rongo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_6/mihimihi_mihi_basic_1_6_9_rongo.mp3',
    'aroha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_3_aroha.mp3',
    'ngā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_16_nga.mp3',
    'whakaaro': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_5_whakaaro.mp3',
    'take': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_7_take.mp3',
    'o': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_13_o.mp3',
    'wā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_10_wa.mp3',
    'kaupapa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_12_kaupapa.mp3',
    'rā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_15_ra.mp3',
    'uaua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_17_uaua.mp3',
    'pātai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_8/mihimihi_mihi_basic_1_8_19_patai.mp3',
    'mana': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Basic/1_9/mihimihi_mihi_basic_1_9_19_mana.mp3',


    // Mihimihi Advanced
    'he': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_28_he.mp3',
    'aha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_7_aha.mp3',
    'mea': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_9_mea.mp3',
    'nui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_10_nui.mp3',
    'ao,': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_13_ao.mp3',
    'tangata': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_15_tangata.mp3',
    'pango': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_21_pango.mp3',
    'whero': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_23_whero..mp3',
    'ka': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_24_ka.mp3',
    'oti': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_25_oti.mp3',
    'mahi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_27_mahi.mp3',
    'wake': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_29_waka.mp3',
    'noa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_31_noa.mp3',
    'tūwhitia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_32_tuwhitia.mp3',
    'hopo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_34_hopo.mp3',
    'manuka': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_35_manuka..mp3',
    'takato,': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_36_takato.mp3',
    'hikitia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_37_hikitia.mp3',
    'ake': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_10/Mihimihi_mihi_advance_1_10_38_ake.mp3',
    'ahau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_11/Mihimihi_mihi_advance_1_11_7_ahau.mp3',
    'matou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_11/Mihimihi_mihi_advance_1_11_8_matoa.mp3',
    'tatou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_11/Mihimihi_mihi_advance_1_11_9_tatou.mp3',
    'kaha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_12/Mihimihi_mihi_advance_1_12_3_kaha.mp3',
    'maia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_12/Mihimihi_mihi_advance_1_12_4_maia.mp3',
    'piri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_12/Mihimihi_mihi_advance_1_12_5_piri.mp3',
    'pono': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_12/Mihimihi_mihi_advance_1_12_6_pono.mp3',
    'manawanui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_12/Mihimihi_mihi_advance_1_12_7_manawanui.mp3',
    'kai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_12/Mihimihi_mihi_advance_1_12_8_kai.mp3',
    'ngana': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_12/Mihimihi_mihi_advance_1_12_10_ngana.mp3',
    'āwhina': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_13/Mihimihi_mihi_advance_1_13_3_awhina.mp3',
    'hāpai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_13/Mihimihi_mihi_advance_1_13_4_hapai.mp3',
    'tautoko': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_13/Mihimihi_mihi_advance_1_13_5_tautoko.mp3',
    'whakamārama': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_13/Mihimihi_mihi_advance_1_13_6_whakamarama.mp3',
    'whakatikatika': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_13/Mihimihi_mihi_advance_1_13_7_whakatikatika.mp3',
    'kōrerorero': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_13/Mihimihi_mihi_advance_1_13_8_korerorero.mp3',
    'i': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_14/Mihimihi_mihi_advance_1_14_1_i.mp3',
    'tikanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_14/Mihimihi_mihi_advance_1_14_5_tikanga.mp3',
    'āhuatanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_14/Mihimihi_mihi_advance_1_14_7_ahuatanga.mp3',
    'pā ': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_15/Mihimihi_mihi_advance_1_15_2_pa.mp3',
    'ana': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_15/Mihimihi_mihi_advance_1_15_3_ana.mp3',
    'ki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_15/Mihimihi_mihi_advance_1_15_4_ki.mp3',
    // 'hui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_15/Mihimihi_mihi_advance_1_15_12_hui.mp3',
    'whakaminenga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_15/Mihimihi_mihi_advance_1_15_14_whakaminenga.mp3',
    'nō': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_16/Mihimihi_mihi_advance_1_16_1_no.mp3',
    'reira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_16/Mihimihi_mihi_advance_1_16_2_reira.mp3',
    'pā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Mihi/Advance/Separately+/1_15/Mihimihi_mihi_advance_1_15_2_pa.mp3',

    // Kete Korero
    'kete': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kete.mp3',
    'tīmatanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Timatanga/Kete_korero_Mihi_Timatanga_00_timatanga.mp3',
    'mutunga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_2_mutunga.mp3',
    'whakatepe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kuou_Whakatepa/Kete_korero_kupu_whakatepa_2_whakapete.mp3',
    'kīwaha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_1_kiwaha.mp3',
    'tohutohu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Tohutohu/Kete_korero_tohutohu_1_tohutohu.mp3',
    'opaki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_2_opaki.mp3',
    'reo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_1_roe.mp3',
    'kia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Timatanga/Kete_korero_Mihi_Timatanga_1_kia.mp3',
    'ora': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Timatanga/Kete_korero_Mihi_Timatanga_2_ora.mp3',
    'ata': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Timatanga/Kete_korero_Mihi_Timatanga_9_ata.mp3',
    'marie': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Timatanga/Kete_korero_Mihi_Timatanga_10_marie.mp3',
    'morena': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Timatanga/Kete_korero_Mihi_Timatanga_11_morena.mp3',
    'mihi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Timatanga/Kete_korero_Mihi_Timatanga_0_mihi.mp3',
    'ahiahi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Timatanga/Kete_korero_Mihi_Timatanga_16_ahiahi.mp3',
    // 'ka': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_3_ka.mp3',
    'kite': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_4_kite.mp3',
    'ra': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_16_ra.mp3',
    'hei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_7_hei.mp3',
    'konei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_10_konei.mp3',
    'po': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_11_po.mp3',
    'pai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_14_pai.mp3',
    'wa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_17_wa.mp3',
    'a': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_18_a.mp3',
    'tona': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Mihi_Mutunga/Kete_korero_Mihi_Timatanga_19_tona.mp3',
    'nā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kuou_Whakatepa/Kete_korero_kupu_whakatepa_3_na.mp3',
    'nāku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kuou_Whakatepa/Kete_korero_kupu_whakatepa_4_naku.mp3',
    'iti': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kuou_Whakatepa/Kete_korero_kupu_whakatepa_5_iti.mp3',
    'konā,': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kuou_Whakatepa/Kete_korero_kupu_whakatepa_11_kona.mp3',
    'mau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_3_mau.mp3',
    'wehi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_5_wehi.mp3',
    'koia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_6_koia.mp3',
    'kei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_15_kei.mp3',
    'tāu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_12_tau.mp3',
    'aua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_13_aua.mp3',
    'hoki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_14_hoki.mp3',
    'wīwī': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_16_wiwi.mp3',
    'wāwā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_17_wawa..mp3',
    'nē?': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_18_ne(really_).mp3',
    'whai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_21_whai.mp3',
    'anō': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_22_ano.mp3',
    'taea': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_26_taea.mp3',
    'hoatu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_30_hoatu.mp3',
    'hoake': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_31_hoake.mp3',
    'wai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_33_wai.mp3',
    'mutu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_36_mutu.mp3',
    'tūpono': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Kiwaha/Kete_korero_kiwaha_38_tupono.mp3',
    'kuhu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Tohutohu/Kete_korero_tohutohu_2_kuhu.mp3',
    'oma': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Tohutohu/Kete_korero_tohutohu_7_oma.mp3',
    'whakarongo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Tohutohu/Kete_korero_tohutohu_9_whakarongo.mp3',
    'titiro': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Tohutohu/Kete_korero_tohutohu_10_titiro.mp3',
    'taihoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Tohutohu/Kete_korero_tohutohu_12_taihoa.mp3',
    'hoihoi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Tohutohu/Kete_korero_tohutohu_15_hoihoi.mp3',
    'huri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Tohutohu/Kete_korero_tohutohu_11_huri.mp3',
    'hea': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_6_hea.mp3',
    'wharepaku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_8_wharepaku.mp3',
    'wharekai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_9_wharekai.mp3',
    'āe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_10_ae.mp3',
    'kāo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_11_kao.mp3',
    'kupu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_16_kupu.mp3',
    'mō': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_18_mo.mp3',
    'pākehā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_19_pakeha.mp3',
    'homai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_20_homai.mp3',
    'tāku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_22_taku.mp3',
    // 'pai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_22_pai.mp3',
    'i ': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_26_i.mp3',
    'kua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_27_kua.mp3',
    'hiakai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_28_hiakai..mp3',
    'hiainu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Kete_Korero/Separately/Reo_Opaki/Kete_korero_roe_opaki_30_hiainu.mp3',

    // Pepeha Maori
    'pepeha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Pepeha/Pepeha_Maori_advance/Pepeha_Maori_Advance_1_Pepeha.mp3',
    'maunga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Pepeha/Pepeha_Maori_advance/Pepeha_Maori_Advance_4_maunga.mp3',
    'roto': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Pepeha/Pepeha_Maori_advance/Pepeha_Maori_Advance_5_roto.mp3',
    'awa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Pepeha/Pepeha_Maori_advance/Pepeha_Maori_Advance_6_awa.mp3',
    'moana': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Pepeha/Pepeha_Maori_advance/Pepeha_Maori_Advance_7_moana.mp3',
    'iwi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Pepeha/Pepeha_Maori_advance/Pepeha_Maori_Advance_11_iwi.mp3',
    'hapū': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Pepeha/Pepeha_Maori_advance/Pepeha_Maori_Advance_12_hapu.mp3',
    'ingoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Pepeha/Pepeha_Maori_advance/Pepeha_Maori_Advance_16_ingoa.mp3',
    'noho': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Pepeha/Pepeha_Maori_advance/Pepeha_Maori_Advance_22_noho.mp3',

    // whakapapa
    'whakapapa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Whakapapa/Whakapapa_35.mp3',
    'taha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Whakapapa_16.mp3',
    'māmā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Whakapapa_7.mp3',
    'māmā,': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Whakapapa_7.mp3',
    'koroua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Whakapapa_10.mp3',
    'koroua,': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Whakapapa_10.mp3',
    'pāpā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Wwhakapapa_19.mp3',
    'pāpā,': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Wwhakapapa_19.mp3',
    'kuia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Whakapapa_25.mp3',
    'whaiāipo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Whakapapa_34.mp3',
    'aku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Whakapapa/Whakapapa_64.mp3',
    'mokopuna': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Whakapapa/Whakapapa_65.mp3',
    'tamariki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Whakapapa/Whakapapa_59.mp3',
    'tamaiti': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Whakapapa/Whakapapa_38.mp3',
    'taku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Whakapapa_9.mp3',
    'ko': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Child/Whakapapa_8.mp3',
    'Ko': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Whakapapa/Whakapapa_63.mp3',
    'rāua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Whakapapa/Whakapapa_43.mp3',
    'rātou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/Mihimihi/Whakapapa/Whakapapa/Whakapapa_55.mp3',

    

    // Kupu taka
    'ā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/a.mp3',
    'e tipu e rea': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/e_tipo_e_rea.mp3',
    'ehara': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/ehara.mp3',
    'ehara i te mea': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/ehara_i_te_mea.mp3',
    'he hōnore': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/he_honore.mp3',
    'he tangata': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/he_tangata.mp3',
    'he whānau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/he_whanau.mp3',
    'ka mate': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/ka_mate.mp3',
    'karakia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/karakia.mp3',
    'karakia mō te kai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/karakia_mo_te_kai.mp3',
    'karakia timatanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/karakia_timatanga.mp3',
    'karawhiua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/karawhiua.mp3',
    'kia ora': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/kia_ora.mp3',
    'koutou katoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/koutou_katoa.mp3',
    'kūoro': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/kuoro.mp3',
    'kupu taka': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/kupu_taka.mp3',
    'maru': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/maru.mp3',
    'mate': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/mate.mp3',
    'Māori': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/maori.mp3',
    'manaaki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/manaki.mp3',
    'mō tēnei rauemi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/mo_tenei_rauemi.mp3',
    'ngā kūoro': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/nga_kuoro.mp3',
    'ngā orokati': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/nga_orokati.mp3',
    'ngā oropuare': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/nga_oropuare.mp3',
    'ngāwari': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/ngawari.mp3',
    'nō reira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/no_reira.mp3',
    'orokati': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/orokati.mp3',
    'oropuare': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/oropuare.mp3',
    'poipoia': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/poipoia.mp3',
    'pōkarekare': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/pokarekare.mp3',
    'puke': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/puke.mp3',
    'rauemi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/rauemi.mp3',
    'taka': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/taka.mp3',
    'taki whakaaro': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/taki_whakaro.mp3',
    'taku marae': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/taku_marae.mp3',
    'tamariki': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tamariki.mp3',
    'tātou katoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tatou_katoa.mp3',
    'te aroha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/te_aroha.mp3',
    'te kākano': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/te_kakano.mp3',
    'te pae tawhiti': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/te_pae_tawhiti.mp3',
    'timatanga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/timatanga.mp3',
    'tika': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tika.mp3',
    'tipu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tipu.mp3',
    'tīpuna': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tipuna.mp3',
    'tira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tira.mp3',
    'toru': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/toru.mp3',
    'tū': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tu.mp3',
    'Tū tira mai ngā iwi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tu_tira_mai_nga_iwi.mp3',
    'waiata': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/waiata.mp3',
    'waka': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/waka.mp3',
    'rohe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/rohe.mp3',
    'whā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/wha.mp3',
    'whai rawa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/whai_rawa.mp3',
    'whakahua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/whakahua.mp3',
    'whakahua tika': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/whakahua_tika.mp3',
    // 'kupu': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/kupu.mp3',
    // 'nō': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/no.mp3',
    // 'noho': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/noho.mp3',
    // 'o': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/o.mp3',
    // 'pepeha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/pepeha.mp3',
    // 'roto': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/roto.mp3',
    // 'rangatira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/rangatira.mp3',
    // 'taha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/taha.mp3',
    // 'pāpā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/papa.mp3',
    // 'pāpā,': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/papa.mp3',
    // 'rāua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/raua.mp3',
    // 'hapū': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/hapu.mp3',
    // 'hau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/hau.mp3',
    // 'he': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/he.mp3',
    // 'hoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/hoa.mp3',
    // 'ingoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/ignoa.mp3',
    // 'iwi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/iwi.mp3',
    // 'ka': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/ka.mp3',
    // 'katoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/katoa.mp3',
    // 'kai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/kai.mp3',
    // 'kāinga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/kainga.mp3',
    // 'koe': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/koe.mp3',
    // 'koroua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/koroua.mp3',
    // 'koroua,': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/koroua.mp3',
    // 'kōrua': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/korua.mp3',
    // 'koutou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/koutou.mp3',
    // 'ahau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/ahau.mp3',
    // 'aku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/aku.mp3',
    // 'anō': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/ano.mp3',
    // 'aroha': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/aroha.mp3',
    // 'awa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/awa.mp3',
    // 'e aku rangatira': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/e_aku_rangatira.mp3',
    // 'e hoa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/e_hoa.mp3',
    // 'e hoa mā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/e_hoa_ma.mp3',
    // 'e te hau kāinga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/e_te-hau-kainga.mp3',
    // 'e te manuhiri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/e_te_manuhiri.mp3',
    // 'e te whānau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/e_te_whanau.mp3',
    // 'mai': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/mai.mp3',
    // 'māmā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/mama.mp3',
    // 'māmā,': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/mama.mp3'
    // 'manawanui': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/manawanui.mp3',
    // 'manuhiri': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/manuhiri.mp3',
    // 'marae': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/marae.mp3',
    // 'maunga': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/maunga.mp3',
    // 'mea': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/mea.mp3',
    // 'mihi': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/mihi.mp3',
    // 'mō': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/mo.mp3',
    // 'moana': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/moana.mp3',
    // 'ngā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/nga.mp3',
    // 'taku': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/taku.mp3',
    // 'tēnei': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tenei.mp3',
    // 'tamaiti': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tamaiti.mp3',
    // 'whaiāipo': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/whaiaipo.mp3',
    // 'tātou': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tatou.mp3',
    // 'tēnā': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/tena.mp3',
    // 'te': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/te.mp3',
    // 'whakapapa': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/whakapapa.mp3',
    // 'whānau': 'https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/glossary/whanau.mp3',
}




export const getMaoriVoices = (text) => maoriVoices[text]