import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const These = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='ngā kōrero'
                engText="discussions"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "ngā kōrero" ? true : false}
            />
            <ButtonChoice
                maoriText="ngā tikanga"
                engText="customs"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "ngā tikanga" ? true : false}
            />
            <ButtonChoice
                maoriText="ngā āhuatanga"
                engText="matters"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "ngā āhuatanga" ? true : false}
            />
            <ButtonChoice
                maoriText="ngā kaupapa"
                engText="topics"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "ngā kaupapa" ? true : false}
            />
            <ButtonChoice
                maoriText="ngā take"
                engText="issues"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "ngā take" ? true : false}
            />
            <ButtonChoice
                maoriText="ngā uaua"
                engText="challenges"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "ngā uaua" ? true : false}
            />
        </ButtonContainer>
    )
}

export default These;