import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from '../Title'
import Button from '../Button';

const Container = styled.div`
    text-align: center;
    width: 380px;
    margin: 0 20px;
    padding: 35px 50px;

    border-radius: 10px;  
    opacity: 1;
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    @media only screen and (min-width: 768px) {
        padding: 50px 135px;
    }
`;

const Text = styled.p`
    font-size: 12px;
    margin: -25px 0 30px 0;

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 20px;
    }
`;

const InputStyled = styled.input`
font-size: 18;
    background: white;
    border: 1px solid #B2B2B2;
    border-radius: 6px;
    color: #2D373C;
    font-family: RegularFont;
    font-size: 18;
    margin: 10px 0;
    padding: 15px;
    width: 349px;
`;

const InputQuestionTwoChildren = ({
    path,
    imgSrc,
    title,
    text,
    inputValueTwoChildren,
    setInputValueTwoChildren
}) => {

    let handleChange = (i, e) => {
        let newFormValues = [...inputValueTwoChildren];
        newFormValues[i][e.target.name] = e.target.value;
        setInputValueTwoChildren(newFormValues);
    }

    return (

        <Container>
            <img src={imgSrc} alt={title} width={190}/>
            <Title title={title} />
            <Text>{text}</Text>

            {inputValueTwoChildren.map((element, index) => (
                <div key={`input-${index}`}>
                    <InputStyled
                        type="text"
                        name="name"
                        value={element.name || ""}
                        onChange={e => handleChange(index, e)}
                        placeholder="Insert name"
                    />
                </div>
            ))}

            <Link to={path}>
                <Button text="Next" />
            </Link>
        </Container>
    )
}

export default InputQuestionTwoChildren;