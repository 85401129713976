import React, { useEffect, useState } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../components/Button';
import AreYouMaori from '../components/pepeha/AreYouMaori';
import Image from '../components/Image'
import SectionIntro from '../components/SectionIntro';
import SectionIntroHandlePopUp from '../components/SectionIntroHandlePopUp';
import PopUpCard from '../components/PopUpCard';
import IsMaori from '../components/pepeha/IsMaori'
import IsNotMaori from '../components/pepeha/IsNotMaori'
import BasicAdvanced from '../components/BasicAdvanced';
// import BackgroundImage from '../components/BackgroundImage';

const ContainerAnima = styled.div`
    background-color: #435A36;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: auto;
    background-blend-mode: screen;
    // opacity: 0.5;
    background-image: url(/img/bg-1.png);
    background-position: left;
    background-size: cover;
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 75px 0 25px;
    align-items: center;
    @media only screen and (min-width: 1000px) {
        padding: 125px 0 25px;
    }
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;



const PepehaContainer = styled.div`
    // background-image: url(/img/bg-2.png);
    background-blend-mode: screen;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    // padding-bottom: 2rem;
`;

const BodyContainer = styled.div`
    background-color: #435A36;
    background-image: url(/img/bg-3.png);
    background-blend-mode: screen;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    // position: fixed;
    // top: 0;
    width: 100%;
    // padding: 4rem 0;
    // overflow-y: auto;
`;

const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
    height: 100%;
    width: 560px;
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin: auto auto 0;
    @media only screen and (max-width: 650px) {
        width: 80%;
    }
    @media only screen and (min-width: 850px) {
        width: 40%;
    }

`;

const PopUpContent = ({ setShowPopUp }) => {

    return (
        <div>
            <h1>What is pepeha?</h1>
            <p style={{textAlign:"justify"}}>
                Simply go through each section and choose relevant landmarks that reflect your connection to place.
                If you connect to multiple places, then just go through multiple times, all pepeha will be available
                for you to view on this device, nō reira, toru, whā, KARAWHIUA!
            </p>
            <div onClick={() => setShowPopUp(false)}>
                <Button text="Tēna, got it" />
            </div>
        </div>
    )
}

const Pepeha = () => {
    const [showPopUp, setShowPopUp] = useState(false)
    const [toPrintTheme, setToPrintTheme] = useState(true)
    const [isMaori, setIsMaori] = useState("error")
    // Is Maori and non-Māori
    const [mountain, setMountain] = useState("")
    const [hill, setHill] = useState("")
    const [river, setRiver] = useState("")
    const [lake, setLake] = useState("")
    const [sea, setSea] = useState("")
    const [extra1, setExtra1] = useState("")
    const [extra2, setExtra2] = useState("")
    const [extra3, setExtra3] = useState("")
    const [extra4, setExtra4] = useState("")
    const [extra5, setExtra5] = useState("")
    const [marae, setMarae] = useState("")
    const [canoe, setCanoe] = useState("")
    const [tribe, setTribe] = useState("")
    const [subTribe, setSubTribe] = useState("")
    const [whereFrom, setWhereFrom] = useState("")
    const [whanau, setWhanau] = useState("")
    const [name, setName] = useState("")
    const [whereBorn, setWhereBorn] = useState("")
    const [whereLiving, setWhereLiving] = useState("")
    const [myJob, setMyJob] = useState("")
    const [ancestors, setAncestors] = useState("")

    let { pepehaView } = useParams()

    useEffect(() => {
        Analytics.record({
            name: "Pepeha page visit"
        })
    }, [])

    // if (showPopUp) {
    //     return <PopUpCard
    //         setShowPopUp={setShowPopUp}
    //         cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
    //         withCloseButton={true}
    //     />
    // }

    if (pepehaView) {
        if (pepehaView == "0") {
            if (mountain) setMountain("")
            if (hill) setHill("")
            if (river) setRiver("")
            if (lake) setLake("")
            if (sea) setSea("")
            if (marae) setMarae("")
            if (canoe) setCanoe("")
            if (tribe) setTribe("")
            if (subTribe) setSubTribe("")
            if (whereFrom) setWhereFrom("")
            if (whanau) setWhanau("")
            if (name) setName("")
            if (whereBorn) setWhereBorn("")
            if (whereLiving) setWhereLiving("")
            if (ancestors) setAncestors("")
        }
        return (
            <>
                {showPopUp &&
                    <PopUpCard
                        setShowPopUp={setShowPopUp}
                        cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
                        withCloseButton={true}
                    />
                }
                <ContainerAnima>
                    <Container>
                        <ContentContainer>
                            <PepehaContainer>
                                {/* <BackgroundImage image="/img/home-background.jpg" /> */}
                                {
                                    pepehaView === "0" &&
                                    <>
                                        <IntroContainer>
                                            <Image image="/img/pepeha.jpg" />
                                            < SectionIntro
                                                imgSrc="/svg/pepeha-red.svg"
                                                title="Pepeha"
                                                toPrintTheme={toPrintTheme}
                                                intro={
                                                    <SectionIntroHandlePopUp
                                                        setShowPopUp={setShowPopUp}
                                                        text="Pepeha is a personal statement telling us a bit more about who you are and where you are from. 
                                                        It gives others an opportunity to make a connection with you and build a relationship."
                                                    />
                                                }
                                                btText="Karawhiua -(Let's start)"
                                                path="/pepeha/1/0"
                                            />
                                        </IntroContainer>
                                        <div style={{ width: "100%", height: "20px" }}></div>
                                    </>
                                }
                            </PepehaContainer>
                        </ContentContainer>
                    </Container>
                </ContainerAnima>

                <BodyContainer>

                    {
                        pepehaView === "1" &&
                        <>
                            <div style={{ width: "100%", height: "20px", paddingTop: "60px" }}></div>
                            <AreYouMaori
                                isMaori={isMaori}
                                setIsMaori={setIsMaori}
                            />
                        </>
                    }

                    {
                        pepehaView === "maori" && isMaori === true &&
                        <IsMaori
                            inputValueMountain={mountain}
                            setInputStateMountain={setMountain}
                            inputValueRiver={river}
                            setInputStateRiver={setRiver}
                            inputValueLake={lake}
                            setInputStateLake={setLake}
                            inputValueSea={sea}
                            setInputValueSea={setSea}
                            inputValueMarae={marae}
                            setInputValueMarae={setMarae}
                            inputValueCanoe={canoe}
                            setInputValueCanoe={setCanoe}
                            inputValueTribe={tribe}
                            setInputValueTribe={setTribe}
                            inputValueSubTribe={subTribe}
                            setInputValueSubTribe={setSubTribe}
                            inputValueWhereFrom={whereFrom}
                            setInputValueWhereFrom={setWhereFrom}
                            inputValueWhanau={whanau}
                            setInputValueWhanau={setWhanau}
                            inputValueName={name}
                            setInputValueName={setName}
                            inputValueWhereBorn={whereBorn}
                            setInputValueWhereBorn={setWhereBorn}
                            inputValueWhereLiving={whereLiving}
                            setInputValueWhereLiving={setWhereLiving}
                            inputValueJob={myJob}
                            setInputValueJob={setMyJob}
                        />
                    }

                    {
                        pepehaView === "non-maori" && isMaori === false &&
                        < IsNotMaori
                            inputValueAncestors={ancestors}
                            setInputStateAncestors={setAncestors}
                            inputValueHill={hill}
                            setInputStateHill={setHill}
                            inputValueMountain={mountain}
                            setInputStateMountain={setMountain}
                            inputValueRiver={river}
                            setInputStateRiver={setRiver}
                            inputValueLake={lake}
                            setInputStateLake={setLake}
                            inputValueSea={sea}
                            setInputValueSea={setSea}
                            inputValueExtra1={extra1}
                            setInputValueExtra1={setExtra1}
                            inputValueExtra2={extra2}
                            setInputValueExtra2={setExtra2}
                            inputValueExtra3={extra3}
                            setInputValueExtra3={setExtra3}
                            inputValueExtra4={extra4}
                            setInputValueExtra4={setExtra4}
                            inputValueExtra5={extra5}
                            setInputValueExtra5={setExtra5}
                            inputValueTribe={tribe}
                            setInputValueTribe={setTribe}
                            inputValueWhereFrom={whereFrom}
                            setInputValueWhereFrom={setWhereFrom}
                            inputValueName={name}
                            setInputValueName={setName}
                            inputValueWhereBorn={whereBorn}
                            setInputValueWhereBorn={setWhereBorn}
                            inputValueWhereLiving={whereLiving}
                            setInputValueWhereLiving={setWhereLiving}
                            inputValueWhanau={whanau}
                            setInputValueWhanau={setWhanau}
                            inputValueJob={myJob}
                            setInputValueJob={setMyJob}
                        />
                    }
                </BodyContainer>
            </>
        )
    }

}

export default Pepeha;