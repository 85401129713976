import React from 'react';
import styled from 'styled-components'
import TextToPrint from '../TextToPrint'
import TextToPrintThreeChildren from '../TextToPrintThreeChildren'
import InvertColor from '../InvertColor';

import {
    sMother,
    sFather,
    sPartner,
    sChild,
    sTwoChildren,
    sMaternalGrandFather,
    sMaternalGrandMother,
    sPaternalGrandFather,
    sPaternalGrandMother,
    sGrandChild,
    sTwoGrandChildren,
} from './WhakapapaSentences'
import TextToPrintThreeGrandChildren from '../TextToPrintThreeGrandChildren';
import PlayableMaoriText from '../mihimihi/PlayableMaoriText';

const Container = styled.div`
    margin-top: -50px;
    text-align: center;
    padding: 0 10px 50px 10px;
`;

const TitleStyled = styled.p`
    color: ${props => props.colorTextMaori};
    font-family: MediumFont;
    font-size: 20px;
`;

const Text = styled.p`
    color: ${props => props.colorTextEng};
    margin-top: -18px;
    font-size: 15px;
`;

const IsMaoriPepehaToPrintUi = ({
    mother,
    father,
    partner,
    child,
    twoChildren,
    threeChildren,
    grandChild,
    twoGrandChildren,
    threeGrandChildren,
    maternalGrandFather,
    maternalGrandMother,
    paternalGrandFather,
    paternalGrandMother,
    setToPrintTheme,
    toPrintTheme,
    childrenNumber,
    grandChildrenNumber,
}) => {


    return (
        <Container>
            {
                (maternalGrandFather !== "") && (maternalGrandMother !== "") &&
                <TextToPrint
                    textMaori={`I te taha o taku  māmā, ko ${maternalGrandFather} taku koroua, ko ${maternalGrandMother} taku kuia`}
                    textEng={`On my mother's side, my grandfather is ${maternalGrandFather}, my grandmother is ${maternalGrandMother}`}
                    toPrintTheme={toPrintTheme}
                // textMaori={sMaternalGrandFather(maternalGrandFather)[0]}
                // textEng={sMaternalGrandFather(maternalGrandFather)[1]}
                />
            }
            {/* {
                maternalGrandMother !== "" &&
                <TextToPrint
                    textMaori={sMaternalGrandMother(maternalGrandMother)[0]}
                    textEng={sMaternalGrandMother(maternalGrandMother)[1]}
                    toPrintTheme={toPrintTheme}
                />
            } */}

            {
                (paternalGrandFather !== "") && (paternalGrandMother !== "") &&
                <TextToPrint
                    textMaori={`I te taha o taku pāpā, ko ${paternalGrandFather} taku koroua, ko ${paternalGrandMother} taku kuia`}
                    textEng={`On my father's side, my grandfather is ${paternalGrandFather}, my grandmother is ${paternalGrandMother}`}
                    toPrintTheme={toPrintTheme}
                // textMaori={sPaternalGrandFather(paternalGrandFather)[0]}
                // textEng={sPaternalGrandFather(paternalGrandFather)[1]}
                />
            }
            {/* {
                paternalGrandMother !== "" &&
                <TextToPrint
                    textMaori={sPaternalGrandMother(paternalGrandMother)[0]}
                    textEng={sPaternalGrandMother(paternalGrandMother)[1]}
                    toPrintTheme={toPrintTheme}
                />
            } */}
            {
                mother !== "" &&
                <TextToPrint
                    textMaori={sMother(mother)[0]}
                    textEng={sMother(mother)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                father !== "" &&
                <TextToPrint
                    textMaori={sFather(father)[0]}
                    textEng={sFather(father)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                partner !== "" &&
                <TextToPrint
                    textMaori={sPartner(partner)[0]}
                    textEng={sPartner(partner)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                child !== "" && childrenNumber === "One child" &&
                <TextToPrint
                    textMaori={sChild(child)[0]}
                    textEng={sChild(child)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }

            {
                twoChildren[0].name !== "" && childrenNumber === "Two children" &&
                <>
                    <TextToPrint
                        textMaori={sTwoChildren(twoChildren)[0]}
                        textEng={sTwoChildren(twoChildren)[1]}
                        toPrintTheme={toPrintTheme}
                    />
                    <TitleStyled colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"}>
                        <PlayableMaoriText textMaori="aku tamariki" toPrintTheme={toPrintTheme} />
                    </TitleStyled>
                    <Text colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"}>are my children</Text>
                </>
            }

            {
                threeChildren[0].name !== "" && childrenNumber === "Three or more children" &&
                <TextToPrintThreeChildren
                    inputValueThreeChildren={threeChildren}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                grandChild !== "" && grandChildrenNumber === "One grand child" &&
                <TextToPrint
                    textMaori={sGrandChild(grandChild)[0]}
                    textEng={sGrandChild(grandChild)[1]}
                    toPrintTheme={toPrintTheme}
                />
            }
            {
                twoGrandChildren[0].name !== "" && grandChildrenNumber === "Two grand children" &&
                <>
                    <TextToPrint
                        textMaori={sTwoGrandChildren(twoGrandChildren)[0]}
                        textEng={sTwoGrandChildren(twoGrandChildren)[1]}
                        toPrintTheme={toPrintTheme}
                    />
                    <TitleStyled colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"}>
                        <PlayableMaoriText textMaori="aku mokopuna" toPrintTheme={toPrintTheme} />
                    </TitleStyled>
                    <Text colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"}>are my grand children</Text>
                </>
            }
            {
                threeGrandChildren[0].name !== "" && grandChildrenNumber === "Three or more grand children" &&
                <TextToPrintThreeGrandChildren
                    inputValueThreeGrandChildren={threeGrandChildren}
                    toPrintTheme={toPrintTheme}
                />
            }
            <InvertColor setToPrintTheme={setToPrintTheme} toPrintTheme={toPrintTheme} />
        </Container>
    )
}

export default IsMaoriPepehaToPrintUi;