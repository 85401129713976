import React from 'react';
import styled from 'styled-components'
import TextToPrint from '../TextToPrint'
import InvertColor from '../InvertColor';

import {
    mountain,
    river,
    lake,
    sea,
    marae,
    canoe,
    tribe,
    subTribe,
    iAmFrom,
    family,
    name,
    iWasBorn,
    iLive,
    myJob

} from './IsMaoriPepehaSentences'

const Container = styled.div`
    margin-top: -50px;
    text-align: center;
    padding: 0 10px 50px 10px;
`;

const IsMaoriPepehaToPrintUi = ({
    autoPlayAudio,
    inputValueMountain,
    inputValueRiver,
    inputValueLake,
    inputValueSea,
    inputValueMarae,
    inputValueCanoe,
    inputValueTribe,
    inputValueSubTribe,
    inputValueWhereFrom,
    inputValueWhanau,
    inputValueName,
    inputValueWhereBorn,
    inputValueWhereLiving,
    inputValueJob,
    toPrintTheme,
    setToPrintTheme
}) => {

    return (
        <Container>
            {
                inputValueMountain !== "" &&
                <TextToPrint
                    textMaori={mountain(inputValueMountain)[0]}
                    textEng={mountain(inputValueMountain)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueLake !== "" &&
                <TextToPrint
                    textMaori={lake(inputValueLake)[0]}
                    textEng={lake(inputValueLake)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueRiver !== "" &&
                <TextToPrint
                    textMaori={river(inputValueRiver)[0]}
                    textEng={river(inputValueRiver)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueSea !== "" &&
                <TextToPrint
                    textMaori={sea(inputValueSea)[0]}
                    textEng={sea(inputValueSea)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueMarae !== "" &&
                <TextToPrint
                    textMaori={marae(inputValueMarae)[0]}
                    textEng={marae(inputValueMarae)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueCanoe !== "" &&
                <TextToPrint
                    textMaori={canoe(inputValueCanoe)[0]}
                    textEng={canoe(inputValueCanoe)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueTribe !== "" &&
                <TextToPrint
                    textMaori={tribe(inputValueTribe)[0]}
                    textEng={tribe(inputValueTribe)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueSubTribe !== "" &&
                <TextToPrint
                    textMaori={subTribe(inputValueSubTribe)[0]}
                    textEng={subTribe(inputValueSubTribe)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueWhereFrom !== "" &&
                <TextToPrint
                    textMaori={iAmFrom(inputValueWhereFrom)[0]}
                    textEng={iAmFrom(inputValueWhereFrom)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueWhanau !== "" &&
                <TextToPrint
                    textMaori={family(inputValueWhanau)[0]}
                    textEng={family(inputValueWhanau)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueName !== "" &&
                <TextToPrint
                    textMaori={name(inputValueName)[0]}
                    textEng={name(inputValueName)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueWhereBorn !== "" &&
                <TextToPrint
                    textMaori={iWasBorn(inputValueWhereBorn)[0]}
                    textEng={iWasBorn(inputValueWhereBorn)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueWhereLiving !== "" &&
                <TextToPrint
                    textMaori={iLive(inputValueWhereLiving)[0]}
                    textEng={iLive(inputValueWhereLiving)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            {
                inputValueJob !== "" &&
                <TextToPrint
                    textMaori={myJob(inputValueJob)[0]}
                    textEng={myJob(inputValueJob)[1]}
                    toPrintTheme={toPrintTheme}
                    autoPlayAudio={autoPlayAudio}
                />
            }
            <InvertColor setToPrintTheme={setToPrintTheme} toPrintTheme={toPrintTheme}
            autoPlayAudio={autoPlayAudio} />
        </Container>
    )
}

export default IsMaoriPepehaToPrintUi;