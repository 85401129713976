import React, { useContext } from 'react';
import styled from 'styled-components'
import { MaoriAudioContext } from './MihimihiFlow';
import { playMaoriSound } from '../../utils/play-maori-sound';

const TextMaori = styled.span`
    color: ${props => props.colorTextMaori};
    font-family: MediumFont;
    font-size: 20px;
`;
const TextContainer = styled.div`
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`
const PlayableMaoriText = ({ textMaori, toPrintTheme, autoPlayAudio }) => {
    const { updateAudio, insideTextIsAudioPlaying, setInsideTextIsAudioPlaying } = useContext(MaoriAudioContext);

    const handlePlaybackFinished = () => {
        updateAudio(null);
        setInsideTextIsAudioPlaying(false);
    };

    const handleOnClick = (event) => {
        if (!autoPlayAudio && !insideTextIsAudioPlaying) {
            // const selectedText = window.getSelection().toString().trim();
            const maoriText = event.target.textContent.trim();
            playMaoriSound(maoriText.toLowerCase(), handlePlaybackFinished, setInsideTextIsAudioPlaying, updateAudio)
        }
    };

    return (
        // <TextMaori colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"} onClick={handleOnClick} >{textMaori}</TextMaori>
        <TextContainer>
            {
                textMaori.split(" ").filter(Boolean).map((word, index) => (
                    <React.Fragment key={index}>
                        <TextMaori colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"} onClick={handleOnClick} >
                            {word}
                        </TextMaori>
                        &nbsp;
                    </React.Fragment>
                ))
            }
        </TextContainer>
    )
}

export default PlayableMaoriText;