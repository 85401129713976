import React from 'react';
import styled from 'styled-components'
import PlayableMaoriText from './mihimihi/PlayableMaoriText';

const TitleStyled = styled.div`
    color: ${props => props.colorTextMaori};
    font-family: MediumFont;
    font-size: 20px;
`;

const Text = styled.p`
    color: ${props => props.colorTextEng};
    margin-top: -18px;
    font-size: 15px;
`;


const TextToPrintThreeChildren = ({ inputValueThreeChildren, toPrintTheme }) => {

    return (
        <>
            <TitleStyled colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"}>
                <PlayableMaoriText toPrintTheme={toPrintTheme} textMaori={`Ko ${inputValueThreeChildren[0].name || "..."} rātou`} />
            </TitleStyled>
            <Text colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"}>
                {` ${inputValueThreeChildren[0].name || "..."} and`}
            </Text>
            {inputValueThreeChildren.map((element, index) => {
                if (index > 0) return (
                    <div key={`text-styled-${index}`}>
                        <TitleStyled colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"}>
                            <PlayableMaoriText toPrintTheme={toPrintTheme} textMaori={`Ko ${element.name || "..."}`} />
                        </TitleStyled>
                        <Text colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"}>
                            {`${element.name || "..."}`}
                        </Text>
                    </div>
                )


            })}
            <TitleStyled colorTextMaori={toPrintTheme ? "#2F373B" : "#FFFFFF"}>
                <PlayableMaoriText toPrintTheme={toPrintTheme} textMaori="aku tamariki" />
            </TitleStyled>
            <Text colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"}>
                are my children
            </Text>
        </>
    )
}

export default TextToPrintThreeChildren;