import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getMaoriVoices } from "../../utils/maori-voices";

const StyledTable = styled.table`
  @media only screen and (max-width: 650px) {
    width: 80%;
  }
  width: 100%;

  caption-side: top;
  border: none;
  // border-collapse: collapse;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;

  td,
  th {
    border: none;
  }
  /* td,
  th {
    border: 1px solid;
  } */

  td {
    padding: 30px 10px;
    border: 3px solid #FFFFFF;
    border-color: #FFFFFF;
    color: #174221;
    font-weight: bold;
  }
  td p {
    cursor: pointer;
    width: 20px;
    margin: 0 5px;
  }
  td img {
    width: 17px
  }
  tbody tr {
    background: var(--unnamed-color-deb22f) 0% 0% no-repeat padding-box;
    // border: 3px solid var(--unnamed-color-ffffff);
    border: 3px solid #FFFFFF;
    opacity: 1;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  tr > td:first-child  {
    display:flex;
    justify-content: center;
    align-items : center;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
  
`;

const TextMaori = styled.span`
    color: ${props => props.colorTextMaori};
    font-family: MediumFont;
    font-size: 14px;
    display: inline-block;
    height: auto;
    width: auto;
    // margin: 20px auto;
`;



const TableMarkup = ({ data, clickHandler }) => {
  const [playing, setPlaying] = useState(false);
  const [maoriText, setMaoriText] = useState(null);

  const toggleClick = (text) => {
    setPlaying(!playing);
    setMaoriText(text);
  };

  useEffect(() => {
    const audio = new Audio();
  
    if (maoriText) {
      const maoriVoice = getMaoriVoices(maoriText);
      if (maoriVoice && playing) {
        audio.src = maoriVoice;
        audio.play();
      }
    } else {
      speechSynthesis.cancel();
      audio.pause();
      audio.src = '';
    }
  
    audio.addEventListener("ended", () => {
      setPlaying(false);
      setMaoriText(null)
    });
  
    return () => {
      if (audio) {
        audio.removeEventListener("ended", () => {
          setPlaying(false);
          setMaoriText(null);
        });
      }
    };
  }, [playing, maoriText]);

  return (
    <StyledTable>
      <colgroup>
        <col />
        <col />
      </colgroup>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>
              {item.Capital.split(" ").filter(Boolean).map((word, index) => (
                <React.Fragment key={index}>
                  <TextMaori onClick={clickHandler}>{word}</TextMaori>&nbsp;
                </React.Fragment>
              ))}
              <p onClick={() => toggleClick(item.Capital)}>
                <img
                  src={maoriText === item.Capital && playing ? "/svg/sound-glossary-pause.svg" : "/svg/sound-glossary.svg"}
                  alt="sound icon"
                />
              </p>
            </td>
            <td>{item.Currency}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default TableMarkup;