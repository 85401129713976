import React, { useEffect, useState, useContext } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../components/Button';
import Image from '../components/Image'
import SectionIntro from '../components/SectionIntro';
import SectionIntroHandlePopUp from '../components/SectionIntroHandlePopUp';
import PopUpCard from '../components/PopUpCard';
import WhakapapaFlow from '../components/whakapapa/WhakapapaFlow'

const WhakapapaContainerBack = styled.div`
    background-color: #435A36;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: scroll;
    background-image: url(/img/bg-1.png);
    background-blend-mode: screen;
    background-position: left;
    background-size: cover;
`;
const BodyContainer = styled.div`
    background-color: #435A36;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-image: url(/img/bg-3.png);
    background-blend-mode: screen;
    // opacity: 0.5;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
`

const WhakapapaContainer = styled.div`
    margin-top: 55px;
`;

const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
    height: 100%;
    width: 560px;
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin: auto auto 30px;
    @media only screen and (max-width: 650px) {
        width: 80%;
    }
    @media only screen and (min-width: 850px) {
        width: 40%;
    }

`;

const PopUpContent = ({ setShowPopUp }) => {


    return (
        <div>
            <h1>What is whakapapa?</h1>
            <p style={{textAlign:"justify"}}>
                Whakapapa is used to represent whānau relationships, who your whānau is and how you are all related. Simply select the sections that are relevant to you and your whānau.
                When you're finished your whakapapa will be available for you to view on this device, nō reira, toru, whā, KARAWHIUA!'
            </p>
            <div onClick={() => setShowPopUp(false)}>
                <Button text="Tēna, got it" />
            </div>
        </div>
    )
}

const Whakapapa = () => {
    const [showPopUp, setShowPopUp] = useState(false)
    const [toPrintTheme, setToPrintTheme] = useState(true)
    const [mother, setMother] = useState("")
    const [father, setFather] = useState("")
    const [partner, setPartner] = useState("")
    const [child, setChild] = useState("")
    const [grandChild, setGrandChild] = useState("")

    const [twoChildren, setTwoChildren] = useState([{ name: "" }, { name: "" }])
    const [twoGrandChildren, setTwoGrandChildren] = useState([{ name: "" }, { name: "" }])
    const [threeChildren, setThreeChildren] = useState([{ name: "" }, { name: "" }, { name: "" }])
    const [threeGrandChildren, setThreeGrandChildren] = useState([{ name: "" }, { name: "" }, { name: "" }])

    const [maternalGrandFather, setMaternalGrandFather] = useState("")
    const [maternalGrandMother, setMaternalGrandMother] = useState("")
    const [paternalGrandFather, setPaternalGrandFather] = useState("")
    const [paternalGrandMother, setPaternalGrandMother] = useState("")

    const [childrenNumber, setChildrenNumber] = useState("init")
    const [grandChildrenNumber, setGrandChildrenNumber] = useState("init")

    useEffect(() => {
        Analytics.record({
            name: "Whakapapa page visit"
        })
    }, [])

    let { whakapapaView } = useParams()

    // if (showPopUp) {
    //     return <PopUpCard
    //         setShowPopUp={setShowPopUp}
    //         cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
    //         withCloseButton={true}
    //     />
    // }

    if (whakapapaView) {
        if (whakapapaView == "0") {
            if (mother) setMother("")
            if (father) setFather("")
            if (partner) setPartner("")
            if (child) setChild("")
            if (maternalGrandFather) setMaternalGrandMother("")
            if (maternalGrandMother) setMaternalGrandMother("")
            if (paternalGrandFather) setPaternalGrandFather("")
            if (paternalGrandMother) setPaternalGrandMother("")
        }
        return (
            <>
                {showPopUp &&
                    <PopUpCard
                        setShowPopUp={setShowPopUp}
                        cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
                        withCloseButton={true}
                    />
                }
                <WhakapapaContainerBack>
                    <WhakapapaContainer>
                        {
                            whakapapaView === "0" &&
                            <IntroContainer>
                                <Image image="/img/whakapapa.jpg" />
                                <SectionIntro
                                    imgSrc="/imagesss/All SVG/Whakapapa.svg"
                                    title="Your whakapapa"
                                    toPrintTheme={toPrintTheme}
                                    intro={
                                        <SectionIntroHandlePopUp
                                            setShowPopUp={setShowPopUp}
                                            text="Your whakapapa allows you to share who your whānau members are.
                                    Like pepeha, it gives others an opportunity to connect with you,
                                    to appreciate who you are and the special people in your life."
                                        />
                                    }
                                    btText="Karawhiua - Let's start"
                                    path="/whakapapa/1/1"
                                />
                            </IntroContainer>
                        }
                    </WhakapapaContainer>
                </WhakapapaContainerBack>


                {
                    whakapapaView === "1" &&

                    <BodyContainer>

                        <WhakapapaFlow
                            inputValueMother={mother}
                            setInputStateMother={setMother}
                            inputValueFather={father}
                            setInputStateFather={setFather}
                            inputValuePartner={partner}
                            setInputStatePartner={setPartner}



                            inputValueChild={child}
                            setInputValueChild={setChild}
                            inputValueGrandChild={grandChild}
                            setInputValueGrandChild={setGrandChild}

                            inputValueTwoChildren={twoChildren}
                            setInputValueTwoChildren={setTwoChildren}
                            inputValueTwoGrandChildren={twoGrandChildren}
                            setInputValueTwoGrandChildren={setTwoGrandChildren}

                            inputValueThreeChildren={threeChildren}
                            setInputValueThreeChildren={setThreeChildren}
                            inputValueThreeGrandChildren={threeGrandChildren}
                            setInputValueThreeGrandChildren={setThreeGrandChildren}

                            inputValueMaternalGrandFather={maternalGrandFather}
                            setInputValueMaternalGrandFather={setMaternalGrandFather}

                            inputValueMaternalGrandMother={maternalGrandMother}
                            setInputValueMaternalGrandMother={setMaternalGrandMother}

                            inputValuePaternalGrandFather={paternalGrandFather}
                            setInputValuePaternalGrandFather={setPaternalGrandFather}

                            inputValuePaternalGrandMother={paternalGrandMother}
                            setInputValuePaternalGrandMother={setPaternalGrandMother}

                            childrenNumber={childrenNumber}
                            setChildrenNumber={setChildrenNumber}

                            grandChildrenNumber={grandChildrenNumber}
                            setGrandChildrenNumber={setGrandChildrenNumber}
                        />
                    </BodyContainer>

                }
            </>
        )
    }

}

export default Whakapapa;