import React from 'react';
import styled from 'styled-components'
import PlayableMaoriText from './mihimihi/PlayableMaoriText';

const TextEng = styled.p`
    color: ${props => props.colorTextEng};
    margin-top: -18px;
    font-size: 15px;
    width: 90%;
    margin: 0 auto;

`;

const TextToPrint = ({ textMaori, textEng, toPrintTheme, autoPlayAudio }) => {
    return (
        <>
            <PlayableMaoriText autoPlayAudio={autoPlayAudio} toPrintTheme={toPrintTheme} textMaori={textMaori} />
            <TextEng colorTextEng={toPrintTheme ? "#2F373B" : "#FFFFFF"} >
                {textEng}
            </TextEng>
        </>
    )
}

export default TextToPrint;