import React from 'react';
import styled from 'styled-components'

const SubTitleStyled = styled.p`
    margin-top: -20px;
    color: #AE0F0B;
`;


const SubTitle = ({ subtitle }) => {
    return <SubTitleStyled>{subtitle}</SubTitleStyled>
}

export default SubTitle;