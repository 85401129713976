import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from './Title'
import Button from './Button';
import ErrorMessage from './ErrorMessage';

const Container = styled.div`
    width: 380px;
    margin: 0 20px;
    padding: 35px 50px;
    text-align: center;

    border-radius: 10px;  
    opacity: 1;
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    @media only screen and (min-width: 768px) {
        padding: 50px 135px;
    }
`;

const Text = styled.p`
    font-size: 12px;
    margin: -25px 0 30px 0;

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 20px;
    }
`;

const STICKYDIV = styled.div`
    padding: 40px 10px;
    text-align: center;
    width: 349px;
    height: 80px;
    // position: sticky;
    top: 0;
    // width: 65%;
`


const ButtonQuestion = ({ title, text, path, buttons, state }) => {
    const [showError, setShowError] = useState(false)
    if (buttons.props.inputValue == []){
        state = "error"
    }
    return (

        <Container>
            <STICKYDIV>
            <Title title={title} />
            <Text>{text}</Text>
            </STICKYDIV>
            {
                    state === "error" ?
                        <div onClick={() => setShowError(true)}>
                            <Button text="Next" />
                        </div>
                        :
                        <Link to={path}>
                            <Button text="Next" />
                        </Link>
            }
            {buttons}
            {showError && <ErrorMessage text="Please select an option above" />}
            {
                    state === "error" ?
                        <div onClick={() => setShowError(true)}>
                            <Button text="Next" />
                        </div>
                        :
                        <Link to={path}>
                            <Button text="Next" />
                        </Link>
            }
        </Container>

    )
}

export default ButtonQuestion;