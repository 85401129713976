import React from 'react'
import "./Practice.css"
import PracticeItem from '../../components/PracticeItem/PracticeItem'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import PracticeQuestionPage from '../../components/PracticeQuestionPage/PracticeQuestionPage'
import { karakiaData, keteKoreroData, kimikimikimiData, kupuTakaData, mihiData, pepehaData, waiataData, whakapapaData } from '../../components/PracticeQuestionPage/DataOptions/data'


const Practice = () => {
    
    const { practiceView } = useParams()
    
    let id = 0
    const practiceItems = [
        {
            id: id++,
            engTitle: 'Blessings',
            maoriTitle: 'Karakia',
            icon: '/imagesss/All SVG/Karakia (Blessings).svg',
            path: 'karakia',
            data: karakiaData,
        },
        {
            id: id++,
            engTitle: 'Provenance',
            maoriTitle: 'Pepeha',
            icon: '/imagesss/All SVG/Pepeha (Provenance).svg',
            path: 'pepeha',
            data: pepehaData,
        },
        {
            id: id++,
            engTitle: 'Greetings',
            maoriTitle: 'Mihi',
            icon: '/imagesss/All SVG/Mihi (Greetings).svg',
            path: 'mihi',
            data: mihiData,
        },
        {
            id: id++,
            engTitle: 'Songs',
            maoriTitle: 'Waiata',
            icon: '/imagesss/All SVG/Waiata (Songs).svg',
            path: 'waiata',
            data: waiataData,
        },
        {
            id: id++,
            engTitle: 'Genealogy',
            maoriTitle: 'Whakapapa',
            icon: '/imagesss/All SVG/Whakapapa.svg',
            path: 'whakapapa',
            data: whakapapaData,
        },
        {
            id: id++,
            engTitle: 'Tool kit',
            maoriTitle: 'Kete Kōrero',
            icon: '/imagesss/All SVG/Kete Korero (Tool Kit).svg',
            path: 'kete-korero',
            data: keteKoreroData,
        },
        {
            id: id++,
            engTitle: 'Glossary',
            maoriTitle: 'Kupu taka',
            icon: '/imagesss/All SVG/Kupu taka (Glossary).svg',
            path: 'kupu-taka',
            data: kupuTakaData,
        },
        {
            id: id++,
            engTitle: 'Practice all',
            maoriTitle: 'kimikimikimi',
            icon: '/imagesss/All SVG/Kete Korero (Tool Kit).svg',
            path: 'practice-all',
            data: kimikimikimiData,
        },
    ]

    return (
        <div className='practice__parrent' style={{ backgroundImage: "url(/img/bg-1.png)" }}>
            <div className="practice__container">
                {
                    practiceView === "0" &&
                    <div className="practice__content">
                        <div className="practice__header">
                            <div className="practice__header-logo">
                                <img src="/imagesss/All SVG/practice.svg" alt="practice icon×" />
                            </div>
                            <h2 className="practice__header-title">Choose Your Course</h2>
                        </div>
                        <div className="practice__main">
                            <div className="practice__main-row">
                                {practiceItems.map(item => (
                                    <PracticeItem
                                        key={item.id}
                                        path={`${item.path}/1`}
                                        engTitle={item.engTitle}
                                        maoriTitle={item.maoriTitle}
                                        icon={item.icon} />
                                ))}
                            </div>
                        </div>
                    </div>
                }
                {
                    practiceItems.map(practiceItem => (
                        practiceView === practiceItem.path &&
                        <PracticeQuestionPage
                            key={practiceItem.id}
                            data={practiceItem.data}
                            title={practiceItem.maoriTitle}
                        />
                    ))

                }
            </div>
        </div>
    )
}

export default Practice