export const data = [
  {
    Capital: "Ka kite",
    Currency: "See you later",
  },
  {
    Capital: "Haere rā",
    Currency: "Farewell (to someone leaving)",
  },
  {
    Capital: "E noho rā",
    Currency: "Farewell (to someone staying)",
  },
  {
    Capital: "Hei konei rā",
    Currency: "Farewell",
  },
  {
    Capital: "Pō mārie",
    Currency: "Good night",
  },
  {
    Capital: "Kia pai te rā",
    Currency: "Have a good day",
  },
  {
    Capital: "Hei te wā",
    Currency: "Till next time",
  },
  {
    Capital: "Ā tōna wā",
    Currency: "Till next time",
  }
];
