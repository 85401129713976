import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components'
import TextToPrint from '../TextToPrint'
import InvertColor from '../InvertColor';
import { MaoriAudioContext } from './MihimihiFlow';
import { playMaoriSound } from '../../utils/play-maori-sound';
import stopAudio from '../../utils/stop-audio';

const Container = styled.div`
    margin-top: -50px;
    text-align: center;
    padding: 0 10px 50px 10px;
`;

const IsMaoriPepehaToPrintUi = ({
    autoPlayAudio,
    setAutoPlayAudio,
    greetingsTo = "",
    greetingsToCont = "",
    i = "",
    iamFeeling = "",
    personalPronoun = "",
    therefore = "",
    to = "",
    toB = "",
    whoHave = "",
    saying = "",
    great = "",
    mo = "",
    kite = "",
    these = "",
    concerning = "",
    setToPrintTheme = "",
    toPrintTheme = ""
}) => {
    const { audio, updateAudio, isAutoPlayAudioPlaying, setIsAutoPlayAudioPlaying } = useContext(MaoriAudioContext);
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(-1);
    
    const handlePlaybackFinished = () => {
        setIsAutoPlayAudioPlaying(false);
        setCurrentPlayingIndex(currentIndex => currentIndex + 1);
    }

    const texts = {
        greetingsTo: {
            var: greetingsTo,
            maori: `Tēnā ${greetingsTo[0]}`,
            eng: `Greetings to ${greetingsTo[1]}`,
        },
        greetingsToCont: {
            var: greetingsToCont,
            maori: greetingsToCont[0],
            eng: greetingsToCont[1],
        },
        iamFeeling: {
            var: iamFeeling,
            maori: `Kei te ${iamFeeling[0]} ahau`,
            eng: `I am feeling ${iamFeeling[1]}`,
        },
        whoHave: {
            var: whoHave,
            maori:`kua ${whoHave[0]}`,
            eng: `who have ${whoHave[1]}`,
        },
        to: {
            var: to,
            maori:`ki ${to[0] ? to[0] : ""}`,
            eng:`to ${to[1] ? to[1] : ""}`
        },
        toB: {
            var: toB,
            maori: `ki te ${toB[0]} ${personalPronoun[0] ? personalPronoun[0] : ""}`,
            eng: `to ${toB[1]} ${personalPronoun[1] ? personalPronoun[1] : ""}`,
        },
        i: {
            var: i,
            maori: `i ${i[0]}`,
            eng: i[1],
        },
        saying: {
            var: saying,
            maori:`E kiia nei te kōrero`,
            eng: `The saying goes`,
        },
        saying1: {
            var: saying,
            maori: saying[0],
            eng: saying[1],
        },
        great: {
            var: great,
            maori: `Nā konā, kei a ${great[0]}`,
            eng: `As such ${great[1]} have great`,
        },
        mo: {
            var: mo,
            maori: `mō te ${mo[0]}`,
            eng: ` ${mo[1]}`,
        },
        kite: {
            var: kite,
            maori: `Ki te ${kite[0]}`,
            eng: `to ${kite[1]}`,
        },
        these: {
            var: these,
            maori: `i ${these[0]}`,
            eng: `this/these ${these[1]}`,
        },
        concerning: {
            var: concerning,
            maori: `e pā ana ki ${concerning[0]}`,
            eng: `concerning ${concerning[1]}`,
        },
        therefore: {
            var: therefore,
            maori:`nō reira ${therefore[0]}`,
            eng: `therefore ${therefore[1]}`,
        },
        greetingsTo1: {
            var: greetingsTo,
            maori: `Tēnā ${greetingsTo[0]}`,
            eng: `Greetings to ${greetingsTo[1]}`,
        },
    }

    useEffect(() => {
        if (currentPlayingIndex > -1 && autoPlayAudio) {

            //  create aarrays from texts object
            const textsArray = Object.values(texts);
            if (textsArray.length <= currentPlayingIndex) {
                setAutoPlayAudio(false);
            } else {
                if (!isAutoPlayAudioPlaying) {
                    if (textsArray[currentPlayingIndex].var) {
                        playMaoriSound(textsArray[currentPlayingIndex].maori.trim(), handlePlaybackFinished, setIsAutoPlayAudioPlaying, updateAudio)
                    } else {
                        handlePlaybackFinished()
                    }
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlayingIndex]);

    useEffect(() => {
        if (autoPlayAudio && currentPlayingIndex === -1) {
            setCurrentPlayingIndex(0);
        } else if (!autoPlayAudio) {
            setCurrentPlayingIndex(-1);
            if (audio) {
                stopAudio(audio);
                setIsAutoPlayAudioPlaying(false);
                updateAudio(null)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoPlayAudio]);

    return (
        <Container>
            {
                Object.values(texts).map((text, index) => text.var && 
                (
                    <TextToPrint
                            key={index}
                            textMaori={text.maori}
                            textEng={text.eng}
                            toPrintTheme={toPrintTheme}
                            autoPlayAudio={autoPlayAudio}
                        />
                    )
                )
            }
            <InvertColor setToPrintTheme={setToPrintTheme} toPrintTheme={toPrintTheme} />
        </Container>
    )
}

export default IsMaoriPepehaToPrintUi;