import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from '../Title'
import Button from '../Button';
import ButtonOption from '../ButtonOption';
import ErrorMessage from '../ErrorMessage';

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    text-align: center;
    width: 380px;
    padding: 35px 50px;

    border-radius: 10px;  
    opacity: 1;
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    @media only screen and (min-width: 768px) {
        padding: 50px 135px;
    }
`;

const GrandChildrenNumber = ({ state, setState }) => {
    const [showError, setShowError] = useState(false)
    const [view, setView] = useState("")
    useEffect(() => {
        if (view == "") {
            if (state == "None") {
                setView("14")
            } else if (state == "One grand child") {
                setView("~")
            } else if (state == "Two grand children") {
                setView("12")
            } else if (state == "Three or more grand children") {
                setView("13")
            }
        }
    })
    return (
        <ContainerOut>
            <Container>
                <img src="/imagesss/All SVG/Child.svg" alt="grand Children" width={190} />
                <Title title="How many grand children to you have?" />
                <div onClick={() => setView("14")}>
                    <ButtonOption
                        text="None"
                        setBtState={setState}
                        btState="None"
                        active={state === "None"}
                    />
                </div>

                <div onClick={() => setView("11")}>
                    <ButtonOption
                        text="One grand child"
                        setBtState={setState}
                        btState="One grand child"
                        active={state === "One grand child"}
                    />
                </div>

                <div onClick={() => setView("12")}>
                    <ButtonOption
                        text="Two grand children"
                        setBtState={setState}
                        btState="Two grand children"
                        active={state === "Two grand children"}
                    />
                </div>

                <div onClick={() => setView("13")}>
                    <ButtonOption
                        text="Three or more children"
                        setBtState={setState}
                        btState="Three or more grand children"
                        active={state === "Three or more grand children"}
                    />
                </div>

                {showError && <ErrorMessage text="Please select an option above" />}

                {
                    view === "" ?
                        <div onClick={() => setShowError(true)}>
                            <Button text="Next" />
                        </div>
                        :
                        <Link to={`/whakapapa/1/${view}`}>
                            <Button text="Next" />
                        </Link>
                }
            </Container>
        </ContainerOut>
    )
}

export default GrandChildrenNumber;