import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const Kite = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='āwhina'
                engText="support"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "āwhina" ? true : false}
            />
            <ButtonChoice
                maoriText="hāpai"
                engText="uplift"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "hāpai" ? true : false}
            />
            <ButtonChoice
                maoriText="tautoko"
                engText="support"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "tautoko" ? true : false}
            />
            <ButtonChoice
                maoriText="whakamārama"
                engText="explain"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "whakamārama" ? true : false}
            />
            <ButtonChoice
                maoriText="whakatikatika"
                engText="solve"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "whakatikatika" ? true : false}
            />
            <ButtonChoice
                maoriText="kōrerorero"
                engText="discuss"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === "kōrerorero" ? true : false}
            />
        </ButtonContainer>
    )
}

export default Kite;