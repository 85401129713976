export const data = [
    {
      Capital: "Ka mau te wehi",
      Currency: "Wicked",
    },
    {
      Capital: "Koia kei a koe",
      Currency: "You’re awesome",
    },
    {
      Capital: "Hei aha tāu",
      Currency: "Never mind yours",
    },
    {
      Capital: "Aua hoki",
        Currency: "I don’t know",
      },
      {
        Capital: "Kei wīwī kei wāwā",
        Currency: "Here, there, everywhere",
      },
      {
        Capital: "Nē?",
        Currency: "Really?",
      },
      {
        Capital: "Nē",
        Currency: "Aye",
      },
      {
        Capital: "Nā whai anō",
        Currency: "No wonder",
      },
      {
        Capital: "Tōnā tikanga",
        Currency: "Supposedly",
      },
      {
        Capital: "E taea ana te aha",
        Currency: "What can one do",
      },
      {
        Capital: "Hoatu",
        Currency: "You go",
      },
      {
        Capital: "Hoake",
        Currency: "Let’s go",
      },
      {
        Capital: "Nā wai rā, nā wai rā",
        Currency: "As a result",
      },
      {
        Capital: "Ka mutu",
        Currency: "Furthermore",
      },
      {
        Capital: "Kei tūpono",
        Currency: "Just in case",
      }
  ];
  