import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Image from '../components/Image'
import KarakiaButtons from '../components/Karakia/KarakiaButtons';
import KarakiaView from '../components/Karakia/KarakiaView';
import LyricOne from '../components/Karakia/LyricOne';
import LyricTwo from '../components/Karakia/LyricTwo';
import LyricThree from '../components/Karakia/LyricThree';
import LyricFour from '../components/Karakia/LyricFour';
import LyricFive from '../components/Karakia/LyricFive';
import LyricSix from '../components/Karakia/LyricSix';
import LyricSeven from '../components/Karakia/LyricSeven';
import LyricEight from '../components/Karakia/LyricEight';
import Title from '../components/Title'
import SectionIntroHandlePopUp from '../components/SectionIntroHandlePopUp';

const ContainerAnima = styled.div`
    background-color: #435A36;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: scroll;
    background-blend-mode: screen;
    // opacity: 0.5;
    background-image: url(/img/bg-1.png);
    background-position: left;
    background-size: cover;
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 75px 0 25px;
    align-items: center;
    @media only screen and (min-width: 1000px) {
        padding: 125px 0 25px;
    }
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const WhakapapaContainer = styled.div`
    margin-top: 55px;
    padding-bottom: 50px;
    // background-image: url(/img/bg-4.png);
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
`;

// const IntroContainer = styled.div`
//     text-align: center;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// `;
const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
    height: 100%;
    width: 560px;
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    padding-bottom: 2rem;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin: auto auto 30px;
    @media only screen and (max-width: 650px) {
        width: 100%;
    }
    @media only screen and (min-width: 850px) {
        width: 50%;
    }

`;
const SectionIntroHandlePopUpContainer = styled.div`
    margin: -15px auto 32px auto;
    width: 400px;
    font-size: 19px;
    @media only screen and (max-width: 640px) {
        width: 80%;
    }
`

const SubTitle = styled.p`
    margin-top: -30px;
`;

const Content = styled.div`
    margin-top: 30px;
`;

const URL = "https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/waiata/"

const Karakia = () => {
    let { view } = useParams()

    useEffect(() => {
        Analytics.record({
            name: "Karakia page visit"
        })
    }, [])

    if (view) {
        return (
            <ContainerAnima>
                <Container>
                    <ContentContainer>
                        <WhakapapaContainer>
                            {
                                view === "0" &&
                                <IntroContainer>
                                    <Image image="/img/karakia.jpg" />
                                    <Content>
                                        <img src="/imagesss/All SVG/Karakia (Blessings).svg" alt="karakia icon" style={{ width: "190px", marginTop: "-2rem" }} />
                                        <Title title="Karakia" />
                                        {/* <SubTitle>Listen and learn Karakia (prayers) </SubTitle> */}
                                        <SectionIntroHandlePopUpContainer >
                                            <SectionIntroHandlePopUp
                                                text="Karakia is a prayer or chant often recited to open a gathering or occasion. As a metaphysical moment of divine kinship, it's purpose is to spiritually bring people together under a veil of unity and protection. It is an honour to lead karakia and carries much responsibility."
                                            />
                                        </SectionIntroHandlePopUpContainer>
                                        <KarakiaButtons />
                                    </Content>
                                </IntroContainer>
                            }

                            {
                                view === "1" &&
                                <KarakiaView
                                    title="Tuia"
                                    // subTitle="Karakia - For beginning and Closing"
                                    song={`${URL}tuia.mp3`}
                                    lyric={<LyricOne />}
                                />
                            }

                            {
                                view === "2" &&
                                <KarakiaView
                                    title="Ūhia mai rā"
                                    // subTitle="Karakia - For beginning and Closing"
                                    song={`${URL}uhia.mp3`}
                                    lyric={<LyricTwo />}
                                />
                            }


                            {
                                view === "3" &&
                                <KarakiaView
                                    title="Nau mai (kai)"
                                    // subTitle="Before food"
                                    song={`${URL}nau_mai.mp3`}
                                    lyric={<LyricThree />}
                                />
                            }

                            {
                                view === "4" &&
                                <KarakiaView
                                    title="Kia tau iho"
                                    // subTitle="Karakia - For beginning and Closing"
                                    song={`${URL}kia_tau_iho.mp3`}
                                    lyric={<LyricFour />}
                                />
                            }

                            {
                                view === "5" &&
                                <KarakiaView
                                    title="Mauri oho"
                                    // subTitle="Karakia timatanga - For beginning, starting, opening"
                                    song={`${URL}mauri_oho.mp3`}
                                    lyric={<LyricFive />}
                                />
                            }

                            {
                                view === "6" &&
                                <KarakiaView
                                    title="Whakataka te Hau"
                                    // subTitle="Karakia timatanga - For beginning, starting, opening"
                                    song={`${URL}whakataka_te_hau.mp3`}
                                    lyric={<LyricSix />}
                                />
                            }

                            {
                                view === "7" &&
                                <KarakiaView
                                    title="Kia tau"
                                    // subTitle="Karakia whakamutunga - For closing"
                                    song={`${URL}kia_tau_ki_a_tatou_katoa.mp3`}
                                    lyric={<LyricSeven />}
                                />
                            }

                            {
                                view === "8" &&
                                <KarakiaView
                                    title="Unuhia"
                                    subTitle="Karakia whakamutunga - For closing"
                                    song={`${URL}unuhia.mp3`}
                                    lyric={<LyricEight />}
                                />
                            }
                        </WhakapapaContainer>
                    </ContentContainer>
                </Container>
            </ContainerAnima>
        )
    }

}

export default Karakia;