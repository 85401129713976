import React, { useEffect, useState } from 'react';
import { Analytics } from 'aws-amplify'
import styled from 'styled-components'
import AbcMenu from '../components/Glossary/AbcMenu';
import SubTitle from '../components/SubTitle'
import A from '../components/Glossary/A'
import E from '../components/Glossary/E'
import H from '../components/Glossary/H'
import I from '../components/Glossary/I'
import K from '../components/Glossary/K'
import M from '../components/Glossary/M'
import N from '../components/Glossary/N'
import O from '../components/Glossary/O'
import P from '../components/Glossary/P'
import R from '../components/Glossary/R'
import T from '../components/Glossary/T'
import W from '../components/Glossary/W'

const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
    height: 100%;
    width: 560px;
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin: auto auto 0;
    @media only screen and (max-width: 650px) {
        width: 80%;
    }
    @media only screen and (min-width: 850px) {
        width: 40%;
    }

`;

const ContentOutContainer = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ContentInContainer = styled.div`
    text-align: center;
`;

const Title = styled.h1`
    margin-top: 50px;
    font-size: 26px;

    @media only screen and (min-width: 768px) {
        font-size: 36px;
    }
`;

const TextContainer = styled.div`
    // margin: 20px 20px 80px 20px;

    @media only screen and (min-width: 768px) {
        width: 100%;
    }
`;

const Glossary = () => {
    const [active, setActive] = useState("a")

    useEffect(() => {
        Analytics.record({
            name: "Glossary page visit"
        })
    }, [])

    return (
        <IntroContainer>
            <ContentOutContainer>
                <ContentInContainer>
                    <Title>Kupu taka</Title>
                    <SubTitle subtitle="Glossary" />
                    <TextContainer>
                        <AbcMenu active={active} setActive={setActive}  />
                        {active === "a" && <A active={active} />}
                        {active === "e" && <E active={active} />}
                        {active === "h" && <H active={active} />}
                        {active === "i" && <I active={active} />}
                        {active === "k" && <K active={active} />}
                        {active === "m" && <M active={active} />}
                        {active === "n" && <N active={active} />}
                        {active === "o" && <O active={active} />}
                        {active === "p" && <P active={active} />}
                        {active === "r" && <R active={active} />}
                        {active === "t" && <T active={active} />}
                        {active === "w" && <W active={active} />}
                    </TextContainer>
                </ContentInContainer>
            </ContentOutContainer >
        </IntroContainer >
    )
}

export default Glossary;