import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import styled from 'styled-components'
import Title from '../components/Title'
import SubTitle from '../components/SubTitle'
import Image from '../components/Image'


const ContainerAnima = styled.div`
    background-color: #435A36;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: scroll;
    background-blend-mode: screen;
    // opacity: 0.5;
    background-image: url(/img/bg-1.png);
    background-position: left;
    background-size: cover;
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 75px 0 25px;
    align-items: center;
    @media only screen and (min-width: 1000px) {
        padding: 125px 0 25px;
    }
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;
const ContentOutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ContentInContainer = styled.div`
    margin-top: 40px;
    text-align: center;
`;


const TextContainer = styled.div`
    margin: 20px 20px 80px 20px;

    @media only screen and (min-width: 650px) {
        width: 520px;
    }
`;

const CustomLink = styled.a`
    color: blue;
    text-decoration: underline;
    display: inline-block;
    margin: 0 0.2rem;
`;

const TextValues = styled.p`
    font-size: 12px;
    text-align: justify;
    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }
`;
const TextBold = styled.p`
    font-weight: bold;
`;

const AboutPageBox = styled.div`
left: 543px;
width: 700px;
margin:auto; 
/* UI Properties */
background: #FFFFFF80 0% 0% no-repeat padding-box;
box-shadow: inset 0px 3px 6px #00000029;
border-radius: 30px;
opacity: 1;
backdrop-filter: blur(30px);
-webkit-backdrop-filter: blur(30px);
@media only screen and (min-width: 415px) and (max-width: 650px) {
    margin: auto;
    ${'' /* height: 950px; */}
    margin-bottom: 30px;
    width: 80%;
}
@media only screen and (max-width: 414px) {
    margin: auto;
    ${'' /* height: 550px; */}
    margin-bottom: 30px;
    width: 80%;
}
`

const About = () => {

    useEffect(() => {
        Analytics.record({
            name: "About page visit"
        })
    }, [])

    return (
        <ContainerAnima>

            <Container>

                <ContentContainer>
                    <AboutPageBox>
                        <Image image="/imagesss/About.jpg" />
                        {/* <GreyBackground>
                <Framewrok src="/svg/framewrok-hero.svg" />
            </GreyBackground> */}
                        <ContentOutContainer>
                            <ContentInContainer>
                                <Title title="Mō tēnei rauemi" />
                                <SubTitle subtitle="About this resource" />
                                <TextContainer>
                                    <TextValues>
                                        Tupukupu is a user-friendly and enjoyable app designed to assist learners in building a mihi, pepeha or whakapapa statements, and incorporating everyday te reo Māori language into their interactions. Like many language resources, Tupukupu is a tool designed to complement and support in-class reo lessons.

                                        <TextBold className="bold"> This app does not collect or store any user data on external servers. All data is processed locally on your device and is stored only on your browser or phone. We do not have access to, nor do we retain, any personal information.</TextBold>
                                    </TextValues>
                                    If you have any feedback on this application, follow this
                                    <CustomLink href="https://forms.office.com/r/EYkfZxqJ4L" target="_blank" rel="noopener noreferrer">
                                        link
                                    </CustomLink>.
                                </TextContainer>
                            </ContentInContainer>
                        </ContentOutContainer>
                    </AboutPageBox>
                </ContentContainer>
            </Container>
        </ContainerAnima>



    )
}

export default About;