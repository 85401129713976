import React from 'react';
import styled from 'styled-components'

const ButtonStyled = styled.button`
    background: #FEFEFE 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 10px;  
    box-shadow: 0px 0px 30px var(--unnamed-color-000000);
    box-shadow: 0px 0px 30px #000000;
    color: #AE0F0B;
    cursor: pointer;
    font-family: SemiBoldFont;
    font-size: 14px;
    margin: 10px 0;
    padding: 25px 45px;
    width: 100%;
    // background: #FEFEFE 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 10px;  
    box-shadow: 0px 0px 30px var(--unnamed-color-000000);
    box-shadow: 0px 0px 30px #000000;
    // opacity: 1;
    // color: #2D373C;
    // cursor: pointer;
    // margin: 10px 15px 10px 15px;
    // padding: 2px;
  

    @media only screen and (min-width: 1200px) {
        font-size: 18px;
    }
`;


const Button = ({ text, ArgBackgroundColor, ArgHoverBackgroundColor, ArgBorder, ArgColor, onClick, disabled }) => {
    return (

        <ButtonStyled
            backgroundColor={ArgBackgroundColor ? ArgBackgroundColor : "#5c9021"}
            hoverBackgroundColor={ArgHoverBackgroundColor ? ArgHoverBackgroundColor : "#22170F"}
            border={ArgBorder ? ArgBorder : "none"}
            color={ArgColor ? ArgColor : "white"}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </ButtonStyled>

    )
}

export default Button;