import React from 'react';
import styled from 'styled-components'

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
`;

const ImageStyled = styled.img`
  display: inherit;
  object-fit: cover;
  width: 80%;
  /* Layout Properties */
    /* UI Properties */
    background: transparent url('img/Screenshot 2023-08-08 at 10.48.09 PM.png') 0% 0% no-repeat padding-box;
    border-radius: 90px;
    opacity: 1;
    margin-top: 40px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
  @media only screen and (min-width: 390px) {
    width: 80%;
    border-radius: 80px;
    }

    @media only screen and (max-width: 390px) {
      width: 80%;
      border-radius: 40px;
      }

  @media only screen and (min-width: 1200px) {
    height: 35vh;
    width: 70%;
    height: 100%;
    }
`;


const Image = ({ image }) => {
    return (
        <ImageContainer>
            <ImageStyled src={image} alt="" />
        </ImageContainer>
    )
}

export default Image;