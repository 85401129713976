import React from 'react';
import { Document, View, Page, Font, Image, Text, StyleSheet } from '@react-pdf/renderer';
import fontMedium from '../../fonts/Poppins-Medium.ttf'
import fontRegular from '../../fonts/Poppins-Regular.ttf'

Font.register({ family: 'PoppinsMedium', src: fontMedium })
Font.register({ family: 'PoppinsRegular', src: fontRegular })

// styling guide https://react-pdf.org/styling
const stylesLight = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        color: '#2D373C',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        textAlign: 'center',
        margin: 30,
        width: 500
    },
    maori_text: {
        fontSize: 12,
        fontFamily: 'PoppinsMedium'
    },
    eng_text: {
        fontSize: 10,
        fontFamily: 'PoppinsRegular',
        marginBottom: 12,
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
    },
    image: {
        width: 74,
        height: 100
    }
});

const stylesDark = StyleSheet.create({
    page: {
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        color: 'white',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        textAlign: 'center',
        margin: 30,
        width: 500
    },
    maori_text: {
        fontSize: 12,
        fontFamily: 'PoppinsMedium'
    },
    eng_text: {
        fontSize: 10,
        fontFamily: 'PoppinsRegular',
        marginBottom: 12,
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
    },
    image: {
        width: 74,
        height: 100
    }
});

const MaoriText = ({ text, toPrintTheme }) => <Text style={toPrintTheme ? stylesLight.maori_text : stylesDark.maori_text}>{text}</Text>
const EngText = ({ text, toPrintTheme }) => <Text style={toPrintTheme ? stylesLight.eng_text : stylesDark.eng_text}>{text}</Text>

const IsMaoriPepehaToPrint = ({
    greetingsTo,
    greetingsToCont,
    i,
    iamFeeling,
    personalPronoun,
    therefore,
    to,
    toB,

    saying,
    great,
    mo,
    kite,
    these,
    concerning,

    whoHave,
    toPrintTheme,
}) => (
    <Document>
        <Page size="A4" style={toPrintTheme ? stylesLight.page : stylesDark.page}>
            <View style={toPrintTheme ? stylesLight.section : stylesDark.section}>
                <View style={toPrintTheme ? stylesLight.image_container : stylesDark.image_container}>
                    {
                        toPrintTheme ?
                            <Image style={toPrintTheme ? stylesLight.image : stylesDark.image} src="/img/mihimihi-for-light-pdf.png" />
                            :
                            <Image style={toPrintTheme ? stylesLight.image : stylesDark.image} src="/img/mihimihi-for-dark-pdf.png" />
                    }
                </View>
                {greetingsTo !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`Tēnā ${greetingsTo[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`Greetings to ${greetingsTo[1]}`}
                        />
                    </>
                }

                {greetingsToCont !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`${greetingsToCont[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`${greetingsToCont[1]}`}
                        />
                    </>
                }

                {iamFeeling !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`Kei te ${iamFeeling[0]} ahau`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`Greetings to ${iamFeeling[1]}`}
                        />
                    </>
                }

                {whoHave !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`kua ${whoHave[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`who have ${whoHave[1]}`}
                        />
                    </>
                }

                {to !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`ki ${to[0] ? to[0] : ""}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`to ${to[1] ? to[1] : ""}`}
                        />
                    </>
                }
                {toB !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`ki te ${toB[0]} ${personalPronoun[0] ? personalPronoun[0] : ""}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`to ${toB[1]} ${personalPronoun[1] ? personalPronoun[1] : ""}`}
                        />
                    </>
                }

                {i !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`i ${i[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`${i[1]}`}
                        />
                    </>
                }

                {saying !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`E kiia nei te kōrero`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`The saying goes`}
                        />
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`${saying[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`${saying[1]}`}
                        />
                    </>
                }

                {great !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`Nā konā, kei a ${great[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`As such ${great[1]} have great`}
                        />
                    </>
                }

                {mo !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`mō te ${mo[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={` ${mo[1]}`}
                        />
                    </>
                }

                {kite !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`Ki te ${kite[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`to${kite[1]}`}
                        />
                    </>
                }

                {these !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`i ${these[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`this/these ${these[1]}`}
                        />
                    </>
                }

                {concerning !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`e pā ana ki ${concerning[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`concerning ${concerning[1]}`}
                        />
                    </>
                }

                {/* {i !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`${i[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`${i[1]}`}
                        />
                    </>
                } */}

                {therefore !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`nō reira ${therefore[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`therefore ${therefore[1]}`}
                        />
                    </>
                }

                {greetingsTo !== "" &&
                    <>
                        <MaoriText
                            toPrintTheme={toPrintTheme}
                            text={`Tēnā ${greetingsTo[0]}`}
                        />
                        <EngText
                            toPrintTheme={toPrintTheme}
                            text={`Greetings to ${greetingsTo[1]}`}
                        />
                    </>
                }

            </View>
        </Page>
    </Document>
);

export default IsMaoriPepehaToPrint;