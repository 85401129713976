import { getMaoriVoices } from "./maori-voices";
import { getAudioByUrl } from "./play-sound";

const syncTextWithVoice = (startingPoint, timeoutDuration, maoriText, element, clone) => {
    setTimeout(() => {
        element.innerHTML = `<span style="color: red">${maoriText.substring(0, startingPoint)}</span>${maoriText.substring(startingPoint, maoriText.length)}`
        startingPoint += 1;
        if (startingPoint <= maoriText.length) {
            syncTextWithVoice(startingPoint, timeoutDuration, maoriText, element, clone);
        } else {
            element.replaceChildren(clone);
        }
    }, timeoutDuration);
}


const handleGetDuration = (duration, maoriText) => {
    duration = duration ? Math.round(duration) * 1000 : 0;
    const element = document.evaluate(`//p[contains(text(),'${maoriText}')]`, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    if (element) {
        const clone = element.cloneNode(true)
        const timeoutDuration = duration / maoriText.length;
        let startingPoint = 2;
        element.innerHTML = `<span style="color: red">${maoriText[0]}</span>${maoriText.substring(1, maoriText.length)}`
        syncTextWithVoice(startingPoint, timeoutDuration, maoriText, element, clone);
    }
}


export const playMaoriSound = (maoriText, handlePlaybackFinished, setIsAudioPlaying, updateAudio) => {
    const voiceUrl = getMaoriVoices(maoriText);
    if (voiceUrl) {
        const audio = getAudioByUrl(voiceUrl, handlePlaybackFinished, (metadata) => {
            handleGetDuration(metadata.currentTarget.duration, maoriText);
        })
        setIsAudioPlaying(true)
        updateAudio(audio);
        audio.play().catch((error) => console.error('Error playing audio:', error));
        return audio;
    }
}