import React from 'react';
import styled from 'styled-components'
import KarakiaButtons from './KarakiaButtons';
import Title from '../Title';

const Container = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const SubTitle = styled.p`
    margin: -30px 0 50px 0;
`;

const Audio = styled.audio`
    width: 80%;
    margin-bottom: 2rem;
`;

const LyricContainer = styled.div`
    // background-color: #F8F8F8;
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    border-radius: 6px;
    margin-top: 30px;
    text-align: center;
    padding 50px 0;
    width: 100%;
    
    // @media only screen and (min-width: 850px) {
    //     width: 90%;
    // }
    // @media only screen and (min-width: 1200px) {
    //     width: 100%;
    // }
`;

const KarakiaView = ({
    title,
    subTitle,
    song,
    lyric
}) => {
    return (

        <Container>
            <div>
                <LyricContainer   >
                    <Title title={title} />
                    <SubTitle>{subTitle}</SubTitle>

                    <Audio src={song} controls />
                    {lyric}
                </LyricContainer>

                <KarakiaButtons />
            </div>
        </Container>

    )
}

export default KarakiaView;