import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Title from './Title'
import Button from './Button';
import ButtonOption from './ButtonOption';
import ErrorMessage from './ErrorMessage';

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90vh;
`;

const Container = styled.div`
    text-align: center;
    width: 380px;
    padding: 35px 50px;

    border-radius: 10px;  
    opacity: 1;
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    @media only screen and (min-width: 768px) {
        padding: 50px 135px;
    }
`;

const BasicAdvanced = ({
    setState,
    state,
    image,
    basic,
    advanced,
    path,
    title
}) => {
    const [showError, setShowError] = useState(false)
    return (
        <ContainerOut>
            <Container>
                <img src={image} alt="Basic or advanced?" width={190} />
                <Title title={title} />

                <ButtonOption
                    text={basic}
                    setBtState={setState}
                    btState={true}
                    active={state === true}
                />
                <ButtonOption
                    text={advanced}
                    setBtState={setState}
                    btState={false}
                    active={state === false}
                />
                {showError && <ErrorMessage text="Please select an option above" />}

                {
                    state === "error" ?
                        <div onClick={() => setShowError(true)}>
                            <Button text="Next" />
                        </div>
                        :
                        <Link to={path}>
                            <Button text="Next" />
                        </Link>
                }
            </Container>
        </ContainerOut>
    )
}

export default BasicAdvanced;