export const data = [
    {
      Capital: "Kuhu mai",
      Currency: "Come in",
    },
    {
      Capital: "E kai",
      Currency: "Eat",
    },
    {
      Capital: "Haere mai",
      Currency: "Come here",
    },
    {
      Capital: "Haere atu",
        Currency: "Go away",
      },
      {
        Capital: "E oma",
        Currency: "Run",
      },
      {
        Capital: "Āta haere",
        Currency: "Slow down",
      },
      {
        Capital: "Whakarongo mai",
        Currency: "Listen up",
      },
      {
        Capital: "Titiro mai",
        Currency: "Look towards me",
      },
      {
        Capital: "Huri mai",
        Currency: "Turn to me",
      },
      {
        Capital: "Taihoa",
        Currency: "Wait",
      },
      {
        Capital: "Kōrero mai",
        Currency: "Speak up",
      },
      {
        Capital: "Hoihoi",
        Currency: "Be quiet",
      }
  ];
  