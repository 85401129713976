import React from 'react'
import styled from 'styled-components'

const TextTip = styled.p`
    font-family: MediumFont;
`;

const Span = styled.span`
    font-family: MediumFont;
    color: #DF7726;
    display: block;
    margin: 0 auto; 
    width: 80%;
    text-align: center;
    cursor: pointer;
`;

const SectionIntroHandlePopUp = ({ setShowPopUp, text }) => {
    return (
        <div>
            <p style={{fontSize:"1.2rem" , textAlign: "left"}}>{text} <br />
                {setShowPopUp && <Span onClick={() => setShowPopUp(true)}>Learn more.</Span>}
            </p>
            {/* <TextTip>Tip: Skip any sections that don’t apply</TextTip> */}
        </div>
    )
}


export default SectionIntroHandlePopUp