import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Image from '../components/Image'
import WaiataButtons from '../components/Waiata/WaiataButtons';
import WaiataView from '../components/Waiata/WaiataView';
import LyricOne from '../components/Waiata/LyricOne';
import LyricTwo from '../components/Waiata/LyricTwo';
import LyricThree from '../components/Waiata/LyricThree';
import LyricFour from '../components/Waiata/LyricFour';
import LyricFive from '../components/Waiata/LyricFive';
import LyricSix from '../components/Waiata/LyricSix';
import LyricSeven from '../components/Waiata/LyricSeven';
import LyricEight from '../components/Waiata/LyricEight';

import Title from '../components/Title'
import SectionIntroHandlePopUp from '../components/SectionIntroHandlePopUp';


const WhakapapaContainer = styled.div`
    margin-top: 55px;
`;

// const IntroContainer = styled.div`
//     text-align: center;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// `;

const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
    height: 100%;
    width: 560px;
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    padding-bottom: 2rem;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin: auto auto 30px;
    @media only screen and (max-width: 650px) {
        width: 80%;
    }
    @media only screen and (min-width: 850px) {
        width: 50%;
    }

`;
const SectionIntroHandlePopUpContainer = styled.div`
    margin: -15px auto 32px auto;
    width: 400px;
    font-size: 19px;
`



const SubTitle = styled.p`
    margin-top: -30px;
`;

const Content = styled.div`
    margin-top: -30px;
`;

const URL = "https://westpac-bucket.s3.ap-southeast-2.amazonaws.com/waiata/"


const Waiata = () => {
    let { view } = useParams()

    useEffect(() => {
        Analytics.record({
            name: "Waiata page visit"
        })
    }, [])

    if (view) {
        return (
            <WhakapapaContainer>
                {
                    view === "0" &&
                    <IntroContainer>
                        <Image image="/img/waiata.jpg" />
                        <Content>
                            <img src="/imagesss/All SVG/Waiata (Songs).svg" alt='songs icon' width={190} style={{ display: "block", margin: "auto" }} />
                            <Title title="Waiata" />
                            {/* <SubTitle>Listen and learn songs</SubTitle> */}
                            <SectionIntroHandlePopUpContainer >
                                <SectionIntroHandlePopUp
                                    text="Waiata is a song often performed to complement a speech or an occasion. For this reason it is important one knows the meaning of the waiata. Waiata is a wonderful way to express yourself and connect with others."
                                />
                            </SectionIntroHandlePopUpContainer>
                            <WaiataButtons />
                        </Content>
                    </IntroContainer>
                }

                {
                    view === "1" &&
                    <WaiataView
                        title="Purea nei"
                        // subTitle="Restored by"
                        song={`${URL}purea_nei.mp3`}
                        lyric={<LyricOne />}
                        songInfoText="Composed by Hirini Melbourne, Purea nei honours the life of one of his former students who sadly passed away."
                    />
                }
                {
                    view === "3" &&
                    <WaiataView
                        title="Te Aroha"
                        // subTitle="Love"
                        song={`${URL}te-aroha.mp3`}
                        lyric={<LyricThree />}
                        songInfoText="Composed by Morvin Simon, Te Aroha was composed to encourage people from diverse backgrounds to come together in compassion, faith, and peace."
                    />
                }

                {
                    view === "4" &&
                    <WaiataView
                        title="Tū tira mai ngā iwi"
                        // subTitle="Stand together everyone"
                        song={`${URL}tutira-mai-nga-iwi.mp3`}
                        lyric={<LyricFour />}
                        songInfoText="Wi Huata composed this song for his tamariki as a reminder of the importance of whanaungatanga and relationships."
                    />
                }
                {
                    view === "5" &&
                    <WaiataView
                        title="Pōkarekare ana"
                        // subTitle="Agitated"
                        song={`${URL}pokarekare-ana.mp3`}
                        lyric={<LyricFive />}
                        songInfoText="Notably composed by Paraire Tomoana, Pōkarekare ana is a song of love for his sweetheart."
                    />
                }

                {
                    view === "6" &&
                    <WaiataView
                        title="Ka mate"
                        // subTitle="Tis death"
                        song={`${URL}ka-mate.mp3`}
                        lyric={<LyricSix />}
                        songInfoText="Composed by Ngāti Toa chief Te Rauparaha, this haka was recited in response to a life or death situation Te Rauparaha found himself in."
                    />
                }

                {
                    view === "7" &&
                    <WaiataView
                        title="Ehara i te mea"
                        // subTitle="It is not that"
                        song={`${URL}ehara-i-te-mea.mp3`}
                        lyric={<LyricSeven />}
                        songInfoText="Composed by Eru Ihaka, this song speaks to the enduring gift of love handed down to us from our ancestors."
                    />
                }
                {
                    view === "8" &&
                    <WaiataView
                        title="Toi Te Rawenga"
                        // subTitle="It is not that"
                        song={`${URL}Toi_Te_Rawenga.mp3`}
                        lyric={<LyricEight />}
                        songInfoText="Composed by Valance Smith, Toi Te Rawenga honours the staff and students of AUT embarking on their Tiriti honouring journey."
                    />
                }



            </WhakapapaContainer>
        )
    }

}

export default Waiata;