let idNumber = 0

const datas = {
    karakia:
        [
            {
                lyricOne: [
                    {
                        id: ++idNumber,
                        Capital: "Tuia ki te rangi",
                        Currency: "Dedicated to the sky",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tuia ki te whenua",
                        Currency: "and to the earth below",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tuia ki te",
                        Currency: "human kind are bound",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ira tangata",
                        Currency: "and inseperable",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ka rongo te pō",
                        Currency: "From the infinite potential",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ka rongo te ao",
                        Currency: "to the realization in light",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tūturu whakamaua",
                        Currency: "Let us come together",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia tīna",
                        Currency: "with purpose",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Haumi e Hui e",
                        Currency: "united and ready",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tāiki e",
                        Currency: "to progress",
                    },
                ],
                lyricTwo: [
                    {
                        id: ++idNumber,
                        Capital: "Ūhia mai rā",
                        Currency: "Bestow upon us",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ko te wairua tapu",
                        Currency: "the divine spirit",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Hei pūeru mō te iwi",
                        Currency: "to cloak the people",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Me te aroha",
                        Currency: "and with the love",
                    },
                    {
                        id: ++idNumber,
                        Capital: "O te runga rawa",
                        Currency: "of the higher being",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ua noa nei",
                        Currency: "may it shower",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ki te whenua",
                        Currency: "across the land",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tōmairangi noa",
                        Currency: "and dew",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ki ngā tāngata katoa",
                        Currency: "upon all people",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Haumi e hui e tāiki e",
                        Currency: "for us all",
                    },
                ],
                lyricThree: [
                    {
                        id: ++idNumber,
                        Capital: "Nau mai e ngā hua",
                        Currency: "Bequeath the gifts",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Nā Tāne",
                        Currency: "of Tāne",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Nā Rongo",
                        Currency: "of Rongo",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Nā Tangaroa",
                        Currency: "of Tangaroa",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Nā Maru",
                        Currency: "of Maru",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Whītiki ki te ora",
                        Currency: "life giving sustenance",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Whakamaua kia tīna",
                        Currency: "Let it be realised",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Hui e Tāiki e",
                        Currency: "for us all",
                    },
                ],
                lyricFour: [
                    {
                        id: ++idNumber,
                        Capital: "Kia tau iho",
                        Currency: "Let the strength",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Te tauwhirotanga",
                        Currency: "and serenity",
                    },
                    {
                        id: ++idNumber,
                        Capital: "O te wāhi ngaro",
                        Currency: "of our ancestors",
                    },
                    {
                        id: ++idNumber,
                        Capital: "E pai ai te nohotahi",
                        Currency: "guide us as we gather",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ā tinana, wairua hoki",
                        Currency: "in body and spirit",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Whakauenuku ake",
                        Currency: "Holdfast to",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Te āio mō te katoa",
                        Currency: "Peace for all",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia whakamaua kia tīna",
                        Currency: "let it be realised",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Hui e Tāiki e",
                        Currency: "for us all",
                    },
                ],
                lyricFive: [
                    {
                        id: ++idNumber,
                        Capital: "Mauri oho",
                        Currency: "Life force awaken",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Mauri tū",
                        Currency: "Life force stand tall",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Mauri ora, ki a tātou",
                        Currency: "Life force all wellness, good health for all",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Haumi e, Hui e, Tāiki e!",
                        Currency: "Join together, unite, the group is ready to progress for the purpose of coming together",
                    },
                ],
                lyricSix: [
                    {
                        id: ++idNumber,
                        Capital: "Whakataka te hau ki te uru",
                        Currency: "Cease the winds from the west",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Whakataka te hau ki te tonga",
                        Currency: "Cease the winds from the south",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia mākinakina ki uta",
                        Currency: "Let the breeze blow over the land",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia mātaratara ki tai",
                        Currency: "Let the breeze blow over the ocean",
                    },
                    {
                        id: ++idNumber,
                        Capital: "E hī ake ana te atakura",
                        Currency: "Let the red-tipped dawn come with a sharpened air",
                    },
                    {
                        id: ++idNumber,
                        Capital: "He tio, he huka, he hau hū",
                        Currency: "A touch of frost",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tīhei mauri ora!",
                        Currency: "a promise of a glorious day",
                    },
                ],
                lyricSeven: [
                    {
                        id: ++idNumber,
                        Capital: "Kia tau kia tātou katoa",
                        Currency: "May peace, love, and safety",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Te āio, te aroha me te marutau",
                        Currency: "Be upon us all",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tīhei mauri ora",
                        Currency: "a promise of a glorious day",
                    },
                ],
                lyricEight: [
                    {
                        id: ++idNumber,
                        Capital: "Unuhia, unuhia",
                        Currency: "Draw on, draw on",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Unuhia ki te uru tapu nui",
                        Currency: "Draw on the supreme sacredness",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia wātea, kia māmā, te ngākau, te tinana, te wairua i te ara takatā",
                        Currency: "To clear, to free the heart, the body and the spirit of mankind",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Koia rā e Rongo, whakairia ake ki runga",
                        Currency: "Rongo, suspended high above us",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia tina! Tina! Hui e! Tāiki e!",
                        Currency: "Draw together! Affirm!",
                    },
                ],
            },
        ],
    keteKorero:
        [

            // farewellsdata
            {
                id: ++idNumber,
                Capital: "Ka kite",
                Currency: "See you later",
            },
            {
                id: ++idNumber,
                Capital: "Haere rā",
                Currency: "Farewell (to someone leaving)",
            },
            {
                id: ++idNumber,
                Capital: "E noho rā",
                Currency: "Farewell (to someone staying)",
            },
            {
                id: ++idNumber,
                Capital: "Hei konei rā",
                Currency: "Farewell",
            },
            {
                id: ++idNumber,
                Capital: "Pō mārie",
                Currency: "Good night",
            },
            {
                id: ++idNumber,
                Capital: "Kia pai te rā",
                Currency: "Have a good day",
            },
            {
                id: ++idNumber,
                Capital: "Hei te wā",
                Currency: "On time",
            },
            {
                id: ++idNumber,
                Capital: "Ā tōna wā",
                Currency: "Till next time",
            },

            // greetingsdata
            {
                id: ++idNumber,
                Capital: "Kia ora",
                Currency: "Hello/Thanks",
            },
            {
                id: ++idNumber,
                Capital: "Tēnā koe",
                Currency: "Greetings (1 person)",
            },
            {
                id: ++idNumber,
                Capital: "Tēnā kōrua",
                Currency: "Greetings (2 people)",
            },
            {
                id: ++idNumber,
                Capital: "Tēnā koutou",
                Currency: "Greetings (3 + people)",
            },
            {
                id: ++idNumber,
                Capital: "Ata mārie",
                Currency: "Good morning",
            },
            {
                id: ++idNumber,
                Capital: "Mōrena",
                Currency: "Morning",
            },
            {
                id: ++idNumber,
                Capital: "Ngā mihi o te ahiahi",
                Currency: "Good afternoon",
            },

            // idiomsdata
            {
                id: ++idNumber,
                Capital: "Ka mau te wehi",
                Currency: "Wicked",
            },
            {
                id: ++idNumber,
                Capital: "Koia kei a koe",
                Currency: "You’re awesome",
            },
            {
                id: ++idNumber,
                Capital: "Hei aha tāu",
                Currency: "Never mind yours",
            },
            {
                id: ++idNumber,
                Capital: "Aua hoki",
                Currency: "I don’t know",
            },
            {
                id: ++idNumber,
                Capital: "Kei wīwī kei wāwā",
                Currency: "Here, there, everywhere",
            },
            {
                id: ++idNumber,
                Capital: "Nē?",
                Currency: "Really?",
            },
            {
                id: ++idNumber,
                Capital: "Nē",
                Currency: "Aye",
            },
            {
                id: ++idNumber,
                Capital: "Nā whai anō",
                Currency: "No wonder",
            },
            {
                id: ++idNumber,
                Capital: "Tōnā tikanga",
                Currency: "Supposedly",
            },
            {
                id: ++idNumber,
                Capital: "E taea ana te aha",
                Currency: "What can one do",
            },
            {
                id: ++idNumber,
                Capital: "Hoatu",
                Currency: "You go",
            },
            {
                id: ++idNumber,
                Capital: "Hoake",
                Currency: "Let’s go",
            },
            {
                id: ++idNumber,
                Capital: "Nā wai rā, nā wai rā",
                Currency: "As a result",
            },
            {
                id: ++idNumber,
                Capital: "Ka mutu",
                Currency: "Furthermore",
            },
            {
                id: ++idNumber,
                Capital: "Kei tūpono",
                Currency: "Just in case",
            },

            // instructionsdata
            {
                id: ++idNumber,
                Capital: "Kuhu mai",
                Currency: "Come in",
            },
            {
                id: ++idNumber,
                Capital: "E kai",
                Currency: "Eat",
            },
            {
                id: ++idNumber,
                Capital: "Haere mai",
                Currency: "Come here",
            },
            {
                id: ++idNumber,
                Capital: "Haere atu",
                Currency: "Go away",
            },
            {
                id: ++idNumber,
                Capital: "E oma",
                Currency: "Run",
            },
            {
                id: ++idNumber,
                Capital: "Āta haere",
                Currency: "Slow down",
            },
            {
                id: ++idNumber,
                Capital: "Whakarongo mai",
                Currency: "Listen up",
            },
            {
                id: ++idNumber,
                Capital: "Titiro mai",
                Currency: "Look towards me",
            },
            {
                id: ++idNumber,
                Capital: "Huri mai",
                Currency: "Turn to me",
            },
            {
                id: ++idNumber,
                Capital: "Taihoa",
                Currency: "Wait",
            },
            {
                id: ++idNumber,
                Capital: "Kōrero mai",
                Currency: "Speak up",
            },
            {
                id: ++idNumber,
                Capital: "Hoihoi",
                Currency: "Be quiet",
            },

            // signoffsdata
            {
                id: ++idNumber,
                Capital: "Nā",
                Currency: "From",
            },
            {
                id: ++idNumber,
                Capital: "Nāku, nā",
                Currency: "Yours faithfully",
            },
            {
                id: ++idNumber,
                Capital: "Nāku iti noa, nā",
                Currency: "Yours sincerely",
            },
            {
                id: ++idNumber,
                Capital: "Ngā mihi",
                Currency: "Thank you",
            },
            {
                id: ++idNumber,
                Capital: "Ngā mihi nui",
                Currency: "Kind regards",
            },
            {
                id: ++idNumber,
                Capital: "Hei konā mai",
                Currency: "Goodbye for now",
            },

            // survivalreodata
            {
                id: ++idNumber,
                Capital: "Āwhina mai",
                Currency: "Please help",
            },
            {
                id: ++idNumber,
                Capital: "Kei hea te wharepaku",
                Currency: "Where are the toilets",
            },
            {
                id: ++idNumber,
                Capital: "Kei hea te wharekai",
                Currency: "Where is the kitchen/ dining room",
            },
            {
                id: ++idNumber,
                Capital: "Āe",
                Currency: "Yes",
            },
            {
                id: ++idNumber,
                Capital: "Kāo",
                Currency: "No",
            },
            // {
            // id: ++idNumber,
            //   Capital: "Kōrero mai anō", 
            //   Currency: "Say that again",
            // },
            {
                id: ++idNumber,
                Capital: "Kōrero mai anō",
                Currency: "Say that again",
            },
            {
                id: ++idNumber,
                Capital: "He aha te kupu Māori mō ….?",
                Currency: "What is the Māori word for …?",
            },
            {
                id: ++idNumber,
                Capital: "He aha te kupu Pākehā mō…?",
                Currency: "What is the English word for …?",
            },
            {
                id: ++idNumber,
                Capital: "Homai te ….",
                Currency: "Pass me the ….",
            },
            {
                id: ++idNumber,
                Capital: "He pātai tāku",
                Currency: "I have a question",
            },
            {
                id: ++idNumber,
                Capital: "Kei te pai ahau",
                Currency: "I’m okay",
            },
            {
                id: ++idNumber,
                Capital: "Kāore au I te pai",
                Currency: "I’m not okay",
            },
            {
                id: ++idNumber,
                Capital: "Kua hiakai ahau",
                Currency: "I’m hungry",
            },
            {
                id: ++idNumber,
                Capital: "Kua hiainu ahau",
                Currency: "I’m thirsty",
            },
        ],
    kupuTaka:
        [
            // A
            {
                id: ++idNumber,
                Capital: "ā",
                Currency: "and, and then",
            },
            {
                id: ++idNumber,
                Capital: "ahau",
                Currency: "me, I",
            },
            {
                id: ++idNumber,
                Capital: "aku",
                Currency: "my (plural)",
            },
            {
                id: ++idNumber,
                Capital: "anō",
                Currency: "again, indeed",
            },
            {
                id: ++idNumber,
                Capital: "aroha",
                Currency: "love, care",
            },
            {
                id: ++idNumber,
                Capital: "awa",
                Currency: "river",
            },

            // E
            {
                id: ++idNumber,
                Capital: "e aku rangatira",
                Currency: "my leader",
            },
            {
                id: ++idNumber,
                Capital: "e hoa",
                Currency: "dear friend",
            },
            {
                id: ++idNumber,
                Capital: "e hoa mā",
                Currency: "dear friends",
            },
            {
                id: ++idNumber,
                Capital: "e te hau kāinga",
                Currency: "to the hosts",
            },
            {
                id: ++idNumber,
                Capital: "e te manuhiri",
                Currency: "to the guests",
            },
            {
                id: ++idNumber,
                Capital: "e te whānau",
                Currency: "to the family",
            },
            {
                id: ++idNumber,
                Capital: "e tipu e rea",
                Currency: "grow young seedling (child)",
            },
            {
                id: ++idNumber,
                Capital: "ehara",
                Currency: "It is not, does not",
            },
            {
                id: ++idNumber,
                Capital: "ehara i te mea",
                Currency: "It is not as if...",
            },

            // H
            {
                id: ++idNumber,
                Capital: "hapū",
                Currency: "sub-tribe",
            },
            {
                id: ++idNumber,
                Capital: "hau",
                Currency: "wind",
            },
            {
                id: ++idNumber,
                Capital: "he",
                Currency: "a",
            },
            {
                id: ++idNumber,
                Capital: "he hōnore",
                Currency: "to honour, Majesty",
            },
            {
                id: ++idNumber,
                Capital: "he tangata",
                Currency: "the people",
            },
            {
                id: ++idNumber,
                Capital: "he whānau",
                Currency: "the family",
            },
            {
                id: ++idNumber,
                Capital: "hoa",
                Currency: "friend",
            },

            // I
            {
                id: ++idNumber,
                Capital: "ingoa",
                Currency: "name",
            },
            {
                id: ++idNumber,
                Capital: "iwi",
                Currency: "tribe, people",
            },

            // K
            {
                id: ++idNumber,
                Capital: "ka",
                Currency: "(particle)",
            },
            {
                id: ++idNumber,
                Capital: "ka mate",
                Currency: "to perish, to be unwell",
            },
            {
                id: ++idNumber,
                Capital: "kai",
                Currency: "food",
            },
            {
                id: ++idNumber,
                Capital: "kāinga",
                Currency: "home",
            },
            {
                id: ++idNumber,
                Capital: "karakia",
                Currency: "blessing",
            },
            {
                id: ++idNumber,
                Capital: "karakia mō te kai",
                Currency: "blessing of food",
            },
            {
                id: ++idNumber,
                Capital: "karakia timatanga",
                Currency: "opening blessing",
            },
            {
                id: ++idNumber,
                Capital: "karawhiua",
                Currency: "go for it",
            },
            {
                id: ++idNumber,
                Capital: "katoa",
                Currency: "all",
            },
            {
                id: ++idNumber,
                Capital: "kia ora",
                Currency: "hello, thank you",
            },
            {
                id: ++idNumber,
                Capital: "koe",
                Currency: "you",
            },
            {
                id: ++idNumber,
                Capital: "koroua",
                Currency: "elderly man",
            },
            {
                id: ++idNumber,
                Capital: "kōrua",
                Currency: "you two",
            },
            {
                id: ++idNumber,
                Capital: "koutou",
                Currency: "you three or more",
            },
            {
                id: ++idNumber,
                Capital: "koutou katoa",
                Currency: "you (plural) all",
            },
            {
                id: ++idNumber,
                Capital: "kūoro",
                Currency: "syllable",
            },
            {
                id: ++idNumber,
                Capital: "kupu",
                Currency: "word",
            },
            {
                id: ++idNumber,
                Capital: "kupu taka",
                Currency: "glossary",
            },

            // M
            {
                id: ++idNumber,
                Capital: "mai",
                Currency: "a particle indicating towards speakers direction",
            },
            {
                id: ++idNumber,
                Capital: "māmā",
                Currency: "mother",
            },
            {
                id: ++idNumber,
                Capital: "manaaki",
                Currency: "care",
            },
            {
                id: ++idNumber,
                Capital: "manawanui",
                Currency: "dedicated",
            },
            {
                id: ++idNumber,
                Capital: "manuhiri",
                Currency: "guest",
            },
            {
                id: ++idNumber,
                Capital: "Māori",
                Currency: "Indigenous people of Aotearoa",
            },
            {
                id: ++idNumber,
                Capital: "marae",
                Currency: "cultural centre",
            },
            {
                id: ++idNumber,
                Capital: "maru",
                Currency: "shelter, shadow",
            },
            {
                id: ++idNumber,
                Capital: "mate",
                Currency: "unwell, perish",
            },
            {
                id: ++idNumber,
                Capital: "maunga",
                Currency: "mountain",
            },
            {
                id: ++idNumber,
                Capital: "mea",
                Currency: "thing",
            },
            {
                id: ++idNumber,
                Capital: "mihi",
                Currency: "greeting",
            },
            {
                id: ++idNumber,
                Capital: "mō",
                Currency: "for, about",
            },
            {
                id: ++idNumber,
                Capital: "mō tēnei rauemi",
                Currency: "about this resource",
            },
            {
                id: ++idNumber,
                Capital: "moana",
                Currency: "sea",
            },

            // N
            {
                id: ++idNumber,
                Capital: "ngā",
                Currency: "the (plural)",
            },
            {
                id: ++idNumber,
                Capital: "ngā kūoro",
                Currency: "syllables",
            },
            {
                id: ++idNumber,
                Capital: "ngā orokati",
                Currency: "consonants",
            },
            {
                id: ++idNumber,
                Capital: "ngā oropuare",
                Currency: "vowels",
            },
            {
                id: ++idNumber,
                Capital: "ngāwari",
                Currency: "easy",
            },
            {
                id: ++idNumber,
                Capital: "nō",
                Currency: "from",
            },
            {
                id: ++idNumber,
                Capital: "nō reira",
                Currency: "therefore",
            },
            {
                id: ++idNumber,
                Capital: "noho",
                Currency: "sit, stay",
            },

            // O
            {
                id: ++idNumber,
                Capital: "o",
                Currency: "of",
            },
            {
                id: ++idNumber,
                Capital: "orokati",
                Currency: "consonant",
            },
            {
                id: ++idNumber,
                Capital: "oropuare",
                Currency: "vowel",
            },

            // P
            {
                id: ++idNumber,
                Capital: "pāpā",
                Currency: "father",
            },
            {
                id: ++idNumber,
                Capital: "pepeha",
                Currency: "provenance, personal statement",
            },
            {
                id: ++idNumber,
                Capital: "poipoia",
                Currency: "nurture, promote",
            },
            {
                id: ++idNumber,
                Capital: "pōkarekare",
                Currency: "ripple",
            },
            {
                id: ++idNumber,
                Capital: "puke",
                Currency: "hill",
            },

            // R
            {
                id: ++idNumber,
                Capital: "rangatira",
                Currency: "leader",
            },
            {
                id: ++idNumber,
                Capital: "rāua",
                Currency: "those two",
            },
            {
                id: ++idNumber,
                Capital: "rauemi",
                Currency: "resource",
            },
            {
                id: ++idNumber,
                Capital: "rohe",
                Currency: "district",
            },
            {
                id: ++idNumber,
                Capital: "roto",
                Currency: "in, inside",
            },

            // T
            {
                id: ++idNumber,
                Capital: "taha",
                Currency: "side",
            },
            {
                id: ++idNumber,
                Capital: "taka",
                Currency: "fall",
            },
            {
                id: ++idNumber,
                Capital: "taki whakaaro",
                Currency: "offering ideas",
            },
            {
                id: ++idNumber,
                Capital: "taku",
                Currency: "my (singular)",
            },
            {
                id: ++idNumber,
                Capital: "taku marae",
                Currency: "my cultural village",
            },
            {
                id: ++idNumber,
                Capital: "tamaiti",
                Currency: "child",
            },
            {
                id: ++idNumber,
                Capital: "tamariki",
                Currency: "children",
            },
            {
                id: ++idNumber,
                Capital: "tātou",
                Currency: "us (everyone)",
            },
            {
                id: ++idNumber,
                Capital: "tātou katoa",
                Currency: "us (everyone) all",
            },
            {
                id: ++idNumber,
                Capital: "te",
                Currency: "the",
            },
            {
                id: ++idNumber,
                Capital: "te aroha",
                Currency: "the love",
            },
            {
                id: ++idNumber,
                Capital: "te kākano",
                Currency: "the seed",
            },
            {
                id: ++idNumber,
                Capital: "te pae tawhiti",
                Currency: "the far off destination",
            },
            {
                id: ++idNumber,
                Capital: "tēnā",
                Currency: "that (near you)",
            },
            {
                id: ++idNumber,
                Capital: "tēnei",
                Currency: "this (near me)",
            },
            {
                id: ++idNumber,
                Capital: "tika",
                Currency: "correct, right",
            },
            {
                id: ++idNumber,
                Capital: "timatanga",
                Currency: "beginning",
            },
            {
                id: ++idNumber,
                Capital: "tipu",
                Currency: "grow",
            },
            {
                id: ++idNumber,
                Capital: "tīpuna",
                Currency: "ancestors",
            },
            {
                id: ++idNumber,
                Capital: "tira",
                Currency: "group",
            },
            {
                id: ++idNumber,
                Capital: "toru",
                Currency: "three",
            },
            {
                id: ++idNumber,
                Capital: "tū",
                Currency: "stand, stop",
            },
            {
                id: ++idNumber,
                Capital: "Tū tira mai ngā iwi",
                Currency: "stand together all peoples",
            },

            // W
            {
                id: ++idNumber,
                Capital: "waiata",
                Currency: "song, sing",
            },
            {
                id: ++idNumber,
                Capital: "waka",
                Currency: "canoe, vehicle",
            },
            {
                id: ++idNumber,
                Capital: "whā",
                Currency: "four",
            },
            {
                id: ++idNumber,
                Capital: "whai rawa",
                Currency: "business person, entrepreneur",
            },
            {
                id: ++idNumber,
                Capital: "whaiāipo",
                Currency: "sweetheart",
            },
            {
                id: ++idNumber,
                Capital: "whakahua",
                Currency: "pronounce",
            },
            {
                id: ++idNumber,
                Capital: "whakahua tika",
                Currency: "pronounce correctly",
            },
            {
                id: ++idNumber,
                Capital: "whakapapa",
                Currency: "lineage",
            },
            {
                id: ++idNumber,
                Capital: "whānau",
                Currency: "family",
            },
        ],
    mihi:
        [
            // GreetingsTo
            {
                id: ++idNumber,
                Capital: 'Tēnā koe',
                Currency: "Greetings to you (singular)",
            },
            {
                id: ++idNumber,
                Capital: 'Tēnā kōrua',
                Currency: "Greetings to you both (two people)",
            },
            {
                id: ++idNumber,
                Capital: 'Tēnā koutou',
                Currency: "Greetings to you (3 people)",
            },
            {
                id: ++idNumber,
                Capital: 'Tēnā koutou katoa',
                Currency: "Greetings to you all",
            },
            {
                id: ++idNumber,
                Capital: 'Tēnā tātou',
                Currency: "Greetings to us",
            },
            {
                id: ++idNumber,
                Capital: 'Tēnā tātou katoa',
                Currency: "Greetings to us all",
            },
            // GreetingsToCont
            {
                id: ++idNumber,
                Capital: 'e hoa',
                Currency: "my friend",
            },
            {
                id: ++idNumber,
                Capital: 'e hoa mā',
                Currency: "my friends",
            },
            {
                id: ++idNumber,
                Capital: 'e te whānau',
                Currency: "my family",
            },
            {
                id: ++idNumber,
                Capital: 'e te manuhiri',
                Currency: "my visitor/s",
            },
            {
                id: ++idNumber,
                Capital: 'e aku rangatira',
                Currency: "my chiefs, superiors",
            },
            {
                id: ++idNumber,
                Capital: 'e te hau kāinga',
                Currency: "to our hosts",
            },
            // WhoHave
            {
                id: ++idNumber,
                Capital: 'kua tae mai nei',
                Currency: "who have arrived here",
            },
            {
                id: ++idNumber,
                Capital: 'kua huihui mai nei',
                Currency: "who have gathered here",
            },
            {
                id: ++idNumber,
                Capital: 'kua haere mai nei',
                Currency: "who have come here",
            },
            {
                id: ++idNumber,
                Capital: 'kua eke mai nei',
                Currency: "who have arrived upon",
            },
            {
                id: ++idNumber,
                Capital: 'kua pōwhiri mai nei',
                Currency: "who have welcomed me/us",
            },
            {
                id: ++idNumber,
                Capital: 'kua karanga mai nei',
                Currency: "who have called to me/us",
            },
            // To
            {
                id: ++idNumber,
                Capital: 'ki tēnei marae',
                Currency: "to this marae",
            },
            {
                id: ++idNumber,
                Capital: 'ki tēnei whare',
                Currency: "to this house/building",
            },
            {
                id: ++idNumber,
                Capital: 'ki tēnei wāhi',
                Currency: "to this place",
            },
            {
                id: ++idNumber,
                Capital: 'ki tēnei hui',
                Currency: "to this meeting",
            },
            {
                id: ++idNumber,
                Capital: 'ki tēnei whenua',
                Currency: "to this land/country",
            },
            {
                id: ++idNumber,
                Capital: 'ki Aotearoa nei',
                Currency: "to New Zealand",
            },
            // ToB
            {
                id: ++idNumber,
                Capital: 'ki te whakarite',
                Currency: "to determine",
            },
            {
                id: ++idNumber,
                Capital: 'ki te kawe mai',
                Currency: "to bring",
            },
            {
                id: ++idNumber,
                Capital: 'ki te kōrero',
                Currency: "to discuss",
            },
            {
                id: ++idNumber,
                Capital: 'ki te wānanga',
                Currency: "to solve",
            },
            {
                id: ++idNumber,
                Capital: 'ki te whāriki',
                Currency: "to lay down",
            },
            {
                id: ++idNumber,
                Capital: 'ki te rongo',
                Currency: "to hear",
            },
            // I
            {
                id: ++idNumber,
                Capital: 'i te aroha',
                Currency: "the love",
            },
            {
                id: ++idNumber,
                Capital: 'i ngā whakaaro',
                Currency: "the ideas",
            },
            {
                id: ++idNumber,
                Capital: 'i ngā take o te wā',
                Currency: "the issues of the day",
            },
            {
                id: ++idNumber,
                Capital: 'i te kaupapa o te rā',
                Currency: "today's purpose",
            },
            {
                id: ++idNumber,
                Capital: 'i ngā uaua',
                Currency: "the challenges",
            },
            {
                id: ++idNumber,
                Capital: 'i ngā pātai',
                Currency: "the queries",
            },
            {
                id: ++idNumber,
                Capital: 'i te kōrero',
                Currency: "the discussions",
            },
            // Therefore
            {
                id: ++idNumber,
                Capital: 'e hoa',
                Currency: "my friend",
            },
            {
                id: ++idNumber,
                Capital: 'e hoa mā',
                Currency: "my friends",
            },
            {
                id: ++idNumber,
                Capital: 'e te whānau',
                Currency: "my family",
            },
            {
                id: ++idNumber,
                Capital: 'e te manuhiri',
                Currency: "my visitor's",
            },
            {
                id: ++idNumber,
                Capital: 'e aku rangatira',
                Currency: "my chiefs, superiors",
            },
            // {
            //     id: ++idNumber,
            //     Capital: 'e ngā mana',
            //     Currency: "important persons",
            // },
            // Saying
            {
                id: ++idNumber,
                Capital: 'E kiia nei te kōrero He aha te mea nui o tēnei ao, he tangata.',
                Currency: "The saying goes What is the most important thing in this world, it is people",
            },
            {
                id: ++idNumber,
                Capital: "E kiia nei te kōrero Mā pango mā whero ka oti te mahi",
                Currency: "The saying goes Through everyone’s contribution we can achieve anything",
            },
            {
                id: ++idNumber,
                Capital: "E kiia nei te kōrero He wake eke noa",
                Currency: "The saying goes We are all in this together",
            },
            {
                id: ++idNumber,
                Capital: "E kiia nei te kōrero Tūwhitia te hopo",
                Currency: "The saying goes Face your fears and go with it",
            },
            {
                id: ++idNumber,
                Capital: "E kiia nei te kōrero Manuka takato, hikitia ake",
                Currency: "The saying goes The challenge laid down, pick it up",
            },
            // Great
            {
                id: ++idNumber,
                Capital: 'Nā konā, kei a koe',
                Currency: "As such to you (singular)",
            },
            {
                id: ++idNumber,
                Capital: "Nā konā, kei a kōrua",
                Currency: "As such to you both",
            },
            {
                id: ++idNumber,
                Capital: "Nā konā, kei a koutou",
                Currency: "As such to you (you and your friends)",
            },
            {
                id: ++idNumber,
                Capital: "Nā konā, kei a ahau",
                Currency: "As such I (singular)",
            },
            {
                id: ++idNumber,
                Capital: "Nā konā, kei a mātou",
                Currency: "As such we (me and my friends)",
            },
            {
                id: ++idNumber,
                Capital: "Nā konā, kei a tātou",
                Currency: "As such all of us (all inclusive)",
            },
            // Mo
            {
                id: ++idNumber,
                Capital: 'mō te kaha',
                Currency: "strength",
            },
            {
                id: ++idNumber,
                Capital: "mō te māia",
                Currency: "willingness",
            },
            {
                id: ++idNumber,
                Capital: "mō te piri pono",
                Currency: "loyalty",
            },
            {
                id: ++idNumber,
                Capital: "mō te manawanui",
                Currency: "dedication",
            },
            {
                id: ++idNumber,
                Capital: "mō te kai ngākau",
                Currency: "interest",
            },
            {
                id: ++idNumber,
                Capital: "mō te ngana",
                Currency: "resilience",
            },
            // Kite
            {
                id: ++idNumber,
                Capital: 'Ki te āwhina',
                Currency: "to support",
            },
            {
                id: ++idNumber,
                Capital: "Ki te hāpai",
                Currency: "to uplift",
            },
            {
                id: ++idNumber,
                Capital: "Ki te tautoko",
                Currency: "to support",
            },
            {
                id: ++idNumber,
                Capital: "Ki te whakamārama",
                Currency: "to explain",
            },
            {
                id: ++idNumber,
                Capital: "Ki te whakatikatika",
                Currency: "to solve",
            },
            {
                id: ++idNumber,
                Capital: "Ki te kōrerorero",
                Currency: "to discuss",
            },
            // These
            {
                id: ++idNumber,
                Capital: 'i ngā kōrero',
                Currency: "this/these discussions",
            },
            {
                id: ++idNumber,
                Capital: "i ngā tikanga",
                Currency: "this/these customs",
            },
            {
                id: ++idNumber,
                Capital: "i ngā āhuatanga",
                Currency: "this/these matters",
            },
            {
                id: ++idNumber,
                Capital: "i ngā kaupapa",
                Currency: "this/these topics",
            },
            {
                id: ++idNumber,
                Capital: "i ngā take",
                Currency: "this/these issues",
            },
            {
                id: ++idNumber,
                Capital: "i ngā uaua",
                Currency: "this/these challenges",
            },
            // Concerning
            {
                id: ++idNumber,
                Capital: 'e pā ana ki tēnei marae',
                Currency: "concerning this marae",
            },
            {
                id: ++idNumber,
                Capital: "e pā ana ki tēnei whare",
                Currency: "concerning this house/building",
            },
            {
                id: ++idNumber,
                Capital: "e pā ana ki tēnei wāhi",
                Currency: "concerning this place",
            },
            {
                id: ++idNumber,
                Capital: "e pā ana ki tēnei hui",
                Currency: "concerning this meeting",
            },
            {
                id: ++idNumber,
                Capital: "e pā ana ki tēnei whakaminenga",
                Currency: "concerning this assembly",
            },
            {
                id: ++idNumber,
                Capital: "e pā ana ki Aotearoa nei",
                Currency: "concerning New Zealand",
            },
            {
                id: ++idNumber,
                Capital: "e pā ana ki tēnei āhuatanga",
                Currency: "concerning this situation",
            },
            {
                id: ++idNumber,
                Capital: "e pā ana ki tēnei kaupapa",
                Currency: "concerning this topic",
            },
            // Therefore
            {
                id: ++idNumber,
                Capital: 'nō reira e hoa',
                Currency: "therefore my friend",
            },
            {
                id: ++idNumber,
                Capital: 'nō reira e hoa mā',
                Currency: "therefore my friends",
            },
            {
                id: ++idNumber,
                Capital: 'nō reira e te whānau',
                Currency: "therefore my family",
            },
            {
                id: ++idNumber,
                Capital: 'nō reira e te manuhiri',
                Currency: "therefore my visitor's",
            },
            {
                id: ++idNumber,
                Capital: 'nō reira e aku rangatira',
                Currency: "therefore my chiefs, superiors",
            },
            {
                id: ++idNumber,
                Capital: 'nō reira e ngā mana',
                Currency: "therefore important persons",
            },
        ],
    pepeha:
        [
            // is Maori
            {
                id: ++idNumber,
                Capital: 'Ko ... te maunga',
                Currency: 'My mountain is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... te awa',
                Currency: 'My river is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... te roto',
                Currency: 'My lake is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... te moana',
                Currency: 'My sea is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... taku marae',
                Currency: 'My marae is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... taku waka',
                Currency: 'My canoe is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... taku iwi',
                Currency: 'My tribe is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... taku hapū',
                Currency: 'my sub-tribe is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Nō ... ahau',
                Currency: 'I am from...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... taku whānau',
                Currency: 'My family is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... taku ingoa',
                Currency: 'My name is ...',
            },
            {
                id: ++idNumber,
                Capital: 'I whānau mai ahau i ...',
                Currency: 'I was born in ...',
            },
            {
                id: ++idNumber,
                Capital: 'E noho ana ahau i ...',
                Currency: 'I live in ...',
            },
            {
                id: ++idNumber,
                Capital: 'He ... taku mahi',
                Currency: 'My job is ...',
            },
            // Is Not Maori
            {
                id: ++idNumber,
                Capital: 'Nō ... aku tīpuna',
                Currency: 'My ancestors hail from ...',
            },

            {
                id: ++idNumber,
                Capital: 'Ko ... taku whānau',
                Currency: 'My family is ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ko ... ahau',
                Currency: 'I am ...',
            },
            {
                id: ++idNumber,
                Capital: 'I tipu ahau i te maru o te maunga o ...',
                Currency: 'I was raised under the mantle of the mountain of ...',
            },
            {
                id: ++idNumber,
                Capital: 'I tipu ahau i te maru o te puke o ...',
                Currency: 'I was raised under the mantle of the hill of ...',
            },
            {
                id: ++idNumber,
                Capital: 'I te taha o te moana o ...',
                Currency: 'Next to the ocean of ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ka noho ahau i te maru o te maunga o ...',
                Currency: 'I live under the mantle of the mountain of ...',
            },
            {
                id: ++idNumber,
                Capital: 'Ka noho ahau i te maru o te puke o ...',
                Currency: 'I live under the mantle of the hill of ...',
            },
            {
                id: ++idNumber,
                Capital: 'I te taha o te awa o ...',
                Currency: 'Next to the river of ...',
            },
            {
                id: ++idNumber,
                Capital: 'I te taha o te roto o ...',
                Currency: 'Next to the lake of ...',
            },
            {
                id: ++idNumber,
                Capital: 'I te rohe ā iwi o ...',
                Currency: 'In the tribal area of ...',
            },
        ],
    waiata:
        [
            {
                lyricOne: [
                    {
                        id: ++idNumber,
                        Capital: "Purea nei e te hau",
                        Currency: "Restored by the wind",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Horoia e te ua",
                        Currency: "washed by the rain",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Whitiwhitia e te rā",
                        Currency: "and transformed by the sun,",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Mahea ake ngā poraruraru",
                        Currency: "all doubts are swept away",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Makere ana ngā here.",
                        Currency: "and all restraints are cast down.",
                    },
                    {
                        id: ++idNumber,
                        Capital: "E rere wairua, e rere",
                        Currency: "Fly O free spirit, fly",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ki ngā ao o te rangi",
                        Currency: "to the clouds in the heavens,",
                    },
                ],
                lyricThree: [
                    {
                        id: ++idNumber,
                        Capital: "Te aroha",
                        Currency: "Love",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Te whakapono",
                        Currency: "Faith",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Me te rangimārie",
                        Currency: "and Peace",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tātou tātou e",
                        Currency: "we are united",
                    },
                ],
                lyricFour: [
                    {
                        id: ++idNumber,
                        Capital: "Kia tapatahi",
                        Currency: "Let us unite",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia kotahi rā",
                        Currency: "Let us be one",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tātou tātou e",
                        Currency: "We are united",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tū tira mai ngā iwi (auē!)",
                        Currency: "Stand together everyone (alas!)",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Whāia te māramatanga",
                        Currency: "Seek understanding",
                    },
                    {
                        id: ++idNumber,
                        Capital: "me te aroha e ngā iwi",
                        Currency: "and also love everyone",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia kotahi rā",
                        Currency: "Let us be one",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Puritia kia mau!",
                        Currency: "Hold fast and strong!",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kss hi auē hī!",
                        Currency: "It is done!",
                    },
                ],
                lyricFive: [
                    {
                        id: ++idNumber,
                        Capital: "Ka mate ahau",
                        Currency: "I would die",
                    },
                    {
                        id: ++idNumber,
                        Capital: "I te aroha e",
                        Currency: "of love for you",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Pōkarekare ana",
                        Currency: "Agitated",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ngā wai o Waiapu",
                        Currency: "are the waters of Waiapu",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Whiti atu koe hine",
                        Currency: "when you cross over my dearest",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Mārino ana e",
                        Currency: "they will become calm",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Hoki mai rā",
                        Currency: "please come back",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ka mate au",
                        Currency: "I would die",
                    },
                    {
                        id: ++idNumber,
                        Capital: "i te aroha e",
                        Currency: "of love for you",
                    },
                    {
                        id: ++idNumber,
                        Capital: "e hine e",
                        Currency: "oh dear one",
                    },
                ],
                lyricSix: [
                    {
                        id: ++idNumber,
                        Capital: "Ringaringa pakia, waewae takahia",
                        Currency: "Hands slapping, feet stomping",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia kino nei hoki!",
                        Currency: "Bad to the bone!",
                    },
                    {
                        id: ++idNumber,
                        Capital: "E kino nei hoki!",
                        Currency: "Bad to the bone indeed!",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ka mate, ka mate!",
                        Currency: "Tis death, tis death!",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ka ora, ka ora",
                        Currency: "Tis life, tis life!",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tēnei te tangata pūruhuruhuru",
                        Currency: "What is this hairy thing",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Nāna i tiki mai whakawhiti te rā?",
                        Currency: "That has caused the sun to shine?",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Hupāne, kaupāne!",
                        Currency: "Up this step, up that step",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Whiti te rā!",
                        Currency: "To sun shines brightly!",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Hi!",
                        Currency: "It is done!",
                    },
                ],
                lyricSeven: [
                    {
                        id: ++idNumber,
                        Capital: "Tērā te haeata",
                        Currency: "Towards the new dawn",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia manawanui",
                        Currency: "Be courageous",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Kia manawaroa",
                        Currency: "Be resilient",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ki te hoe nei",
                        Currency: "Take hold the paddle",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Whakamānu",
                        Currency: "Launch",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Eke Tangaroa",
                        Currency: "Master the journey",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tōkihi tōtika",
                        Currency: "Travel straight and with purpose",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Ki te pae o Te Rawenga",
                        Currency: "And venture beyond the horizon",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Auē e rongo nei i",
                        Currency: "I feel the wairua",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Aku tīpuna",
                        Currency: "of those gone before us",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Mana tuku iho",
                        Currency: "Who continue to inspire us",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Mana Motuhake",
                        Currency: "To live meaningful lives",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tūā taku ara e",
                        Currency: "Clear my path",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Tūātūā",
                        Currency: "Clear my path",
                    },
                    {
                        id: ++idNumber,
                        Capital: "Toi Te Rawenga",
                        Currency: "Hold fast to your greatness",
                    },
                ],
            },
        ],
    whakapapa:
        [
            {
                id: ++idNumber,
                Capital: 'I te taha o taku māmā, ko ... taku koroua',
                Currency: "On my mother's side, my grandfather is ...",
            },
            {
                id: ++idNumber,
                Capital: 'I te taha o taku māmā, ko ... taku kuia',
                Currency: "On my mother's side, my grandmother is ...",
            },
            {
                id: ++idNumber,
                Capital: "I te taha o taku pāpā, ko ... taku koroua",
                Currency: "On my father's side, my grandfather is ...",
            },
            {
                id: ++idNumber,
                Capital: "I te taha o taku pāpā, ko ... taku kuia",
                Currency: "On my father's side, my grandmother is ...",
            },
            {
                id: ++idNumber,
                Capital: "Ko ... taku māmā",
                Currency: "My mother is ...",
            },
            {
                id: ++idNumber,
                Capital: "Ko ... taku pāpā",
                Currency: "My father is ...",
            },
            {
                id: ++idNumber,
                Capital: "Ko ... taku whaiāipo",
                Currency: "My partner is ...",
            },
            {
                id: ++idNumber,
                Capital: "Ko ... taku tamaiti",
                Currency: "... is my child",
            },
            {
                id: ++idNumber,
                Capital: "Ko ... taku mokopuna",
                Currency: "... is my grand child",
            },
        ],
}


export const karakiaData = datas.karakia
export const keteKoreroData = datas.keteKorero
export const kupuTakaData = datas.kupuTaka
export const mihiData = datas.mihi
export const pepehaData = datas.pepeha
export const waiataData = datas.waiata
export const whakapapaData = datas.whakapapa
export const kimikimikimiData =
    [
        ...karakiaData[0].lyricOne,
        ...karakiaData[0].lyricTwo,
        ...karakiaData[0].lyricThree,
        ...karakiaData[0].lyricFour,
        ...karakiaData[0].lyricFive,
        ...karakiaData[0].lyricSix,
        ...karakiaData[0].lyricSeven,
        ...karakiaData[0].lyricEight,
        // ...karakiaData[0].lyricNine,
        ...keteKoreroData,
        ...kupuTakaData,
        ...mihiData,
        ...pepehaData,
        ...waiataData[0].lyricOne,
        ...waiataData[0].lyricThree,
        ...waiataData[0].lyricFour,
        ...waiataData[0].lyricFive,
        ...waiataData[0].lyricSix,
        ...waiataData[0].lyricSeven,
        ...whakapapaData
    ]