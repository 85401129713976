export const whereBorn = (input) => {
    return [
        `I whānau mai ahau i ${input || "..."}`,
        `I was born in ${input || "..."}`
    ]
}

export const whereLiving = (input) => {
    return [
        `E noho ana ahau i ${input || "..."}`,
        `I live in ${input || "..."}`
    ]
}

export const ancestors = (input) => {
    return [
        `Nō ${input || "..."} aku tīpuna`,
        `My ancestors hail from ${input || "..."}`
    ]
}

export const whanau = (input) => {
    return [
        `Ko ${input || "..."} taku whānau`,
        `My family is ${input || "..."}`
    ]
}

export const name = (input) => {
    return [
        `Ko ${input || "..."} ahau`,
        `I am ${input || "..."}`
    ]
}

export const mountain = (input) => {
    return [
        `I tipu ahau i te maru o te maunga o ${input || "..."}`,
        `I was raised under the mantle of the mountain of ${input || "..."}`
    ]
}

export const hill = (input) => {
    return [
        `I tipu ahau i te maru o te puke o ${input || "..."}`,
        `I was raised under the mantle of the hill of ${input || "..."}`
    ]
}

export const sea = (input) => {
    return [
        `I te taha o te moana o ${input || "..."}`,
        `Next to the ocean of ${input || "..."}`
    ]
}
export const extra1 = (input) => {
    return [
        `Ka noho ahau i te maru o te maunga o ${input || "..."}`,
        ` I live under the mantle of the mountain of ${input || "..."}`
    ]
}
export const extra2 = (input) => {
    return [
        `Ka noho ahau i te maru o te puke o ${input || "..."}`,
        ` I live under the mantle of the hill of ${input || "..."}`
    ]
}
export const extra3 = (input) => {
    return [
        `I te taha o te roto o ${input || "..."}`,
        ` Next to the lake of ${input || "..."}`
    ]
}
export const extra4 = (input) => {
    return [
        `I te taha o te awa o ${input || "..."}`,
        ` Next to the river of ${input || "..."}`
    ]
}
export const extra5 = (input) => {
    return [
        `I te taha o te moana o ${input || "..."}`,
        ` Next to the ocean of  ${input || "..."}`
    ]
}


export const river = (input) => {
    return [
        `I te taha o te awa o ${input || "..."}`,
        `Next to the river of ${input || "..."}`
    ]
}


export const lake = (input) => {
    return [
        `I te taha o te roto o ${input || "..."}`,
        `Next to the lake of ${input || "..."}`
    ]
}


export const tribe = (input) => {
    return [
        `I te rohe ā iwi o ${input || "..."}`,
        `In the tribal area of ${input || "..."}`
    ]
}