import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components'
import InputQuestion from '../InputQuestion';
import SectionToPrint from '../SectionToPrint'
import WhakapapaToPrintUi from './WhakapapaToPrintUi';
import WhakapapaToPrint from './WhakapapaToPrint';
import ExportToPDFButton from '../ExportToPDFButton';
import PopUpLoading from '../PopUpLoading';
import Button from '../Button';
import InputQuestionTwoChildren from './InputQuestionTwoChildren';
import InputQuestionThreeChildren from './InputQuestionThreeChildren';
import ChildrenNumber from './ChildrenNumber'
import {
    sMother,
    sFather,
    sPartner,
    sChild,
    sTwoChildren,
    sMaternalGrandFather,
    sMaternalGrandMother,
    sPaternalGrandFather,
    sPaternalGrandMother,
    sGrandChild,
    sTwoGrandChildren,
} from './WhakapapaSentences'
import GrandChildrenNumber from './GrandChildrenNumber';
import InputQuestionTwoGrandChildren from './InputQuestionTwoGrandChildren';
import InputQuestionThreeGrandChildren from './InputQuestionThreeGrandChildren';

const ContainerToPrint = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;
    align-items: center;
`;

const ContainerIn = styled.div`
    width: 100%

    @media only screen and (min-width: 768px) {
        width: 400px;
    }
`;

const ToPrintContainer = styled.div`
    width: 90%;

    @media only screen and (min-width: 768px) {
        width: 600px;
    }
`;

const WhakapapaFlow = (
    {
        inputValueMother,
        setInputStateMother,
        inputValueFather,
        setInputStateFather,
        inputValuePartner,
        setInputStatePartner,

        inputValueChild,
        setInputValueChild,
        inputValueGrandChild,
        setInputValueGrandChild,

        inputValueTwoChildren,
        setInputValueTwoChildren,
        inputValueTwoGrandChildren,
        setInputValueTwoGrandChildren,

        inputValueThreeChildren,
        setInputValueThreeChildren,
        inputValueThreeGrandChildren,
        setInputValueThreeGrandChildren,

        inputValueMaternalGrandFather,
        setInputValueMaternalGrandFather,
        inputValueMaternalGrandMother,
        setInputValueMaternalGrandMother,
        inputValuePaternalGrandFather,
        setInputValuePaternalGrandFather,
        inputValuePaternalGrandMother,
        setInputValuePaternalGrandMother,

        childrenNumber,
        setChildrenNumber,
        grandChildrenNumber,
        setGrandChildrenNumber,
    }
) => {
    let { flow } = useParams()
    let history = useHistory()
    const [toPrintTheme, setToPrintTheme] = useState(true)
    // const [childrenNumber, setChildrenNumber] = useState(true)
    const clearHistory = function () {
        setInputStateMother("")
        setInputStateFather("")
        setInputStatePartner("")
        setInputValueChild("")
        setInputValueGrandChild("")
        setInputValueMaternalGrandFather("")
        setInputValueMaternalGrandMother("")
        setInputValuePaternalGrandFather("")
        setInputValuePaternalGrandMother("")
        setInputValueChild("")
        setInputValueGrandChild("")
        setInputValueTwoChildren("")
        setInputValueTwoGrandChildren("")
        setInputValueThreeChildren("")
        setInputValueThreeGrandChildren("")
        setChildrenNumber("")
    }

    return (
        <div>
            <ContainerToPrint>
                {
                    flow === "15" &&
                    <ToPrintContainer>
                        <SectionToPrint
                            imgSrc="/svg/whakapapa-red.svg"
                            title="Whakapapa"
                            toPrintTheme={toPrintTheme}
                            toPrintUI={
                                <WhakapapaToPrintUi
                                    mother={inputValueMother}
                                    father={inputValueFather}
                                    partner={inputValuePartner}

                                    child={inputValueChild}
                                    twoChildren={inputValueTwoChildren}
                                    threeChildren={inputValueThreeChildren}

                                    grandChild={inputValueGrandChild}
                                    twoGrandChildren={inputValueTwoGrandChildren}
                                    threeGrandChildren={inputValueThreeGrandChildren}


                                    maternalGrandFather={inputValueMaternalGrandFather}
                                    maternalGrandMother={inputValueMaternalGrandMother}

                                    paternalGrandFather={inputValuePaternalGrandFather}
                                    paternalGrandMother={inputValuePaternalGrandMother}

                                    setToPrintTheme={setToPrintTheme}
                                    toPrintTheme={toPrintTheme}

                                    childrenNumber={childrenNumber}
                                    grandChildrenNumber={grandChildrenNumber}

                                />
                            }
                        />
                        <ExportToPDFButton
                            toPrint={
                                <WhakapapaToPrint
                                    mother={inputValueMother}
                                    father={inputValueFather}
                                    partner={inputValuePartner}
                                    child={inputValueChild}
                                    twoChildren={inputValueTwoChildren}
                                    threeChildren={inputValueThreeChildren}
                                    grandChild={inputValueGrandChild}
                                    twoGrandChildren={inputValueTwoGrandChildren}
                                    threeGrandChildren={inputValueThreeGrandChildren}
                                    maternalGrandFather={inputValueMaternalGrandFather}
                                    maternalGrandMother={inputValueMaternalGrandMother}

                                    paternalGrandFather={inputValuePaternalGrandFather}
                                    paternalGrandMother={inputValuePaternalGrandMother}

                                    toPrintTheme={toPrintTheme}

                                    childrenNumber={childrenNumber}
                                    grandChildrenNumber={grandChildrenNumber}
                                />
                            }
                            fileName="Whakapapa.pdf"
                        />
                        <Link to="/whakapapa/1/1" onClick={clearHistory}>
                            <Button
                                text="Start over"
                                ArgBackgroundColor="white"
                                ArgBorder="1px solid #5c9021"
                                ArgColor="#5c9021"
                                ArgHoverBackgroundColor="#F0F0F0"
                            />
                        </Link>
                    </ToPrintContainer>
                }
            </ContainerToPrint>
            {
                flow <= 14 &&
                <ContainerOut>
                    <ContainerIn>
                        {
                            flow === "1" &&
                            <InputQuestion
                                path="/whakapapa/1/2"
                                inputValue={inputValueMaternalGrandFather}
                                setInputState={setInputValueMaternalGrandFather}
                                imgSrc="/imagesss/All SVG/Grandfather-jana.svg"
                                title={sMaternalGrandFather(inputValueMaternalGrandFather)[0]}
                                text={sMaternalGrandFather(inputValueMaternalGrandFather)[1]}
                                placeholder="Insert name"
                            />
                        }

                        {
                            flow === "2" &&
                            <InputQuestion
                                path="/whakapapa/1/3"
                                inputValue={inputValueMaternalGrandMother}
                                setInputState={setInputValueMaternalGrandMother}
                                imgSrc="/imagesss/All SVG/Grandmother-Jana2.svg"
                                title={sMaternalGrandMother(inputValueMaternalGrandMother)[0]}
                                text={sMaternalGrandMother(inputValueMaternalGrandMother)[1]}
                                placeholder="Insert name"
                            />
                        }

                        {
                            flow === "3" &&
                            <InputQuestion
                                path="/whakapapa/1/4"
                                inputValue={inputValuePaternalGrandFather}
                                setInputState={setInputValuePaternalGrandFather}
                                imgSrc="/imagesss/All SVG/Grandfather-jana.svg"
                                title={sPaternalGrandFather(inputValuePaternalGrandFather)[0]}
                                text={sPaternalGrandFather(inputValuePaternalGrandFather)[1]}
                                placeholder="Insert name"
                            />
                        }

                        {
                            flow === "4" &&
                            <InputQuestion
                                path="/whakapapa/1/5"
                                inputValue={inputValuePaternalGrandMother}
                                setInputState={setInputValuePaternalGrandMother}
                                imgSrc="/imagesss/All SVG/Grandmother-Jana2.svg"
                                title={sPaternalGrandMother(inputValuePaternalGrandMother)[0]}
                                text={sPaternalGrandMother(inputValuePaternalGrandMother)[1]}
                                placeholder="Insert name"
                            />
                        }
                        {
                            flow === "5" &&
                            <InputQuestion
                                path="/whakapapa/1/6"
                                inputValue={inputValueMother}
                                setInputState={setInputStateMother}
                                imgSrc="/imagesss/All SVG/Mother-Jana.svg"
                                title={sMother(inputValueMother)[0]}
                                text={sMother(inputValueMother)[1]}
                                placeholder="Insert name"
                            />
                        }
                        {
                            flow === "6" &&
                            <InputQuestion
                                path="/whakapapa/1/7"
                                inputValue={inputValueFather}
                                setInputState={setInputStateFather}
                                imgSrc="/imagesss/All SVG/Father-Jana-02.svg"
                                title={sFather(inputValueFather)[0]}
                                text={sFather(inputValueFather)[1]}
                                placeholder="Insert name"
                            />
                        }

                        {
                            flow === "7" &&
                            <InputQuestion
                                path="/whakapapa/1/7.5"
                                inputValue={inputValuePartner}
                                setInputState={setInputStatePartner}
                                imgSrc="/imagesss/All SVG/Partner.svg"
                                title={sPartner(inputValuePartner)[0]}
                                text={sPartner(inputValuePartner)[1]}
                                placeholder="Insert name"
                            />
                        }

                        {
                            flow === "7.5" &&
                            <ChildrenNumber
                                state={childrenNumber}
                                setState={setChildrenNumber}
                            />
                        }

                        {
                            flow === "8" &&
                            <InputQuestion
                                path="/whakapapa/1/8.5"
                                inputValue={inputValueChild}
                                setInputState={setInputValueChild}
                                imgSrc="/imagesss/All SVG/1-Babe.svg"
                                title={sChild(inputValueChild)[0]}
                                text={sChild(inputValueChild)[1]}
                                placeholder="Insert name"
                            />
                        }
                        {
                            flow === "8.5" &&
                            <GrandChildrenNumber
                                state={grandChildrenNumber}
                                setState={setGrandChildrenNumber}
                            />
                        }

                        {
                            flow === "9" &&
                            <InputQuestionTwoChildren
                                path="/whakapapa/1/8.5"
                                inputValueTwoChildren={inputValueTwoChildren}
                                setInputValueTwoChildren={setInputValueTwoChildren}
                                imgSrc="/imagesss/All SVG/2-Babes.svg"
                                title={sTwoChildren(inputValueTwoChildren)[0]}
                                text={sTwoChildren(inputValueTwoChildren)[1]}
                                placeholder="Insert name"
                            />
                        }

                        {
                            flow === "10" &&
                            <InputQuestionThreeChildren
                                path="/whakapapa/1/8.5"
                                inputValueThreeChildren={inputValueThreeChildren}
                                setInputValueThreeChildren={setInputValueThreeChildren}
                                imgSrc="/imagesss/All SVG/3-Babes.svg"
                                placeholder="Insert name"
                            />
                        }
                        {
                            flow === "11" &&
                            <InputQuestion
                                path="/whakapapa/1/14"
                                inputValue={inputValueGrandChild}
                                setInputState={setInputValueGrandChild}
                                imgSrc="/imagesss/All SVG/1-Babe.svg"
                                title={sGrandChild(inputValueGrandChild)[0]}
                                text={sGrandChild(inputValueGrandChild)[1]}
                                placeholder="Insert name"
                            />
                        }

                        {
                            flow === "12" &&
                            <InputQuestionTwoGrandChildren
                                path="/whakapapa/1/14"
                                inputValueTwoGrandChildren={inputValueTwoGrandChildren}
                                setInputValueTwoGrandChildren={setInputValueTwoGrandChildren}
                                imgSrc="/imagesss/All SVG/2-Babes.svg"
                                title={sTwoGrandChildren(inputValueTwoGrandChildren)[0]}
                                text={sTwoGrandChildren(inputValueTwoGrandChildren)[1]}
                                placeholder="Insert name"
                            />
                        }

                        {
                            flow === "13" &&
                            <InputQuestionThreeGrandChildren
                                path="/whakapapa/1/14"
                                inputValueThreeGrandChildren={inputValueThreeGrandChildren}
                                setInputValueThreeGrandChildren={setInputValueThreeGrandChildren}
                                imgSrc="/imagesss/All SVG/3-Babes.svg"
                                placeholder="Insert name"
                            />
                        }

                        {
                            flow === "14" &&
                            <div onClick={setTimeout(function () { history.push("/whakapapa/1/15") }, 3000)}>
                                <PopUpLoading word="whakapapa" />
                            </div>
                        }
                    </ContainerIn>
                </ContainerOut>
            }

        </div>
    )
}

export default WhakapapaFlow;