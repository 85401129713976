import React, { useState, useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../components/Button';
import Image from '../components/Image'
import SectionIntro from '../components/SectionIntro';
import SectionIntroHandlePopUp from '../components/SectionIntroHandlePopUp';
import PopUpCard from '../components/PopUpCard';
import MihimihiFlow from '../components/mihimihi/MihimihiFlow'

const ContainerAnima = styled.div`
    background-color: #435A36;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: scroll;
    background-blend-mode: screen;
    // opacity: 0.5;
    background-image: url(/img/bg-1.png);
    background-position: left;
    background-size: cover;
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 75px 0 25px;
    align-items: center;
    @media only screen and (min-width: 1000px) {
        padding: 125px 0 25px;
    }
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const MihimihiContainer = styled.div`
    padding: 50px 0;
    // background-image: url(/img/bg-2.png);
    background-position: left;
    background-blend-mode: screen;
    // background-color: #435A36;
    background-size: cover;
`;

const BodyContainer = styled.div`
    background-color: #435A36;
    background-image: url(/img/bg-3.png);
    background-blend-mode: screen;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    // position: fixed;
    // top: 0;
    width: 100%;
    // padding: 4rem 0;
    // overflow-y: auto;
`;


const IntroContainer = styled.div`
    /* Layout Properties */
    left: 543px;
    width: 560px;
    margin:auto; 
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    @media only screen and (max-width: 650px) {
        height: 100%;
        width: 80%;
    }
`;

const PopUpContent = ({ setShowPopUp }) => {
    return (
        <div>
            <h1>What is mihimihi?</h1>
            <p style={{textAlign:"justify"}}>
                You are the author of your own mihi. Simply go through each section, choose what you want
                to include, what not to,
                so your mihi suits the situation.
                When you’re finished your mihi
                will be available for you
                to view on this device,
                nō reira, toru, whā,
                KARAWHIUA!’
            </p>
            <div onClick={() => setShowPopUp(false)}>
                <Button text="Tēna, got it" />
            </div>
        </div>
    )
}

const Mihimihi = () => {
    const [showPopUp, setShowPopUp] = useState(false)
    const [toPrintTheme, setToPrintTheme] = useState(true)

    const [greetingsTo, setGreetingsTo] = useState("")
    const [greetingsToCont, setGreetingsToCont] = useState("")
    const [iamFeeling, setIamFeeling] = useState("")
    const [whoHave, setWhoHave] = useState("")
    const [to, setTo] = useState("")
    const [toB, setToB] = useState("")
    const [personalPronoun, setPersonalPronoun] = useState("")
    const [i, setI] = useState("")
    const [therefore, setTherefore] = useState("")

    const [saying, setSaying] = useState("")
    const [great, setGreat] = useState("")
    const [mo, setMo] = useState("")
    const [kite, setKite] = useState("")
    const [these, setThese] = useState("")
    const [concerning, setConcerning] = useState("")


    let { mihimihiView } = useParams()

    useEffect(() => {
        Analytics.record({
            name: "Mihimihi page visit"
        })
    }, [])

    // if (showPopUp) {
    //     return <PopUpCard
    //         setShowPopUp={setShowPopUp}
    //         cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
    //         withCloseButton={true}
    //     />
    // }

    if (mihimihiView) {
        if (mihimihiView == "0") {
            if (greetingsTo) setGreetingsTo("")
            if (greetingsToCont) setGreetingsToCont("")
            if (iamFeeling) setIamFeeling("")
            if (whoHave) setWhoHave("")
            if (to) setTo("")
            if (toB) setToB("")
            if (personalPronoun) setPersonalPronoun("")
            if (i) setI("")
            if (therefore) setTherefore("")
            if (saying) setSaying("")
            if (great) setGreat("")
            if (mo) setMo("")
            if (kite) setKite("")
            if (these) setThese("")
            if (concerning) setConcerning("")
        }
        return (
            <>
                {showPopUp &&
                    <PopUpCard
                        setShowPopUp={setShowPopUp}
                        cardContent={<PopUpContent setShowPopUp={setShowPopUp} />}
                        withCloseButton={true}
                    />
                }
                <ContainerAnima>
                    <Container>
                        <ContentContainer>
                            <MihimihiContainer>
                                {
                                    mihimihiView === "0" &&
                                    <IntroContainer>
                                        <Image image="/img/mihi.jpg" />
                                        < SectionIntro
                                            imgSrc="/imagesss/All SVG/Mihi.svg"
                                            title="Your mihi"
                                            toPrintTheme={toPrintTheme}
                                            intro={
                                                <SectionIntroHandlePopUp
                                                    setShowPopUp={setShowPopUp}
                                                    text="Mihi is a speech of greeting, a speech acknowledging a person or a group. Following on from pepeha, it continues to build a relationship with people"
                                                />
                                            }
                                            btText="Karawhiua - Let's start"
                                            path="/mihimihi/1/0.5"

                                        />
                                    </IntroContainer>
                                }
                            </MihimihiContainer>
                        </ContentContainer>
                    </Container>
                </ContainerAnima>

                <BodyContainer>

                    {
                        mihimihiView === "1" &&
                        <MihimihiFlow
                            inputValueGreetingsTo={greetingsTo}
                            setInputStateGreetingsTo={setGreetingsTo}

                            inputValueShowPopUp={showPopUp}
                            setInputStateShowPopUp={setShowPopUp}

                            inputValueToPrintTheme={toPrintTheme}
                            setInputStateToPrintTheme={setToPrintTheme}

                            inputValueGreetingsToCont={greetingsToCont}
                            setInputStateGreetingsToCont={setGreetingsToCont}

                            inputValueIamFeeling={iamFeeling}
                            setInputStateIamFeeling={setIamFeeling}

                            inputValueWhoHave={whoHave}
                            setInputStateWhoHave={setWhoHave}

                            inputValueTo={to}
                            setInputStateTo={setTo}

                            inputValueToB={toB}
                            setInputStateToB={setToB}

                            inputValuePersonalPronoun={personalPronoun}
                            setInputStatePersonalPronoun={setPersonalPronoun}

                            inputValueI={i}
                            setInputStateI={setI}

                            inputValueTherefore={therefore}
                            setInputStateTherefore={setTherefore}


                            inputValueSaying={saying}
                            setInputStateSaying={setSaying}

                            inputValueGreat={great}
                            setInputStateGreat={setGreat}

                            inputValueMo={mo}
                            setInputStateMo={setMo}

                            inputValueKite={kite}
                            setInputStateKite={setKite}

                            inputValueThese={these}
                            setInputStateThese={setThese}

                            inputValueConcerning={concerning}
                            setInputStateConcerning={setConcerning}
                        />
                    }
                </BodyContainer>

            </>
        )
    }

}

export default Mihimihi;