import React, { createContext, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components'
import ButtonQuestion from '../ButtonQuestion';
import SectionToPrint from '../SectionToPrint'
import MihimihiToPrintUi from './MihimihiToPrintUi';
import MihimihiToPrint from './MihimihiToPrint';
import ExportToPDFButton from '../ExportToPDFButton';
import PopUpLoading from '../PopUpLoading';
import Button from '../Button';
import BasicAdvanced from '../BasicAdvanced';
// Buttons set
import GreetingsTo from './buttons-set/GreetingsTo';
import GreetingsToCont from './buttons-set/GreetingsToCont';
import I from './buttons-set/I';
// import IamFeeling from './buttons-set/IamFeeling';
// import PersonalPronoun from './buttons-set/PersonalPronoun';
import Therefore from './buttons-set/Therefore';
import To from './buttons-set/To';
import ToB from './buttons-set/ToB';
import WhoHave from './buttons-set/WhoHave';

import Saying from './buttons-set/Saying';
import Great from './buttons-set/Great';
import Mo from './buttons-set/Mo';
import Concerning from './buttons-set/Concerning';
import Kite from './buttons-set/Kite';
import These from './buttons-set/These';
import stopAudio from './../../utils/stop-audio';

export const MaoriAudioContext = createContext({
    updateAudio: () => { },
    setInsideTextIsAudioPlaying: () => { }
});
const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
    height: 100%;
    width: 560px;
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    padding: 2rem 1rem;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin: auto auto 30px;
    @media only screen and (max-width: 650px) {
        width: 80%;
    }
    @media only screen and (min-width: 850px) {
        width: 40%;
    }

`;
const ContainerToPrint = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ContainerIn = styled.div`
    width: 100%

    @media only screen and (min-width: 768px) {
        width: 400px;
    }
`;

const ToPrintContainer = styled.div`
    margin: auto;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
`;

const MihimihiFlow = (
    {
        inputValueGreetingsTo,
        setInputStateGreetingsTo,
        inputValueGreetingsToCont,
        setInputStateGreetingsToCont,
        inputValueI,
        setInputStateI,

        inputValueIamFeeling,
        setInputStateIamFeeling,
        inputValuePersonalPronoun,
        setInputStatePersonalPronoun,

        inputValueTherefore,
        setInputStateTherefore,
        inputValueTo,
        setInputStateTo,
        inputValueToB,
        setInputStateToB,
        inputValueWhoHave,
        setInputStateWhoHave,

        inputValueSaying,
        setInputStateSaying,

        inputValueGreat,
        setInputStateGreat,

        inputValueMo,
        setInputStateMo,

        inputValueKite,
        setInputStateKite,

        inputValueThese,
        setInputStateThese,

        inputValueConcerning,
        setInputStateConcerning,
    }
) => {
    const [audio, updateAudio] = useState(null);
    const [autoPlayAudio, setAutoPlayAudio] = useState(false)
    const [isAutoPlayAudioPlaying, setIsAutoPlayAudioPlaying] = useState(false)
    const [insideTextIsAudioPlaying, setInsideTextIsAudioPlaying] = useState(false)

    let { flow } = useParams()
    let history = useHistory()
    const [toPrintTheme, setToPrintTheme] = useState(true)
    const [isBasic, setIsBasic] = useState("error")
    const clearHistory = function () {
        setInputStateGreetingsTo("")
        setInputStateGreetingsToCont("")
        setInputStateI("")

        setInputStateIamFeeling("")
        setInputStatePersonalPronoun("")

        setInputStateTherefore("")
        setInputStateTo("")
        setInputStateToB("")
        setInputStateWhoHave("")

        setInputStateSaying("")
        setInputStateGreat("")
        setInputStateMo("")
        setInputStateKite("")
        setInputStateThese("")
        setInputStateConcerning("")
        setIsAutoPlayAudioPlaying(false)
        setInsideTextIsAudioPlaying(false)
        if (audio) {
            stopAudio(audio)
            updateAudio(null)
        }
    }

    return (
        <div>
            <MaoriAudioContext.Provider value={{ audio, updateAudio, isAutoPlayAudioPlaying, setIsAutoPlayAudioPlaying, insideTextIsAudioPlaying, setInsideTextIsAudioPlaying }}>
                <ContainerToPrint>
                    {
                        flow === "18" &&
                        <IntroContainer>
                            <ToPrintContainer>
                                <SectionToPrint
                                    imgSrc="/imagesss/All SVG/Mihi.svg"
                                    title="Mihi"
                                    toPrintTheme={toPrintTheme}
                                    toPrintUI={
                                        <MihimihiToPrintUi
                                            autoPlayAudio={autoPlayAudio}
                                            setAutoPlayAudio={setAutoPlayAudio}
                                            greetingsTo={inputValueGreetingsTo}
                                            greetingsToCont={inputValueGreetingsToCont}
                                            i={inputValueI}
                                            iamFeeling={inputValueIamFeeling}
                                            personalPronoun={inputValuePersonalPronoun}
                                            therefore={inputValueTherefore}
                                            to={inputValueTo}
                                            toB={inputValueToB}
                                            whoHave={inputValueWhoHave}
                                            saying={inputValueSaying}
                                            great={inputValueGreat}
                                            mo={inputValueMo}
                                            kite={inputValueKite}
                                            these={inputValueThese}
                                            concerning={inputValueConcerning}
                                            setToPrintTheme={setToPrintTheme}
                                            toPrintTheme={toPrintTheme}
                                        />
                                    }
                                />
                                <ExportToPDFButton
                                    toPrint={
                                        <MihimihiToPrint
                                            greetingsTo={inputValueGreetingsTo}
                                            greetingsToCont={inputValueGreetingsToCont}
                                            i={inputValueI}
                                            iamFeeling={inputValueIamFeeling}
                                            personalPronoun={inputValuePersonalPronoun}
                                            therefore={inputValueTherefore}
                                            to={inputValueTo}
                                            toB={inputValueToB}
                                            saying={inputValueSaying}
                                            great={inputValueGreat}
                                            mo={inputValueMo}
                                            kite={inputValueKite}
                                            these={inputValueThese}
                                            concerning={inputValueConcerning}
                                            whoHave={inputValueWhoHave}
                                            toPrintTheme={toPrintTheme}
                                        />
                                    }
                                    fileName="Mihi.pdf"
                                />
                                <Button
                                    text={!autoPlayAudio ? 'Play' : 'Stop'}
                                    disabled={insideTextIsAudioPlaying}
                                    // disabled={true}
                                    ArgBackgroundColor="#DEB22F"
                                    ArgBorder="1px solid #5c9021"
                                    ArgColor="#5c9021"
                                    ArgHoverBackgroundColor="#F0F0F0"
                                    onClick={(event) => {
                                        event.persist();
                                        setAutoPlayAudio(currentState => !currentState)
                                    }}
                                />
                                <Link onClick={clearHistory} to="/mihimihi/1/0.5">
                                    <Button
                                        text="Start over"
                                        ArgBackgroundColor="white"
                                        ArgBorder="1px solid #5c9021"
                                        ArgColor="#5c9021"
                                        ArgHoverBackgroundColor="#F0F0F0"
                                    />
                                </Link>
                            </ToPrintContainer>
                        </IntroContainer>
                    }
                </ContainerToPrint>
                {
                    flow < 18 &&
                    <ContainerOut>
                        <ContainerIn>

                            {
                                flow === "0.5" &&
                                <BasicAdvanced
                                    setState={setIsBasic}
                                    state={isBasic}
                                    image="/imagesss/All SVG/Mihi.svg"
                                    basic="Basic mihi"
                                    advanced="Advanced mihi"
                                    path="/mihimihi/1/1"
                                    title="Create my mihi"
                                />
                            }

                            {
                                flow === "1" &&
                                <ButtonQuestion
                                    path="/mihimihi/1/2"
                                    buttons={<GreetingsTo setInputState={setInputStateGreetingsTo} inputValue={inputValueGreetingsTo} />}
                                    title={`Tēnā ${inputValueGreetingsTo[0] || "..."}`}
                                    text={`Greetings to ${inputValueGreetingsTo[1] || "..."}`}
                                />
                            }

                            {
                                flow === "2" &&
                                <ButtonQuestion
                                    path="/mihimihi/1/4"
                                    buttons={<GreetingsToCont setInputState={setInputStateGreetingsToCont} inputValue={inputValueGreetingsToCont} />}
                                    title={`${inputValueGreetingsToCont[0] || "..."}`}
                                    text={`${inputValueGreetingsToCont[1] || "..."}`}
                                />
                            }

                            {/* {
                            flow === "3" &&
                            <ButtonQuestion
                                path="/mihimihi/1/4"
                                buttons={<IamFeeling setInputState={setInputStateIamFeeling} inputValue={inputValueIamFeeling} />}
                                title={`Keite te ${inputValueIamFeeling[0] || "..."} ahau`}
                                text={`I am feeling ${inputValueIamFeeling[1] || "..."}`}
                            />
                        } */}

                            {
                                flow === "4" &&
                                <ButtonQuestion
                                    path="/mihimihi/1/5"
                                    buttons={<WhoHave setInputState={setInputStateWhoHave} inputValue={inputValueWhoHave} />}
                                    title={`kua ${inputValueWhoHave[0] || "..."}`}
                                    text={`who have ${inputValueWhoHave[1] || "..."}`}
                                />
                            }

                            {
                                flow === "5" &&
                                <ButtonQuestion
                                    path="/mihimihi/1/6"
                                    buttons={<To setInputState={setInputStateTo} inputValue={inputValueTo} />}
                                    title={`ki ${inputValueTo[0] || "..."}`}
                                    text={`to ${inputValueTo[1] || "..."}`}
                                />
                            }

                            {
                                flow === "6" &&
                                <ButtonQuestion
                                    path="/mihimihi/1/8"
                                    buttons={<ToB setInputState={setInputStateToB} inputValue={inputValueToB} />}
                                    title={`ki te ${inputValueToB[0] || "..."}`}
                                    text={`to ${inputValueToB[1] || "..."}`}
                                />
                            }
                            {/* {
                            flow === "7" &&
                            <ButtonQuestion
                                path="/mihimihi/1/8"
                                buttons={<PersonalPronoun setInputState={setInputStatePersonalPronoun} inputValue={inputValuePersonalPronoun} />}
                                title={`Personal pronoun: ${inputValuePersonalPronoun[0] || "..."}`}
                                text={`${inputValuePersonalPronoun[1] || "..."}`}
                            />
                        } */}

                            {
                                flow === "8" &&
                                <ButtonQuestion
                                    path={isBasic === true ? "/mihimihi/1/9" : "/mihimihi/1/10"}
                                    buttons={<I setInputState={setInputStateI} inputValue={inputValueI} />}
                                    title={`i ${inputValueI[0] || "..."}`}
                                    text={`${inputValueI[1] || "..."}`}
                                />
                            }



                            {
                                flow === "9" && isBasic === true &&
                                <ButtonQuestion
                                    path="/mihimihi/1/17"
                                    buttons={<Therefore setInputState={setInputStateTherefore} inputValue={inputValueTherefore} />}
                                    title={`nō reira ${inputValueTherefore[0] || "..."}`}
                                    text={`${inputValueTherefore[1] || "..."}`}
                                />
                            }

                            {
                                isBasic === false &&
                                <div>
                                    {
                                        flow === "10" &&
                                        <ButtonQuestion
                                            path="/mihimihi/1/11"
                                            buttons={<Saying setInputState={setInputStateSaying} inputValue={inputValueSaying} />}
                                            title={`E kiia nei te kōrero`}
                                            text={`The saying goes`}
                                        />
                                    }

                                    {
                                        flow === "11" &&
                                        <ButtonQuestion
                                            path="/mihimihi/1/12"
                                            buttons={<Great setInputState={setInputStateGreat} inputValue={inputValueGreat} />}
                                            title={`Nā konā, kei a ${inputValueGreat[0] || "..."}`}
                                            text={`As such ${inputValueGreat[1] || "..."} have great`}
                                        />
                                    }

                                    {
                                        flow === "12" &&
                                        <ButtonQuestion
                                            path="/mihimihi/1/13"
                                            buttons={<Mo setInputState={setInputStateMo} inputValue={inputValueMo} />}
                                            title={`mō te ${inputValueMo[0] || "..."}`}
                                            text={` ${inputValueMo[1] || "..."}`}
                                        />
                                    }

                                    {
                                        flow === "13" &&
                                        <ButtonQuestion
                                            path="/mihimihi/1/14"
                                            buttons={<Kite setInputState={setInputStateKite} inputValue={inputValueKite} />}
                                            title={`Ki te ${inputValueKite[0] || "..."}`}
                                            text={`to ${inputValueKite[1] || "..."}`}
                                        />
                                    }

                                    {
                                        flow === "14" &&
                                        <ButtonQuestion
                                            path="/mihimihi/1/15"
                                            buttons={<These setInputState={setInputStateThese} inputValue={inputValueThese} />}
                                            title={`i ${inputValueThese[0] || "..."}`}
                                            text={`this/these ${inputValueThese[1] || "..."}`}
                                        />
                                    }

                                    {
                                        flow === "15" &&
                                        <ButtonQuestion
                                            path="/mihimihi/1/16"
                                            buttons={<Concerning setInputState={setInputStateConcerning} inputValue={inputValueConcerning} />}
                                            title={`e pā ana ki ${inputValueConcerning[0] || "..."}`}
                                            text={`concerning ${inputValueConcerning[1] || "..."}`}
                                        />
                                    }

                                    {
                                        flow === "16" &&
                                        <ButtonQuestion
                                            path="/mihimihi/1/17"
                                            buttons={<Therefore setInputState={setInputStateTherefore} inputValue={inputValueTherefore} />}
                                            title={`nō reira ${inputValueTherefore[0] || "..."}`}
                                            text={`therefore ${inputValueTherefore[1] || "..."}`}
                                        />
                                    }

                                    {/* {
                                    flow === "16" &&
                                    <ButtonQuestion
                                        path="/mihimihi/1/17"
                                        buttons={<I setInputState={setInputStateI} inputValue={inputValueI} />}
                                        title={`i ${inputValueI[0] || "..."}`}
                                        text={`${inputValueI[1] || "..."}`}
                                    />
                                } */}
                                </div>
                            }

                            {
                                flow === "17" &&
                                <div onClick={setTimeout(function () { history.push("/mihimihi/1/18") }, 3000)}>
                                    <PopUpLoading word="mihimihi" />
                                </div>
                            }
                        </ContainerIn>
                    </ContainerOut>
                }
            </MaoriAudioContext.Provider>


        </div>
    )
}

export default MihimihiFlow;