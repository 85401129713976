import React from 'react'
import styled from 'styled-components'

const ImageContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Image = styled.img`
  width: auto;
  display: block;
  margin: 0 auto;
  padding: 5rem 0;
  height: 350px;
  @media screen and (max-width: 600px) {
    width: 70%;
    height: 70%;
}
`
const TableSection = styled.div`
padding: 50px 0;
align-items: center;
justify-content: center;
display: flex;

@media screen and (max-width: 1024px) {
  height: auto;
}
`
const TableContent = styled.div`
display: flex;
align-items: center;
background: #ffffff80 0% 0% no-repeat padding-box;
background-color: #ababab;
justify-content: center;
width: 70%;
margin: 0 auto;
padding: 1rem 0;

@media screen and (max-width: 900px){
  width: 100%;
}
@media screen and (max-width: 600px) {
  display: none;
}
@media screen and (max-width: 1024px) {
  width: 100%;
}
`
const Container = styled.div`
width: 100%;
`
const Table = styled.table`
width: 100%;
border-collapse: collapse;
border-radius: 0.5rem;

@media screen and (max-width: 900px) {
  width: 100%;
  margin: 0 2rem;
}
@media screen and (max-width: 600px) {
  width: 100%;
  padding: 0;
}
@media screen and (max-width: 1024px) {
  width: 100%;
  margin: 0 2rem;
}
`
const THead = styled.thead`
@media screen and (max-width: 600px) {
  display: none;
}
`
const TH = styled.th`
padding: 1rem 1.5rem;
border: 3px solid #ffffff;
box-shadow: 0 0 10px #ffffff;
align-items: center;
justify-content: center;
color: #000;
font-weight: bold;
@media screen and (max-width: 1024px) {
  padding:10px;
  font-size: 0.8rem;
}
@media screen and (max-width: 600px) {
  padding: 10px;
  font - size: 0.6rem;
}
`

const TD = styled.td`
padding: 1rem 1.5rem;
border: 3px solid #ffffff;
box-shadow: 0 0 10px #ffffff;
align-items: center;
justify-content: center;
color: #000;
font-weight: bold;

@media screen and (max-width: 1024px) {
  position: relative;
    font-size: 0.8rem;
    padding:10px;
}

@media screen and (max-width: 600px){
  display: flex;
  justify-content: start;
  position: relative;
  font - size: 0.6rem;
  padding: 10px;
}
`
const BoxesContaier = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff80 0% 0% no-repeat padding-box;
    background-color: #ababab;
    width: 100%;
    padding: 1rem 0;
    margin: 0 auto;
}
`
const BoxesRow = styled.div`
  width: 95%;
  margin: 1.5rem auto 0;
`
const Box = styled.div`
  margin: 0.2rem 0;
  padding: 0.2rem 1rem;
  border: 3px solid #ffffff;
  box-shadow: 0 0 10px #ffffff;
  align-items: center;
  justify-content: center;
`
const BoxHead = styled.p`
  color: red;
  line-height: 1.8;
  font-size: 0.8rem;
  font-weight: bolder;
`
const BoxText = styled.p`
  color: #000;
  line-height: 1.8;
  font-size: 0.8rem;
  font-weight: bolder;
`

const Framewrok = () => {
  return (
    <>
      <ImageContainer>
        <Image src="/imagesss/AUT Values.png" alt="framework image" />
      </ImageContainer>
      <TableSection>
        <Container>
          {/* desktop and tablet */}
          <TableContent>
            <Table>
              <THead>
                <TH>Māori values</TH>
                <TH>Pono</TH>
                <TH>Tika</TH>
                <TH>Aroha</TH>
              </THead>
              <tbody>
                <tr>
                  <TD>Description</TD>
                  <TD>
                    Fact, truth, reality, integrity
                  </TD>
                  <TD>
                    Act accordingly, appropriate action, ethical expression
                  </TD>
                  <TD>
                    Empathy, care, compassion, passion
                  </TD>
                </tr>
                <tr>
                  <TD>In action</TD>
                  <TD>
                    We're truthful, honest, and sincere in our interactions with others. Our integrity acknowledges and accepts there can be differing interpretations of the same fact or event. Pono helps us to navigate this space
                  </TD>
                  <TD>
                    Tika is a physical manifestation of pono. We express ourselves in accordance with what we believe is the right thing to do.
                    Ethical expression considers the consequences of all affected by the action
                  </TD>
                  <TD>
                    Aroha determines how pono and tika are expressed and received. If pono is the why, and tika is the what, aroha is the how. The desired outcome of aroha is to ensure mana and relationships are not diminished
                  </TD>
                </tr>
                <tr>
                  <TD>Desired outcome</TD>
                  <TD>
                    To communicate clearly with integrity and purpose
                  </TD>
                  <TD>
                    Any action is considered and appropriate
                  </TD>
                  <TD>
                    Mana is restored, intact or enhanced
                  </TD>
                </tr>
              </tbody>
            </Table>
          </TableContent>
          {/* mobile */}
          <BoxesContaier>
            <BoxesRow>
              <Box>
                <BoxHead>
                  Pono
                </BoxHead>
              </Box>
              <Box>
                <BoxText>
                  Fact, truth, reality, integrity
                </BoxText>
              </Box>
              <Box>
                <BoxText>
                  Act accordingly, appropriate action, ethical expression
                </BoxText>
              </Box>
              <Box>
                <BoxText>
                  Empathy, care, compassion, passion
                </BoxText>
              </Box>
            </BoxesRow>
            <BoxesRow>
              <Box>
                <BoxHead>
                  Tika
                </BoxHead>
              </Box>
              <Box>
                <BoxText>
                  We're truthful, honest, and sincere in our interactions with others. Our integrity acknowledges and accepts there can be differing interpretations of the same fact or event. Pono helps us to navigate this space
                </BoxText>
              </Box>
              <Box>
                <BoxText>
                  Tika is a physical manifestation of pono. We express ourselves in accordance with what we believe is the right thing to do.
                  Ethical expression considers the consequences of all affected by the action
                </BoxText>
              </Box>
              <Box>
                <BoxText>
                  Aroha determines how pono and tika are expressed and received. If pono is the why, and tika is the what, aroha is the how. The desired outcome of aroha is to ensure mana and relationships are not diminished
                </BoxText>
              </Box>
            </BoxesRow>
            <BoxesRow>
              <Box>
                <BoxHead>
                  Aroha
                </BoxHead>
              </Box>
              <Box>
                <BoxText>
                  To communicate clearly with integrity and purpose
                </BoxText>
              </Box>
              <Box>
                <BoxText>
                  Any action is considered and appropriate
                </BoxText>
              </Box>
              <Box>
                <BoxText>
                  Mana is restored, intact or enhanced
                </BoxText>
              </Box>
            </BoxesRow>
          </BoxesContaier>
        </Container>
      </TableSection>
    </>
  )
}

export default Framewrok