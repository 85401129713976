import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import styled from 'styled-components'
import Image from '../components/Image'
import { Link } from 'react-router-dom'


const ContentOutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 40px;
`;

const ContentInContainer = styled.div`
    text-align: center;
`;


const TextContainer = styled.div`
`;

const TextValues = styled.p`
    font-size: 12px;
    color: #AE0F0B;
    text-align: center;
    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }
`;

const WelcomeContainer = styled.div`
    /* Layout Properties */

    left: 543px;
    width: 560px;
    margin:auto auto 30px; 
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    @media only screen and (max-width: 650px) {
        width: 80%;
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;
const Button = styled.button`
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border: none;
    border-radius: 10px;  
    opacity: 1;
    color: #2D373C;
    cursor: pointer;
    margin: 5px 15px 5px 15px;
    padding: 7px 15px 7px 5px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
`;
const Icon = styled.img` 
    margin: 0 10px;      
    width: 40px;

    @media only screen and (min-width: 768px) {
        width: 55px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
const IconTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
`;
const ButtonText = styled.p`       
    font-size: 14px;
    font-weight: bold;
    color: #22170F; 

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }
`;
const ButtonTextSpan = styled.span`       
    font-size: 70%;
    font-weight: normal;
`;

const TitleContainer = styled.h1`
    font-size: 20px;
    margin: 30px 0;
    color: #AE0F0B;
    text-align: center;
    @media only screen and (min-width: 768px) {
        font-size: 30px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 30px;
    }
`;

const WelcomePageContainer = styled.div`
    background-color: #435A36;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: scroll;
    background-image: url(/img/bg-1.png);
    background-blend-mode: screen;
    background-position: left;
    background-size: cover;
`;

const Welcome = () => {

    useEffect(() => {
        Analytics.record({
            name: "Welcome page visit"
        })
    }, [])

    return (
        <WelcomePageContainer>
            <WelcomeContainer>
                <Image image="/imagesss/Welcome.jpg" />
                <TextContainer>
                    <TitleContainer>
                        Kete Kōrero
                    </TitleContainer>
                    <TextValues>
                        Tool Kit
                    </TextValues>
                </TextContainer>
                {/* <GreyBackground>
                <Framewrok src="/svg/framewrok-hero.svg" />
            </GreyBackground> */}
                <ContentOutContainer>
                    <ContentContainer>
                        <Button>
                            <Link to="/greetings">
                                <ButtonContainer>
                                    <IconTextContainer>
                                        <Icon src="/svg/Nau-mai-haere-mai-Welcome-copy.svg" />
                                        <ButtonText>Mihi Tīmatanga <ButtonTextSpan>(Greetings)</ButtonTextSpan></ButtonText>
                                    </IconTextContainer>
                                </ButtonContainer>
                            </Link>
                        </Button>

                        <Button>
                            <Link to="/farewells">
                                <ButtonContainer>
                                    <IconTextContainer>
                                        <Icon src="/svg/Nau-mai-haere-mai-Welcome-copy.svg" />
                                        <ButtonText>Mihi Mutunga  <ButtonTextSpan>(Farewells)</ButtonTextSpan></ButtonText>
                                    </IconTextContainer>
                                </ButtonContainer>
                            </Link>
                        </Button>

                        <Button>
                            <Link to="/signoffs">
                                <ButtonContainer>
                                    <IconTextContainer>
                                        <Icon src="/svg/Nau-mai-haere-mai-Welcome-copy.svg" />
                                        <ButtonText>Kupu Whakatepe <ButtonTextSpan>(Sign Offs)</ButtonTextSpan></ButtonText>
                                    </IconTextContainer>
                                </ButtonContainer>
                            </Link>
                        </Button>

                        <Button>
                            <Link to="/idioms">
                                <ButtonContainer>
                                    <IconTextContainer>
                                        <Icon src="/svg/Nau-mai-haere-mai-Welcome-copy.svg" />
                                        <ButtonText>Kīwaha <ButtonTextSpan>(Idioms)</ButtonTextSpan></ButtonText>
                                    </IconTextContainer>
                                </ButtonContainer>
                            </Link>
                        </Button>

                        <Button>
                            <Link to="/instructions">
                                <ButtonContainer>
                                    <IconTextContainer>
                                        <Icon src="/svg/Nau-mai-haere-mai-Welcome-copy.svg" />
                                        <ButtonText>Tohutohu <ButtonTextSpan>(Instructions)</ButtonTextSpan></ButtonText>
                                    </IconTextContainer>
                                </ButtonContainer>
                            </Link>
                        </Button>
                        <Button>
                            <Link to="/survivalreo">
                                <ButtonContainer>
                                    <IconTextContainer>
                                        <Icon src="/svg/Nau-mai-haere-mai-Welcome-copy.svg" />
                                        <ButtonText>Reo Ōpaki  <ButtonTextSpan>(Survival Reo)</ButtonTextSpan></ButtonText>
                                    </IconTextContainer>
                                </ButtonContainer>
                            </Link>
                        </Button>

                    </ContentContainer>
                </ContentOutContainer>
            </WelcomeContainer>
        </WelcomePageContainer>
    )
}

export default Welcome;