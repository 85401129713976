import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Image from '../components/Image'
import AkonaButtons from '../components/Akona/AkonaButtons';
import AkonaView from '../components/Akona/AkonaView';
import Vowels from '../components/Akona/Vowels';
import Syllables from '../components/Akona/Syllables';
import Consonants from '../components/Akona/Consonants';
import Diphthongs from '../components/Akona/Diphthongs';
import Title from '../components/Title'


const WhakapapaContainer = styled.div`
    margin-top: 55px;
`;

// const IntroContainer = styled.div`
//     text-align: center;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// `;
const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
    height: 100%;
    width: 560px;
    padding-bottom: 1rem;
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin: auto auto 0;
    @media only screen and (max-width: 650px) {
        width: 80%;
    }
    @media only screen and (min-width: 850px) {
        width: 40%;
    }

`;

const SubTitle = styled.p`
    text-align: center;
    margin-top: -30px;
`;

const Content = styled.div`
    margin-top: -40px;
`;

const Akona = () => {
    let { view } = useParams()

    useEffect(() => {
        Analytics.record({
            name: "Akona page visit"
        })
    }, [])

    if (view) {
        return (
            <WhakapapaContainer>
                {
                    view === "0" &&
                    <IntroContainer>
                        <Image image="/img/karakia.jpg" />
                        <Content>
                            <img src="/imagesss/All SVG/Whakahua tika (Pronunciation).svg" alt="..." style={{ width: 190, display: "block" , margin: "auto"}} />
                            <Title title="Whakahua tika" />
                            {/* <SubTitle>Pronunciation </SubTitle> */}
                            <AkonaButtons />
                        </Content>
                    </IntroContainer>
                }

                {
                    view === "1" &&
                    <AkonaView
                        title="Ngā oropuare"
                        subTitle="Vowels"
                        content={<Vowels />}
                    />
                }

                {
                    view === "2" &&
                    <AkonaView
                        title="Ngā kūoro"
                        subTitle="Syllables"
                        content={<Syllables />}
                    />
                }


                {
                    view === "3" &&
                    <AkonaView
                        title="Ngā orokati"
                        subTitle="Consonats"
                        content={<Consonants />}
                    />
                }

                {
                    view === "4" &&
                    <AkonaView
                        title="Diphthongs"
                        subTitle="Ngā oro honohono"
                        content={<Diphthongs />}
                    />
                }
            </WhakapapaContainer>
        )
    }

}

export default Akona;