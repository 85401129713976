import React, { useEffect } from 'react';
import { Analytics } from 'aws-amplify'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// import BackgroundImage from '../components/BackgroundImage';



const ContainerAnima = styled.div`
    background-color: #435A36;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: scroll;
    background-blend-mode: screen;
    // opacity: 0.5;
    background-image: url(/img/bg-1.png);
    background-position: left;
    background-size: cover;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 75px 0 25px;
    align-items: center;
    @media only screen and (min-width: 1000px) {
        padding: 125px 0 25px;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const Title = styled.h1`
    color: white;
    font-size: 20px
`;

const Text = styled.p`
    color: #22170F;
    font-size: 14px;
    font-weight: bold;
    margin: 15px 10px 50px 10px;

    @media only screen and (min-width: 768px) {
        font-size: 20px;
        margin: 45px 10px 50px 10px;
    }
`;
const LogoText = styled.p`
    color: #22170F;
    font-size: 14px;
    font-weight: bold;
    margin: 15px 10px 25px;

    @media only screen and (min-width: 768px) {
        font-size: 20px;
        margin: 25px 10px 35px;
    }
`;

const Button = styled.button`
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border: none;
    border-radius: 10px;  
    opacity: 1;
    color: #2D373C;
    cursor: pointer;
    margin: 5px 15px 5px 15px;
    padding: 7px 15px 7px 5px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
`;

const Icon = styled.img` 
    margin: 0 10px;      
    width: 40px;
    @media only screen and (min-width: 768px) {
        width: 55px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const IconTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
`;

const ButtonText = styled.p`       
    font-size: 14px;
    font-weight: bold;
    color: #22170F; 

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }
`;
const ButtonTextSpan = styled.span`       
    font-size: 75%;
    font-weight: 900;
    color: #444;
`;

// Anima
const Box = styled.div`
    position: fixed;
    background: ${props => props.backgroundColor};
    width: 2000px;
    height: 2000px;
    margin: 110vh 0 0 calc(-2000px + 50vw);
    transition: transform 1s linear;
    transform-origin: top right;
    transform-style: preserve-3D;
    z-index: -109;

    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes rotating {
        from {
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -ms-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

    -webkit-animation: rotating 2s linear;
    -moz-animation: rotating 2s linear;
    -ms-animation: rotating 2s linear;
    -o-animation: rotating 2s linear;
    animation: rotating 2s linear;
    animation-delay: ${props => props.delay};
`;

const Logo = styled.img`
    margin: 20px 0 0 0px;
    width: 100px;

    @media only screen and (min-width: 768px) {
        margin: 20px 0 0 0px;
        width: 190px;
    }
    
`;

const BlureContainer = styled.div`
    // background-color: rgba(228, 253, 233, 0.37);
    // margin-bottom: 40px;
    // border-radius: 50px;
    margin-bottom: 30px;
    @media only screen and (max-width: 900px) {
        margin-right: 50px;
        margin-left: 50px;
        margin-bottom: 30px;
    }
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 65px;
    opacity: 1;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
`;


const Home = () => {

    useEffect(() => {
        Analytics.record({
            name: "Home page visit"
        })
    }, [])

    // useEffect(() => {
    //     Analytics.record({
    //         name: "Home page visit test"
    //     })
    // }, [])

    return (
        <ContainerAnima>
            {/* <BackgroundImage image="/img/home-background.png" /> */}
            <Container>
                <ContentContainer>
                    <BlureContainer>
                        <Logo src="/imagesss/All SVG/Te Kākano (AUT Values).svg" />
                        <LogoText>TUPUKUPU</LogoText>
                    </BlureContainer>


                    <Button>
                        <Link to="/karakia/0">
                            <ButtonContainer>
                                <IconTextContainer>
                                    <Icon src="/imagesss/All SVG/Karakia (Blessings).svg" />
                                    <ButtonText>Karakia <ButtonTextSpan>(Blessings)</ButtonTextSpan></ButtonText>
                                </IconTextContainer>
                            </ButtonContainer>
                        </Link>
                    </Button>

                    <Button>
                        <Link to="/pepeha/0/0">
                            <ButtonContainer>
                                <IconTextContainer>
                                    <Icon src="/imagesss/All SVG/Pepeha (Provenance).svg" />
                                    <ButtonText>Pepeha <ButtonTextSpan>(Provenance)</ButtonTextSpan></ButtonText>
                                </IconTextContainer>
                            </ButtonContainer>
                        </Link>
                    </Button>

                    <Button>
                        <Link to="/mihimihi/0/0">
                            <ButtonContainer>
                                <IconTextContainer>
                                    <Icon src="/imagesss/All SVG/Mihi (Greetings).svg" />
                                    <ButtonText>Mihi <ButtonTextSpan>(Greetings)</ButtonTextSpan></ButtonText>
                                </IconTextContainer>
                            </ButtonContainer>
                        </Link>
                    </Button>

                    <Button>
                        <Link to="/whakapapa/0/0">
                            <ButtonContainer>
                                <IconTextContainer>
                                    <Icon src="/imagesss/All SVG/Whakapapa (Genealogy).svg" />
                                    <ButtonText>Whakapapa <ButtonTextSpan>(Genealogy)</ButtonTextSpan></ButtonText>
                                </IconTextContainer>
                            </ButtonContainer>
                        </Link>
                    </Button>

                    <Button>
                        <Link to="/welcome">
                            <ButtonContainer>
                                <IconTextContainer>
                                    <Icon src="/imagesss/All SVG/Kete Korero (Tool Kit).svg" />
                                    <ButtonText>Kete Kōrero
                                        <ButtonTextSpan> (Tool Kit)</ButtonTextSpan></ButtonText>
                                </IconTextContainer>
                            </ButtonContainer>
                        </Link>
                    </Button>
                </ContentContainer>
            </Container>

        </ContainerAnima >
    )
}

export default Home;