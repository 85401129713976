import React from 'react';
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ButtonWaiatas from '../ButtonChoiceB';

const OutContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
`;

const ButtonContainer = styled.div`

`;

const WaiataButtons = () => {
    const { view } = useParams()

    return (
        <OutContainer>
            <ButtonContainer>
                {view !== "1" &&
                    <Link to="/waiata/1">
                        <ButtonWaiatas
                            maoriText='Purea nei'
                            // engText="Scattered by"
                        />
                    </Link>
                }

                {/* {view !== "2" &&
                    <Link to="/waiata/2">
                        <ButtonWaiatas
                            maoriText='Tihei mauri ora'
                            // engText="Westpac waiata"
                        />
                    </Link>
                } */}

                {view !== "3" &&
                    <Link to="/waiata/3">
                        <ButtonWaiatas
                            maoriText='Te Aroha'
                            // engText="Love"
                        />
                    </Link>
                }

                {view !== "4" &&
                    <Link to="/waiata/4">
                        <ButtonWaiatas
                            maoriText='Tū tira mai ngā iwi'
                            // engText="Stand together everyone"
                        />
                    </Link>
                }

                {view !== "5" &&
                    <Link to="/waiata/5">
                        <ButtonWaiatas
                            maoriText='Pōkarekare ana'
                            // engText="Agitated"
                        />
                    </Link>
                }

                {view !== "6" &&
                    <Link to="/waiata/6">
                        <ButtonWaiatas
                            maoriText='Ka mate'
                            // engText="Tis death"
                        />
                    </Link>
                }

                {view !== "7" &&
                    <Link to="/waiata/7">
                        <ButtonWaiatas
                            maoriText='Ehara i te mea'
                            // engText="It is not that"
                        />
                    </Link>
                }
                {view !== "8" &&
                    <Link to="/waiata/8">
                        <ButtonWaiatas
                            maoriText='Toi Te Rawenga'
                            // engText="It is not that"
                        />
                    </Link>
                }
            </ButtonContainer>
        </OutContainer>

    )
}

export default WaiataButtons;