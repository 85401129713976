import React from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Menu from './Menu'

const TopBarCoontainer = styled.div`
    box-shadow: ${props => props.shadow};
    height: 55px;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    z-index: 999;
`;
const LogoContainer = styled.div`
    width: 50px;
    margin: 15px 25px;
    @media only screen and (min-width: 768px) {
        width: 100px;
    }
    @media only screen and (min-width: 1024px) {
        width: 150px;
    }
`
const Logo = styled.img`
    width: 100%;
`
const InsideContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const Back = styled.img`
    cursor: pointer;
    margin: 10px 0 0 20px;
    // width: 48px;
    width: 40px;
    height: 40px;
    /* UI Properties */
    box-shadow: 0px 3px 6px var(--unnamed-color-ffffff);
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #FFFFFF;
    border-radius: 65px;
    opacity: 1;
    backdrop-filter: blur(32px);
    -webkit-backdrop-filter: blur(32px);
`;


const TopBar = () => {
    let location = useLocation()
    let path = location.pathname

    let history = useHistory()

    return (
        <TopBarCoontainer
            backgroundColor={path === "/" ? "none" : "white"}
            // shadow={path === "/" ? "none" : "0 4px 8px 0 rgba(0,0,0,0.2)"}
            shadow="none"
        >
            <InsideContainer>
                {path === "/" ?
                    // <Logo src='/svg/logo-white.svg' />
                    <LogoContainer>
                        <Logo
                            src="icons/AUT_Icon.png"
                        />
                    </LogoContainer>
                    :
                    <Back onClick={() => history.goBack()} src='/svg/back-top-menu.svg' />
                }

                <Menu />
            </InsideContainer>
        </TopBarCoontainer>
    )
}

export default TopBar