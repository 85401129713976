import React from 'react';
import { Document, View, Page, Font, Image, Text, StyleSheet } from '@react-pdf/renderer';
import fontMedium from '../../fonts/Poppins-Medium.ttf'
import fontRegular from '../../fonts/Poppins-Regular.ttf'

import {
    mountain,
    river,
    lake,
    sea,
    marae,
    canoe,
    tribe,
    subTribe,
    iAmFrom,
    family,
    name,
    iWasBorn,
    iLive,
    myJob

} from './IsMaoriPepehaSentences'

Font.register({ family: 'PoppinsMedium', src: fontMedium })
Font.register({ family: 'PoppinsRegular', src: fontRegular })

// styling guide https://react-pdf.org/styling
const stylesLight = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        color: '#2D373C',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        textAlign: 'center',
        margin: 30,
        width: 500
    },
    maori_text: {
        fontSize: 12,
        fontFamily: 'PoppinsMedium'
    },
    eng_text: {
        fontSize: 10,
        fontFamily: 'PoppinsRegular',
        marginBottom: 12,
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
    },
    image: {
        width: 95,
        height: 70
    }
});

const stylesDark = StyleSheet.create({
    page: {
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        color: 'white',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderBottomLeftRadius: 6,
        textAlign: 'center',
        margin: 30,
        width: 500
    },
    maori_text: {
        fontSize: 12,
        fontFamily: 'PoppinsMedium'
    },
    eng_text: {
        fontSize: 10,
        fontFamily: 'PoppinsRegular',
        marginBottom: 12,
    },
    image_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 50,
    },
    image: {
        width: 95,
        height: 70
    }
});

const MaoriText = ({ text, toPrintTheme }) => <Text style={toPrintTheme ? stylesLight.maori_text : stylesDark.maori_text}>{text}</Text>
const EngText = ({ text, toPrintTheme }) => <Text style={toPrintTheme ? stylesLight.eng_text : stylesDark.eng_text}>{text}</Text>

const IsMaoriPepehaToPrint = ({
    inputValueMountain,
    inputValueRiver,
    inputValueLake,
    inputValueSea,
    inputValueMarae,
    inputValueCanoe,
    inputValueTribe,
    inputValueSubTribe,
    inputValueWhereFrom,
    inputValueWhanau,
    inputValueName,
    inputValueWhereBorn,
    inputValueWhereLiving,
    inputValueJob,
    toPrintTheme
}) => (
    <Document>
        <Page size="A4" style={toPrintTheme ? stylesLight.page : stylesDark.page}>
            <View style={toPrintTheme ? stylesLight.section : stylesDark.section}>
                <View style={toPrintTheme ? stylesLight.image_container : stylesDark.image_container}>
                    {
                        toPrintTheme ?
                            <Image style={toPrintTheme ? stylesLight.image : stylesDark.image} src="/img/pepeha-for-light-pdf.png" />
                            :
                            <Image style={toPrintTheme ? stylesLight.image : stylesDark.image} src="/img/pepeha-for-dark-pdf.png" />
                    }
                </View>
                {inputValueMountain !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={mountain(inputValueMountain)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={mountain(inputValueMountain)[1]} />
                    </>
                }

                {inputValueLake !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={lake(inputValueLake)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={lake(inputValueLake)[1]} />
                    </>
                }

                {inputValueRiver !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={river(inputValueRiver)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={river(inputValueRiver)[1]} />
                    </>
                }
                
                {inputValueSea !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={sea(inputValueSea)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={sea(inputValueSea)[1]} />
                    </>
                }

                {inputValueMarae !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={marae(inputValueMarae)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={marae(inputValueMarae)[1]} />
                    </>
                }

                {inputValueCanoe !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={canoe(inputValueCanoe)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={canoe(inputValueCanoe)[1]} />
                    </>
                }

                {inputValueTribe !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={tribe(inputValueTribe)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={tribe(inputValueTribe)[1]} />
                    </>
                }

                {inputValueSubTribe !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={subTribe(inputValueSubTribe)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={subTribe(inputValueSubTribe)[1]} />
                    </>
                }

                {inputValueWhereFrom !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={iAmFrom(inputValueWhereFrom)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={iAmFrom(inputValueWhereFrom)[1]} />
                    </>
                }

                {inputValueWhanau !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={family(inputValueWhanau)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={family(inputValueWhanau)[1]} />
                    </>
                }

                {inputValueName !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={name(inputValueName)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={name(inputValueName)[1]} />
                    </>
                }

                {inputValueWhereBorn !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={iWasBorn(inputValueWhereBorn)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={iWasBorn(inputValueWhereBorn)[1]} />
                    </>
                }

                {inputValueWhereLiving !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={iLive(inputValueWhereLiving)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={iLive(inputValueWhereLiving)[1]} />
                    </>
                }
                {inputValueJob !== "" &&
                    <>
                        <MaoriText toPrintTheme={toPrintTheme} text={myJob(inputValueJob)[0]} />
                        <EngText toPrintTheme={toPrintTheme} text={myJob(inputValueJob)[1]} />
                    </>
                }
            </View>
        </Page>
    </Document>
);

export default IsMaoriPepehaToPrint;