import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { getMaoriVoices } from '../../utils/maori-voices';
import Question from './Question';
import QuestionAlert from './QuestionAlert';

const QuestionPage = ({ title, data, flow, practiceView }) => {
    const [options, setOptions] = useState([]);
    const [maoriWords, setMaoriWords] = useState([]);
    const [englishWords, setEnglishWords] = useState([]);
    const [maoriId, setMaoriId] = useState(0)
    const [englishId, setEnglishId] = useState(0)
    const [element1, setElement1] = useState(null)
    const [element2, setElement2] = useState(null)
    const [currectAnswerCount, setCurrectAnswerCount] = useState(0)
    const [errorAlert, setErrorAlert] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [endAlert, setEndAlert] = useState(false)
    const [playing, setPlaying] = useState(false);
    const [maoriSound, setMaoriSound] = useState(null)
    const [soundEffect, setSoundEffect] = useState(null)
    const [duplicateData] = useState([...data])
    const [pageNumber, setPageNumber] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [score, setScore] = useState(0)
    const [dataLength, setDataLength] = useState(0)
    let history = useHistory()
    const spanRef = useRef([])
    const alertRef = useRef()
    const effectAudio = new Audio();
    let voiceEffect;

    let percent = parseFloat((score * 100 / dataLength).toFixed(2))

    useEffect(() => {
        const audio = new Audio();
        if (element1 && element1.innerText) {
            const maoriVoice = getMaoriVoices(element1.innerText);
            if (maoriVoice && playing) {
                setMaoriSound(audio)
                audio.src = maoriVoice;
                audio.play();
            }
        } else {
            speechSynthesis.cancel();
            audio.pause();
            audio.src = '';
        }

        audio.addEventListener("ended", () => {
            console.log("ended");
            setPlaying(false);
        });

        return () => {
            if (audio) {
                audio.removeEventListener("ended", () => {
                    setPlaying(false);
                });
            }
        };
    }, [playing, element1, element2]);

    useEffect(() => {
        if (pageNumber !== lastPage && currectAnswerCount === options.length && options.length) {
            endedEvent(maoriSound, false)
            endedEvent(soundEffect, false)
        }
        if (pageNumber === lastPage && options.length === currectAnswerCount && options.length) {
            endedEvent(maoriSound, true)
            endedEvent(soundEffect, true)
        }
        return () => {
            if (maoriSound) {
                maoriSound.removeEventListener("ended", () => {
                    setPlaying(false);
                });
            }
        };
    }, [currectAnswerCount, maoriSound, soundEffect])

    useEffect(() => {
        randomData()
    }, []);
    useEffect(() => {
        if (options.length) {
            let maoriWordsArray = [];
            let englishWordsArray = [];
            let questionAndAnswerWords = [];
            options.forEach(option => {
                questionAndAnswerWords = Object.values(option);
                let maoriWord = questionAndAnswerWords[1];
                let englishWord = questionAndAnswerWords[2];
                maoriWordsArray.push(maoriWord);
                maoriWordsArray.sort(() => 0.5 - Math.random());
                englishWordsArray.push(englishWord);
                englishWordsArray.sort(() => 0.5 - Math.random());
            });
            setMaoriWords(maoriWordsArray);
            setEnglishWords(englishWordsArray);
            setDataLength(prevState => prevState + options?.length)
        }
    }, [options, duplicateData]);

    // Helper Functions
    const handleClick1 = event => {
        let { target } = event
        setElement1(target)
        element1 && element1.classList.remove("orange__option")
        orangeColor(target)
        setPlaying(true)
        let maoriWordObject = options.find(item => {
            return item.Capital === target.innerText
        })
        if (maoriWordObject) {
            setMaoriId(maoriWordObject.id)
        } else {
            setMaoriId(0)
        }
        // ---------------------- play maori voice -----------------------
        maoriSound && maoriSound.pause()
        setMaoriSound(null)
    }
    const handleClick2 = event => {
        let { target } = event
        setElement2(target)
        element2 && element2.classList.remove("orange__option")
        orangeColor(target)
        setPlaying(false)
        let englishWordObject = options.find(item => {
            return item.Currency === target.innerText
        })
        if (englishWordObject) {
            setEnglishId(englishWordObject.id)
        }
    }
    const alertBtnClickHandler = (event) => {
        if (errorAlert) {
            spanRef.current.map(elem => elem && !elem.className.includes("green__option") && whiteColor(elem))
        }
        if (successAlert) {
            spanRef.current.map(elem => elem && whiteColor(elem))
            randomData()
            history.push(`/practice/${practiceView}/${+flow + 1}`)
            maoriSound && maoriSound.pause()
            setMaoriSound(null)
        }
        if (endAlert) {
            maoriSound && maoriSound.pause()
            setMaoriSound(null)
            soundEffect && soundEffect.pause()
            history.push("/practice/0/0")
        }
        spanRef.current = []
        setErrorAlert(false)
        setSuccessAlert(false)
        setEndAlert(false)
        setPlaying(false)
    }
    const randomData = () => {
        if (title === "Waiata" || title === "Karakia") {
            let valusArray = Object.values(duplicateData[0])
            setLastPage(valusArray.length)
            let shuffledArray = valusArray[pageNumber - 1].sort(() => 0.5 - Math.random());
            let sliceArray = shuffledArray.slice(0, 5);
            setOptions(sliceArray);
        } else {
            let shuffledArray = duplicateData.sort(() => 0.5 - Math.random());
            let spliceArray = shuffledArray.splice(0, 5);
            let lastPageNumber = Math.ceil(data.length / 5) >= 10 ? 10 : Math.ceil(data.length / 5)
            setLastPage(lastPageNumber)
            setOptions(spliceArray);
        }
        setCurrectAnswerCount(0)
        setElement1(null)
        setElement2(null)
    }

    const endedEvent = (sound, lastSoundEffect) => {
        sound && sound.addEventListener("ended", () => {
            if (lastSoundEffect) {
                setSuccessAlert(false)
                setEndAlert(true)
                effectAudio.src = "/sounds/practice/Congratulation.mp3"
                effectAudio.volume = 0.14
                setSoundEffect(effectAudio)
                if (effectAudio) {
                    effectAudio.play()
                }
            } else {
                const lastAudio = new Audio();
                lastAudio.src = "/sounds/practice/Finishing A Page.mp3"
                lastAudio.volume = 1
                if (lastAudio) {
                    lastAudio.play()
                }
                setEndAlert(false)
                setPageNumber(prevState => prevState + 1)
                setSuccessAlert(true)
            }
        })
    }

    const orangeColor = target => target.classList.add("orange__option")

    const redColor = target => {
        target.className = "red__option"
        spanRef.current.map(elem => elem && elem.classList.add("disable__click"))
        setErrorAlert(true)
        maoriSound && maoriSound.pause()
        setMaoriSound(null)
    }
    const greenColor = target => {
        target.className = "green__option disable__click"
        switch (currectAnswerCount) {
            case 0:
                target.classList.add("green__option-1")
                break;
            case 1:
                target.classList.add("green__option-2")
                break;
            case 2:
                target.classList.add("green__option-3")
                break;
            case 3:
                target.classList.add("green__option-4")
                break;
            case 4:
                target.classList.add("green__option-5")
                break
            default:
                break;
        }
    }
    const whiteColor = target => {
        target.className = "white__option"
        spanRef.current.map(elem => elem && !elem.className.includes("green__option") && elem.classList.remove("disable__click"))
        setMaoriId(0)
        setPlaying(false)
    }
    if (maoriId && englishId) {
        if (maoriId === englishId) {
            setMaoriId(0)
            setEnglishId(0)
            greenColor(element1)
            greenColor(element2)
            setScore(prevState => prevState + 1)
            // ---------------------- sound effect -----------------------
            voiceEffect = "/sounds/practice/Correct Word.mp3"
            effectAudio.src = voiceEffect
            effectAudio.volume = 0.2
            if (effectAudio && !maoriSound && !getMaoriVoices(element1.innerText)) {
                setSoundEffect(effectAudio)
                effectAudio.play()
            }
            setCurrectAnswerCount(prevState => prevState + 1)
        } else {
            setPlaying(false)
            redColor(element1)
            redColor(element2)
            // ---------------------- sound effect -----------------------
            voiceEffect = "/sounds/practice/Wrong Answer.mp3"
            effectAudio.src = voiceEffect
            effectAudio.volume = 0.08
            effectAudio && effectAudio.play()
            setMaoriId(0)
            setEnglishId(0)
            setScore(prevState => prevState - 1)
        }
    }
    return (
        <div className="question__box">
            <div className="question__box-header">
                <h2 className="question__box-title">{title}</h2>
                <span className='question__box-text'>Kimihia tōna hoa</span>
            </div>
            <div className="question__box-main">
                <div className="question__box-row">
                    <Question
                        handleClick={handleClick1}
                        spanRef={spanRef}
                        words={maoriWords}
                    />
                    <Question
                        handleClick={handleClick2}
                        spanRef={spanRef}
                        words={englishWords}
                    />
                </div>
            </div>
            <div className="question__box-number">
                <span>
                    {
                        `${pageNumber} / ${lastPage}`
                    }
                </span>
            </div>
            {
                errorAlert &&
                <QuestionAlert
                    engTextAlert={"Got it"}
                    maoriTextAlert={"Taihoa (try again)"}
                    alertBtnClickHandler={alertBtnClickHandler}
                    alertRef={alertRef}
                    colorBtn={"error-btn"}
                    score={false}
                />
            }
            {
                successAlert &&
                <QuestionAlert
                    engTextAlert={"Continue"}
                    maoriTextAlert={"Ka rawe!"}
                    alertBtnClickHandler={alertBtnClickHandler}
                    alertRef={alertRef}
                    colorBtn={"success-btn"}
                    score={false}
                />
            }
            {
                endAlert &&
                <QuestionAlert
                    engTextAlert={"End"}
                    maoriTextAlert={"Nga mihi whakamiharo!"}
                    alertBtnClickHandler={alertBtnClickHandler}
                    alertRef={alertRef}
                    colorBtn={"success-btn"}
                    score={score < 0 ? "0%" : percent + "%"}
                />
            }
        </div>
    );
}
export default QuestionPage