import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components'

import InputQuestion from '../InputQuestion';
import SectionToPrint from '../SectionToPrint'
import IsNotMaoriPepehaToPrintUi from './IsNotMaoriPepehaToPrintUi';
import IsNotMaoriPepehaToPrint from './IsNotMaoriPepehaToPrint';
import ExportToPDFButton from '../ExportToPDFButton';
import PopUpLoading from '../PopUpLoading';
// import PopUpPepeha from '../PopUpPepeha'
import Button from '../Button';
import BasicAdvanced from '../BasicAdvanced';
import {
    whereBorn,
    whereLiving,
    ancestors,
    whanau,
    name,
    mountain,
    hill,
    sea,
    river,
    lake,
    tribe,
    extra1,
    extra2,
    extra3,
    extra4,
    extra5
} from './IsNotMaoriPepehaSentences';
import { myJob } from './IsMaoriPepehaSentences';

const ContainerToPrint = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ContainerOut = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 90vh;
`;

const ContainerIn = styled.div`
    width: 100%

    @media only screen and (min-width: 768px) {
        width: 400px;
    }
`;

const ToPrintContainer = styled.div`
    position: sticky;
    margin: 0 10px 50px 0;
    width: 280px;

    @media only screen and (min-width: 768px) {
        width: 600px;
    }
`;

const IsNotMaori = (
    {
        inputValueAncestors,
        setInputStateAncestors,
        inputValueHill,
        setInputStateHill,
        inputValueMountain,
        setInputStateMountain,
        inputValueRiver,
        setInputStateRiver,
        inputValueLake,
        setInputStateLake,
        inputValueSea,
        setInputValueSea,
        inputValueExtra1,
        setInputValueExtra1,
        inputValueExtra2,
        setInputValueExtra2,
        inputValueExtra3,
        setInputValueExtra3,
        inputValueExtra4,
        setInputValueExtra4,
        inputValueExtra5,
        setInputValueExtra5,
        inputValueTribe,
        setInputValueTribe,
        inputValueName,
        setInputValueName,
        inputValueWhereBorn,
        setInputValueWhereBorn,
        inputValueWhereLiving,
        setInputValueWhereLiving,
        inputValueWhanau,
        setInputValueWhanau,
        inputValueJob,
        setInputValueJob,
    }
) => {
    let { flow } = useParams()
    let history = useHistory()
    const [toPrintTheme, setToPrintTheme] = useState(true)
    const [isBasic, setIsBasic] = useState("error")
    const clearHistory = function () {
        setInputStateAncestors("")
        setInputStateHill("")
        setInputStateMountain("")
        setInputStateRiver("")
        setInputStateLake("")
        setInputValueSea("")
        setInputValueTribe("")
        setInputValueName("")
        setInputValueWhereBorn("")
        setInputValueWhereLiving("")
        setInputValueWhanau("")
        setInputValueJob("")
    }
    return (
        <div>
            <ContainerToPrint>
                {
                    flow === "13" &&
                    <ToPrintContainer>
                        <SectionToPrint
                            imgSrc={`${toPrintTheme ? '/imagesss/All SVG/Mountain.svg' : null}`}
                            title="Pepeha"
                            toPrintTheme={toPrintTheme}
                            toPrintUI={
                                <IsNotMaoriPepehaToPrintUi
                                    inputValueAncestors={inputValueAncestors}
                                    inputValueHill={inputValueHill}
                                    inputValueMountain={inputValueMountain}
                                    inputValueRiver={inputValueRiver}
                                    inputValueLake={inputValueLake}
                                    inputValueSea={inputValueSea}
                                    inputValueExtra1={inputValueExtra1}
                                    inputValueExtra2={inputValueExtra2}
                                    inputValueExtra3={inputValueExtra3}
                                    inputValueExtra4={inputValueExtra4}
                                    inputValueExtra5={inputValueExtra5}
                                    inputValueTribe={inputValueTribe}
                                    inputValueName={inputValueName}
                                    inputValueWhereBorn={inputValueWhereBorn}
                                    inputValueWhereLiving={inputValueWhereLiving}
                                    inputValueWhanau={inputValueWhanau}
                                    inputValueJob={inputValueJob}
                                    setToPrintTheme={setToPrintTheme}
                                    toPrintTheme={toPrintTheme}
                                    isBasic={isBasic}
                                />
                            }
                        />
                        <ExportToPDFButton
                            toPrint={
                                <IsNotMaoriPepehaToPrint
                                    inputValueAncestors={inputValueAncestors}
                                    inputValueHill={inputValueHill}
                                    inputValueMountain={inputValueMountain}
                                    inputValueRiver={inputValueRiver}
                                    inputValueLake={inputValueLake}
                                    inputValueSea={inputValueSea}
                                    inputValueExtra1={inputValueExtra1}
                                    inputValueExtra2={inputValueExtra2}
                                    inputValueExtra3={inputValueExtra3}
                                    inputValueExtra4={inputValueExtra4}
                                    inputValueExtra5={inputValueExtra5}
                                    inputValueTribe={inputValueTribe}
                                    inputValueName={inputValueName}
                                    inputValueWhereBorn={inputValueWhereBorn}
                                    inputValueWhereLiving={inputValueWhereLiving}
                                    inputValueWhanau={inputValueWhanau}
                                    inputValueJob={inputValueJob}
                                    toPrintTheme={toPrintTheme}
                                    isBasic={isBasic}
                                />
                            }
                            fileName="Pepeha.pdf"
                        />
                        <Link onClick={clearHistory} to="/pepeha/non-maori/0.5">
                            <Button
                                text="Start over"
                                ArgBackgroundColor="white"
                                ArgBorder="1px solid #5c9021"
                                ArgColor="#5c9021"
                                ArgHoverBackgroundColor="#F0F0F0"
                            />
                        </Link>
                    </ToPrintContainer>
                }
            </ContainerToPrint>
            {
                flow < 13 &&
                <ContainerOut>
                    <ContainerIn>

                        {
                            flow === "0.5" &&
                            <BasicAdvanced
                                setState={setIsBasic}
                                state={isBasic}
                                image="/imagesss/All SVG/Mountain.svg"
                                basic="Basic pepeha"
                                advanced="Advanced pepeha"
                                path="/pepeha/non-maori/1"
                                title="Create my pepeha"
                                onClick={clearHistory}
                            />
                        }
                        {
                            isBasic === true &&
                            <div>
                                {
                                    flow === "1" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/2"
                                        inputValue={inputValueAncestors}
                                        setInputState={setInputStateAncestors}
                                        imgSrc="/imagesss/All SVG/Mountain.svg"
                                        title={ancestors(inputValueAncestors)[0]}
                                        text={ancestors(inputValueAncestors)[1]}
                                        placeholder="Ancestors place"
                                    />
                                }
                                {
                                    flow === "2" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/3"
                                        inputValue={inputValueWhanau}
                                        setInputState={setInputValueWhanau}
                                        imgSrc="/imagesss/All SVG/Whanua-Jana-Design.svg"
                                        title={whanau(inputValueWhanau)[0]}
                                        text={whanau(inputValueWhanau)[1]}
                                        placeholder="Family name"
                                    />
                                }
                                {
                                    flow === "3" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/4"
                                        inputValue={inputValueWhereBorn}
                                        setInputState={setInputValueWhereBorn}
                                        imgSrc="/imagesss/All SVG/I-was-born-in.svg"
                                        title={whereBorn(inputValueWhereBorn)[0]}
                                        text={whereBorn(inputValueWhereBorn)[1]}
                                        placeholder="Place name"
                                    />
                                }
                                {
                                    flow === "4" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/5"
                                        inputValue={inputValueWhereLiving}
                                        setInputState={setInputValueWhereLiving}
                                        imgSrc="/imagesss/All SVG/Location-Jana.svg"
                                        title={whereLiving(inputValueWhereLiving)[0]}
                                        text={whereLiving(inputValueWhereLiving)[1]}
                                        placeholder="Place name"
                                    />
                                }

                                {
                                    flow === "5" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/12"
                                        inputValue={inputValueName}
                                        setInputState={setInputValueName}
                                        imgSrc="/imagesss/All SVG/Name.svg"
                                        title={name(inputValueName)[0]}
                                        text={name(inputValueName)[1]}
                                        placeholder="Your name"
                                    />
                                }
                            </div>
                        }
                        {/* {
                            flow === "5" &&
                            <PopUpPepeha />
                        } */}
                        {
                            isBasic === false &&
                            <div>
                                {
                                    flow === "1" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/2"
                                        inputValue={inputValueAncestors}
                                        setInputState={setInputStateAncestors}
                                        imgSrc="/imagesss/All SVG/Mountain.svg"
                                        title={ancestors(inputValueAncestors)[0]}
                                        text={ancestors(inputValueAncestors)[1]}
                                        placeholder="Ancestors place"
                                    />
                                }
                                {
                                    flow === "2" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/3"
                                        inputValue={inputValueWhanau}
                                        setInputState={setInputValueWhanau}
                                        imgSrc="/imagesss/All SVG/Whanua-Jana-Design.svg"
                                        title={whanau(inputValueWhanau)[0]}
                                        text={whanau(inputValueWhanau)[1]}
                                        placeholder="Family name"
                                    />
                                }
                                {
                                    flow === "3" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/4"
                                        inputValue={inputValueWhereBorn}
                                        setInputState={setInputValueWhereBorn}
                                        imgSrc="/imagesss/All SVG/I-was-born-in.svg"
                                        title={whereBorn(inputValueWhereBorn)[0]}
                                        text={whereBorn(inputValueWhereBorn)[1]}
                                        placeholder="Place name"
                                    />
                                }
                                {
                                    flow === "4" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/5"
                                        inputValue={inputValueMountain}
                                        setInputState={setInputStateMountain}
                                        imgSrc="/imagesss/All SVG/Mountain.svg"
                                        title={mountain(inputValueMountain)[0]}
                                        text={mountain(inputValueMountain)[1]}
                                        placeholder="Mountain"
                                    />
                                }
                                {
                                    flow === "5" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/6"
                                        inputValue={inputValueHill}
                                        setInputState={setInputStateHill}
                                        imgSrc="/imagesss/All SVG/Mountain.svg"
                                        title={hill(inputValueHill)[0]}
                                        text={hill(inputValueHill)[1]}
                                        placeholder="Hill"
                                    />
                                }
                                {
                                    flow === "6" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/7"
                                        inputValue={inputValueLake}
                                        setInputState={setInputStateLake}
                                        imgSrc="/imagesss/All SVG/Lake.svg"
                                        title={lake(inputValueLake)[0]}
                                        text={lake(inputValueLake)[1]}
                                        placeholder="Lake name"
                                    />
                                }
                                {
                                    flow === "7" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/8"
                                        inputValue={inputValueRiver}
                                        setInputState={setInputStateRiver}
                                        imgSrc="/imagesss/All SVG/River.svg"
                                        title={river(inputValueRiver)[0]}
                                        text={river(inputValueRiver)[1]}
                                        placeholder="River name"
                                    />
                                }
                                {
                                    flow === "8" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/8.2"
                                        inputValue={inputValueSea}
                                        setInputState={setInputValueSea}
                                        imgSrc="/imagesss/All SVG/Sea.svg"
                                        title={sea(inputValueSea)[0]}
                                        text={sea(inputValueSea)[1]}
                                        placeholder="Sea name"
                                    />
                                }
                                {
                                    flow === "8.2" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/8.3"
                                        inputValue={inputValueExtra1}
                                        setInputState={setInputValueExtra1}
                                        imgSrc="/imagesss/All SVG/Mountain.svg"
                                        title={extra1(inputValueExtra1)[0]}
                                        text={extra1(inputValueExtra1)[1]}
                                        placeholder="Mountain"
                                    />
                                }
                                {
                                    flow === "8.3" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/8.4"
                                        inputValue={inputValueExtra2}
                                        setInputState={setInputValueExtra2}
                                        imgSrc="/imagesss/All SVG/Mountain.svg"
                                        title={extra2(inputValueExtra2)[0]}
                                        text={extra2(inputValueExtra2)[1]}
                                        placeholder="Hill"
                                    />
                                }
                                {
                                    flow === "8.4" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/8.5"
                                        inputValue={inputValueExtra3}
                                        setInputState={setInputValueExtra3}
                                        imgSrc="/imagesss/All SVG/Lake.svg"
                                        title={extra3(inputValueExtra3)[0]}
                                        text={extra3(inputValueExtra3)[1]}
                                        placeholder="Lake name"
                                    />
                                }
                                {
                                    flow === "8.5" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/8.6"
                                        inputValue={inputValueExtra4}
                                        setInputState={setInputValueExtra4}
                                        imgSrc="/imagesss/All SVG/River.svg"
                                        title={extra4(inputValueExtra4)[0]}
                                        text={extra4(inputValueExtra4)[1]}
                                        placeholder="River name"
                                    />
                                }
                                {
                                    flow === "8.6" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/9"
                                        inputValue={inputValueExtra5}
                                        setInputState={setInputValueExtra5}
                                        imgSrc="/imagesss/All SVG/Sea.svg"
                                        title={extra5(inputValueExtra5)[0]}
                                        text={extra5(inputValueExtra5)[1]}
                                        placeholder="Sea name"
                                    />
                                }
                                {
                                    flow === "9" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/10"
                                        inputValue={inputValueWhereLiving}
                                        setInputState={setInputValueWhereLiving}
                                        imgSrc="/imagesss/All SVG/Marae.svg"
                                        title={whereLiving(inputValueWhereLiving)[0]}
                                        text={whereLiving(inputValueWhereLiving)[1]}
                                        placeholder="Place name"
                                    />
                                }
                                {
                                    flow === "10" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/11"
                                        inputValue={inputValueTribe}
                                        setInputState={setInputValueTribe}
                                        imgSrc="/imagesss/All SVG/Iwi-Tribe.svg"
                                        title={tribe(inputValueTribe)[0]}
                                        text={tribe(inputValueTribe)[1]}
                                        placeholder="Tribe name"
                                    />
                                }
                                {
                                    flow === "11" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/11.5"
                                        inputValue={inputValueName}
                                        setInputState={setInputValueName}
                                        imgSrc="/imagesss/All SVG/Name.svg"
                                        title={name(inputValueName)[0]}
                                        text={name(inputValueName)[1]}
                                        placeholder="Your name"
                                    />
                                }
                                {
                                    flow === "11.5" &&
                                    <InputQuestion
                                        path="/pepeha/non-maori/12"
                                        inputValue={inputValueJob}
                                        setInputState={setInputValueJob}
                                        imgSrc="/imagesss/All SVG/Name.svg"
                                        title={myJob(inputValueJob)[0]}
                                        text={myJob(inputValueJob)[1]}
                                        placeholder="Your name"
                                    />
                                }

                            </div>
                        }

                        {
                            flow === "12" &&
                            <div onClick={setTimeout(function () { history.push("/pepeha/non-maori/13") }, 3000)}>
                                <PopUpLoading word="pepeha" />
                            </div>
                        }

                    </ContainerIn>
                </ContainerOut>
            }
        </div>
    )
}

export default IsNotMaori;