import React from 'react';
import styled from 'styled-components'
import AkonaButtons from './AkonaButtons';
import Title from '../Title';

const Container = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const IntroContainer = styled.div`
    // @media only screen and (min-width: 1200px) {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     height: 90vh;
    // }
    height: 100%;
    width: 560px;
    /* UI Properties */
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    padding-bottom: 2rem;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    margin: auto auto 30px;
    @media only screen and (max-width: 650px) {
        width: 80%;
    }
    @media only screen and (min-width: 850px) {
        width: 40%;
    }

`;


const SubTitle = styled.p`
    margin: -30px 0 50px 0;
`;

const AkonaContainer = styled.div`
    // background-color: #F8F8F8;
    margin: 30px auto;
    text-align: center;
    padding: 20px 0;

    
`;

const AkonaView = ({
    title,
    subTitle,
    content
}) => {
    return (

        <Container>
            <IntroContainer>
            <div style={{ margin: "5rem 0 3rem 0" }}><Title title={title} /></div>
                <SubTitle>{subTitle}</SubTitle>

                <AkonaContainer>
                    {content}
                </AkonaContainer>
            </IntroContainer>
                <AkonaButtons />
        </Container>

    )
}

export default AkonaView;