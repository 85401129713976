export const data = [
    {
      Capital: "Nā",
      Currency: "From",
    },
    {
      Capital: "Nāku, nā",
      Currency: "Yours faithfully",
    },
    {
      Capital: "Nāku iti noa, nā",
      Currency: "Yours sincerely",
    },
    {
      Capital: "Ngā mihi",
        Currency: "Thank you",
      },
      {
        Capital: "Ngā mihi nui",
        Currency: "Kind regards",
      },
      {
        Capital: "Hei konā mai",
        Currency: "Goodbye for now",
      }
  ];
  