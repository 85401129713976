import React from 'react';
import styled from 'styled-components'
import ButtonChoice from '../../ButtonChoice';

const ButtonContainer = styled.div`
    margin: 10px 0;
`;

const I = ({
    setInputState,
    inputValue
}) => {
    return (
        <ButtonContainer>
            <ButtonChoice
                maoriText='te aroha'
                engText="the love"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'te aroha' ? true : false}
            />
            <ButtonChoice
                maoriText='ngā whakaaro'
                engText="the ideas"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'ngā whakaaro' ? true : false}
            />
            <ButtonChoice
                maoriText='ngā take o te wā'
                engText="the issues of the day"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'ngā take o te wā' ? true : false}
            />
            <ButtonChoice
                maoriText='te kaupapa o te rā'
                engText="today's purpose"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'te kaupapa o te rā' ? true : false}
            />
            <ButtonChoice
                maoriText='ngā uaua'
                engText="the challenges"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'ngā uaua' ? true : false}
            />
            <ButtonChoice
                maoriText='ngā pātai'
                engText="the queries"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'ngā pātai' ? true : false}
            />
            <ButtonChoice
                maoriText='te kōrero'
                engText="the discussions"
                setButtonChoiceState={setInputState}
                active={inputValue[0] === 'te kōrero' ? true : false}
            />
        </ButtonContainer>
    )
}

export default I;