import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../Button';

const Container = styled.div`
    text-align: center;
    width: 380px;
    margin: 0 20px 50px;
    padding: 35px 50px;

    border-radius: 10px;  
    opacity: 1;
    background: #FFFFFF80 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    @media only screen and (min-width: 768px) {
        padding: 50px 135px;
    }
`;


const TitleStyled = styled.h1`
    font-size: 20px;

    @media only screen and (min-width: 768px) {
        font-size: 30px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 30px;
    }
`;

const Text = styled.p`
    font-size: 12px;
    margin: -25px 0 30px 0;

    @media only screen and (min-width: 768px) {
        font-size: 18px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 20px;
    }
`;

const InputStyled = styled.input`
font-size: 18;
    background: white;
    border: 1px solid #B2B2B2;
    border-radius: 6px;
    color: #2D373C;
    font-family: RegularFont;
    font-size: 18;
    margin: 10px 0;
    padding: 15px;
    width: 349px;
`;

const RemoveInput = styled.p`
    color: #5c9021;
    cursor: pointer;
    float: right;
    margin-top: -5px;
`;

const AddInput = styled.p`
    color: #5c9021;
    cursor: pointer;
    font-family: SemiBoldFont;
    margin-top: 30px;
`;



const InputQuestionTwoChildren = ({
    path,
    imgSrc,
    inputValueThreeChildren,
    setInputValueThreeChildren
}) => {

    let handleChange = (i, e) => {
        let newFormValues = [...inputValueThreeChildren];
        newFormValues[i][e.target.name] = e.target.value;
        setInputValueThreeChildren(newFormValues);
    }

    let addFormFields = () => {
        setInputValueThreeChildren([...inputValueThreeChildren, { name: "" }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...inputValueThreeChildren];
        newFormValues.splice(i, 1);
        setInputValueThreeChildren(newFormValues)
    }

    return (
        <Container>
            <img src={imgSrc} alt="Ko aku tamariki" width={190}/>
            <TitleStyled>{`Ko ${inputValueThreeChildren[0].name || "..."} rātou`}</TitleStyled>
            <Text>{`My children are ${inputValueThreeChildren[0].name || "..."} and`}</Text>
            {inputValueThreeChildren.map((element, index) => {
                if (index > 0) return (
                    <div key={`title-text-${index}`}>
                        <TitleStyled>{`Ko ${element.name || "..."}`}</TitleStyled>
                        <Text>{`${element.name || "..."}`}</Text>
                    </div>
                )
            })}

            {inputValueThreeChildren.map((element, index) => (
                <div key={`input-${index}`}>
                    <InputStyled type="text" name="name" value={element.name || ""} onChange={e => handleChange(index, e)} placeholder="Insert name" />
                    {
                        index > 2 ?
                            <RemoveInput onClick={() => removeFormFields(index)}>X Remove field</RemoveInput>
                            : null
                    }
                </div>
            ))}
            <div >
                <AddInput onClick={() => addFormFields()}>+ Add one more field</AddInput>
            </div>

            <Link to={path}>
                <Button text="Next" />
            </Link>
        </Container>
    )
}


export default InputQuestionTwoChildren;